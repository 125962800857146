import React, { Component } from "react";
import { Button, Dialog, Grid, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { translations } from "../../../generated/translationHelper";
import AuthCode from "react-auth-code-input";

const CODE_LENGTH = 6;

interface Props {
  dialogOpen: boolean;
  title: string;
  msg: string;
  onConfirm: (code: string) => Promise<void>;
  onDialogClose: (confirmSuccessful?: boolean) => void;
  onResend: () => void;
}

interface State {
  codeError: boolean;
  code: string;
}

export default class ConfirmCodeDialog extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      codeError: false,
      code: "",
    };
  }

  private handleConfirm = async (): Promise<void> => {
    const { code } = this.state;
    try {
      await this.props.onConfirm(code);
      this.props.onDialogClose(!this.state.codeError);
    } catch (error) {
      this.setState({ codeError: true });
      console.error("Confirm code mismatch", error);
    }
  };

  private renderInputFields = (): React.ReactNode => {
    const { codeError } = this.state;
    const inputClassName = codeError ? "confirm-code-field error" : "confirm-code-field";
    return (
      <AuthCode
        containerClassName="code-container"
        inputClassName={inputClassName}
        allowedCharacters="alphanumeric"
        length={CODE_LENGTH}
        onChange={(val): void => this.setState({ code: val, codeError: false })}
      />
    );
  };

  public render(): React.ReactNode {
    return (
      <Dialog open={this.props.dialogOpen} fullWidth className="confirm-dialog">
        <Grid container direction="column" className="confirm-dialog-container">
          <Grid item>
            <Typography variant="h5">{this.props.title}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">{this.props.msg}</Typography>
          </Grid>
          <Grid item>
            {this.renderInputFields()}
            {this.state.codeError ? (
              <Typography className="confirm-error-text">{translations.user.texts.codeMismatchError()}</Typography>
            ) : null}
          </Grid>
          <Grid item container justifyContent="flex-end">
            <Button variant="text" onClick={(): void => this.props.onResend()}>
              <Typography variant="body2" color="primary">
                {translations.logIn.buttons.resendCode()}
              </Typography>
            </Button>
          </Grid>
          <Grid item container xs direction="row" justifyContent="flex-end" className="confirm-dialog-item-container">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                className="confirm-dialog-button"
                onClick={(): void => this.props.onDialogClose(false)}
              >
                {translations.common.buttons.cancel().toUpperCase()}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={false}
                className="confirm-dialog-button"
                onClick={(): Promise<void> => this.handleConfirm()}
              >
                {translations.common.buttons.confirm().toUpperCase()}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <IconButton onClick={(): void => this.props.onDialogClose(false)} className="exit-button">
          <Close color="primary" />
        </IconButton>
      </Dialog>
    );
  }
}
