import React, { useEffect, useRef, useState } from "react";
import { AuthWrapper, AwsConfiguration, Credential, OrderApiClient } from "@sade/data-access";
import Loader from "../../../../ui/loader";
import { translations } from "../../../../../generated/translationHelper";

export interface Props {
  credential: Credential;
  orderId: number;
}

export const DeviceId: React.FC<Props> = (props: Props) => {
  const awsConfig = useRef(AwsConfiguration.getConfiguration());
  const apiClient = useRef(new OrderApiClient(awsConfig.current.ApiGateway.RootUrlOrders, AuthWrapper.getAccessToken));
  const [deviceId, setDeviceId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (props.credential.deviceId === undefined) {
      apiClient.current
        .getCredential(props.orderId, props.credential.credentialId)
        .then((c) => setDeviceId(c.deviceId))
        .catch((e) => {
          console.error(e);
          setDeviceId(translations.common.texts.error());
        });
    } else {
      setDeviceId(props.credential.deviceId);
    }
  }, [props.orderId, props.credential.credentialId, props.credential.deviceId]);

  return deviceId !== undefined ? <div>{deviceId}</div> : <Loader size="small"></Loader>;
};
