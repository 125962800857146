import { Button, Dialog, styled } from "@mui/material";
import React, { useState } from "react";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import { translations } from "../../../generated/translationHelper";

import { Document, Page, pdfjs } from "react-pdf";
import type { PDFDocumentProxy } from "pdfjs-dist";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf/pdf.worker.js";

interface Props {
  close: () => void;
}

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  root: {
    padding: theme.spacing(2),
    flexWrap: "nowrap",
  },
}));
const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(1),
  justifyContent: "flex-start",
}));

export const UserLicenseAgreementDialog: React.FC<Props> = (props: Props) => {
  const [numPages, setNumPages] = useState<number>();

  function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy): void {
    setNumPages(nextNumPages);
  }

  function download(): void {
    const link = document.createElement("a");
    link.download = `UserLicenseAgreement.pdf`;
    link.href = "/pdf/UserLicenseAgreement.pdf";
    link.click();
  }

  return (
    <Dialog open={true} onClose={props.close} maxWidth={false}>
      <DialogContent>
        <Document file="/pdf/UserLicenseAgreement.pdf" onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          ))}
        </Document>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={props.close}>
          {translations.common.buttons.close()}
        </Button>
        <Button variant="contained" color="primary" onClick={download}>
          {translations.common.buttons.download()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
