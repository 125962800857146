import React, { Component, ReactNode } from "react";
import ErrorDialog from "../../../ui/error-dialog";
import Loader from "../../../ui/loader";
import { BackendFactory, Role } from "@sade/data-access";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { translations } from "../../../../generated/translationHelper";
import { AdminCellItem } from "../util-components/admin-cell-item";

interface Props {}

interface State {
  roles?: Role[];
  loading: boolean;
  errorMsg?: string;
}

const ROW_COL_COUNT = 1;
export default class Permissions extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    return this.updateRoles();
  }

  public async updateRoles(): Promise<void> {
    this.setState({ loading: true });
    this.setState({
      roles: await BackendFactory.getOrganizationBackend().listRoles(),
      loading: false,
    });
  }

  private renderRoleRows(): ReactNode {
    return this.state.roles?.map((role) => {
      return (
        <TableRow key={role.identifier}>
          <TableCell>
            <AdminCellItem item={{ name: role.name, id: role.identifier }} data-testid={"role"} />
          </TableCell>
        </TableRow>
      );
    });
  }

  private renderError(): ReactNode {
    if (this.state.errorMsg) {
      return (
        <TableRow>
          <TableCell colSpan={ROW_COL_COUNT} align="center">
            <ErrorDialog errorMsg={this.state.errorMsg} onClose={(): void => this.setState({ errorMsg: undefined })} />
          </TableCell>
        </TableRow>
      );
    }
  }

  private renderLoader(): ReactNode {
    if (this.state.loading) {
      return (
        <TableRow>
          <TableCell colSpan={ROW_COL_COUNT} align="center">
            <Loader size="small" />
          </TableCell>
        </TableRow>
      );
    }
  }

  public render(): JSX.Element {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={ROW_COL_COUNT}>{translations.admin.texts.organisationsRoles()}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.renderRoleRows()}
          {this.renderError()}
          {this.renderLoader()}
        </TableBody>
      </Table>
    );
  }
}
