import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  AuthWrapper,
  AwsConfiguration,
  BackendFactory,
  OrderApiClient,
  OrderTemplate,
  Organization,
} from "@sade/data-access";
import { Button, Chip, Grid, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { translations } from "../../../../generated/translationHelper";
import { useNotification } from "../../../ui/notification";
import Loader from "../../../ui/loader";
import { OrderTemplateAssignment } from "./order-template-assignment";

export interface Props {
  organization: Organization;
}

export const OrderTemplates: React.FC<Props> = (props) => {
  const organizationBackend = useRef(BackendFactory.getOrganizationBackend());
  const awsConfig = useRef(AwsConfiguration.getConfiguration());
  const apiClient = useRef(new OrderApiClient(awsConfig.current.ApiGateway.RootUrlOrders, AuthWrapper.getAccessToken));
  const displayNotification = useNotification();

  const [orderTemplatesOfHomeOrganization, setOrderTemplatesOfHomeOrganization] = useState<OrderTemplate[]>([]);
  const [orderTemplatesOfSelectedOrganization, setOrderTemplatesOfSelectedOrganization] = useState<OrderTemplate[]>([]);
  const [isOrderTemplateAssignmentDialogOpen, setIsOrderTemplateAssignmentDialogOpen] = useState(false);
  const [isHomeOrganization, setIsHomeOrganization] = useState(true);
  const [currentSlowOperationsCount, setCurrentSlowOperationsCount] = useState(0);
  const [isRevoking, setIsRevoking] = useState(false);

  const organizationId = props.organization.getId();

  const incrementSlowOperationsCountBy = useCallback((count: number): void => {
    setCurrentSlowOperationsCount((previousValue) => {
      if (previousValue < 0) {
        console.debug(
          "Incrementing order template assignment slow operation count from below zero, that should never happen"
        );
      }
      return previousValue + count;
    });
  }, []);
  const decrementSlowOperationsCountBy = useCallback((value: number): void => {
    setCurrentSlowOperationsCount((previousValue) => {
      if (previousValue <= 0) {
        console.debug(
          "Decrementing order template assignment slow operation count to below zero, that should never happen"
        );
      }
      return previousValue - value;
    });
  }, []);

  useEffect(() => {
    organizationBackend.current
      .getCurrentHomeOrganization()
      .then((home) => apiClient.current.getOrderTemplates(home.getId()).then(setOrderTemplatesOfHomeOrganization))
      .catch((e) => {
        const message = e instanceof Error ? e.message : translations.common.texts.unknownError();
        displayNotification({
          title: translations.admin.texts.loadingOrderTemplatesFailed(),
          message: message,
          variant: "error",
        });
      });
  }, [displayNotification]);

  useEffect(() => {
    incrementSlowOperationsCountBy(2);

    console.debug("CLEARING IT NOW");
    setOrderTemplatesOfSelectedOrganization([]);
    setIsHomeOrganization(true);
    // Disable some functionality by assuming newly selected org is the home until we find out from backend is it the home.
    // This is better than assuming the other way around and enabling functionality that possibly shouldn't be enabled

    organizationBackend.current
      .getCurrentHomeOrganization()
      .then((home) => setIsHomeOrganization(home.getId() === organizationId))
      .catch((e) => {
        const message = e instanceof Error ? e.message : translations.common.texts.unknownError();
        displayNotification({
          title: translations.admin.texts.initializingOrderTemplateAdministrationFailed(),
          message: message,
          variant: "error",
        });
      })
      .finally(() => decrementSlowOperationsCountBy(1));

    apiClient.current
      .getOrderTemplates(organizationId)
      .then(setOrderTemplatesOfSelectedOrganization)
      .catch((e) => {
        const message = e instanceof Error ? e.message : translations.common.texts.unknownError();
        displayNotification({
          title: translations.admin.texts.loadingOrderTemplatesFailed(),
          message: message,
          variant: "error",
        });
      })
      .finally(() => decrementSlowOperationsCountBy(1));
  }, [organizationId, displayNotification, incrementSlowOperationsCountBy, decrementSlowOperationsCountBy]);

  function assignOrderTemplates(newOrderTemplateIds: Set<number>): void {
    setOrderTemplatesOfSelectedOrganization(
      orderTemplatesOfHomeOrganization.filter((ot) => newOrderTemplateIds.has(ot.id))
    );
  }

  async function revoke(orderTemplate: OrderTemplate): Promise<void> {
    try {
      setIsRevoking(true);

      const newOrderTemplates = orderTemplatesOfSelectedOrganization.filter((ot) => ot.id !== orderTemplate.id);
      await apiClient.current.assignOrderTemplates(new Set(newOrderTemplates.map((f) => f.id)), organizationId);
      setOrderTemplatesOfSelectedOrganization((previous) => previous.filter((f) => f.id !== orderTemplate.id));
    } catch (e) {
      const message = e instanceof Error ? e.message : translations.common.texts.unknownError();
      displayNotification({
        title: translations.admin.texts.revokingOrderTemplateFailed({ orderTemplateName: orderTemplate.name }),
        message: message,
        variant: "error",
      });
    } finally {
      setIsRevoking(false);
    }
  }

  function renderOrderTemplatesList(): React.JSX.Element[] {
    return orderTemplatesOfSelectedOrganization.map((ot) => {
      return (
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ paddingBottom: 1, borderBottom: 1, borderColor: "rgb(0, 0, 0, 0.12)" }}
          key={ot.name}
        >
          <Grid item>{ot.name}</Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={isRevoking || isHomeOrganization}
              onClick={async (): Promise<void> => await revoke(ot)}
              sx={{ visibility: isHomeOrganization ? "hidden" : "visible" }}
            >
              {translations.admin.buttons.revokeAccess()}
            </Button>
          </Grid>
        </Grid>
      );
    });
  }

  function renderOrderTemplateAssignmentDialog(): React.JSX.Element | undefined {
    if (isOrderTemplateAssignmentDialogOpen) {
      return (
        <OrderTemplateAssignment
          organization={props.organization}
          availableOrderTemplates={new Set(orderTemplatesOfHomeOrganization)}
          selectedOrderTemplates={new Set(orderTemplatesOfSelectedOrganization.map((f) => f.id))}
          isLoadingOrderTemplates={currentSlowOperationsCount > 0}
          assignOrderTemplates={assignOrderTemplates}
          close={(): void => setIsOrderTemplateAssignmentDialogOpen(false)}
        />
      );
    }
  }

  return (
    <>
      <Grid container direction="column" rowGap={3} sx={{ padding: "0 24px" }}>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginTop: "24px" }}
        >
          <Grid item>
            <Typography variant="h6">
              {translations.admin.texts.assignedOrderTemplates()}{" "}
              <Chip
                color="default"
                label={
                  currentSlowOperationsCount === 0 ? orderTemplatesOfSelectedOrganization.length.toString() : "???"
                }
              />
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              disabled={isRevoking || isHomeOrganization}
              onClick={(): void => setIsOrderTemplateAssignmentDialogOpen(true)}
              sx={{ visibility: isHomeOrganization ? "hidden" : "visible" }}
            >
              <Add sx={{ marginRight: 1 }} />
              {translations.admin.buttons.assignOrderTemplates()}
            </Button>
          </Grid>
        </Grid>
        <Grid item container direction="column" rowGap={2}>
          {currentSlowOperationsCount > 0 ? <Loader /> : renderOrderTemplatesList()}
        </Grid>
      </Grid>
      {renderOrderTemplateAssignmentDialog()}
    </>
  );
};
