import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import React, { JSX, useCallback, useRef, useState } from "react";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import Loader from "../../ui/loader";
import { translations } from "../../../generated/translationHelper";
import { DialogTitle } from "../../ui/dialog-title";
import { OrganizationSelect } from "../../ui/organization-select";
import {
  AuthWrapper,
  AwsConfiguration,
  ConfigurationSettings,
  ConfigurationSettingsPurpose,
  controlCardProjectConfigType,
  CreateOrderRequest,
  Format,
  OrderApiClient,
  OrganizationDto,
} from "@sade/data-access";
import { ConfigurationSelect } from "./configuration-select";
import { FormatSelect } from "./format-select";
import { PictureAsPdf } from "@mui/icons-material";
import { useNotification } from "../../ui/notification";
import { CardPreview } from "../../ui/card-preview";
import { isInteger } from "../../../utils/StringUtils";
import { ValidationError } from "../../ui/validation-error";
import { FilePickerButton } from "../../configurations-list-view/configuration-details/file-picker-button";

const CountInputMethod = {
  NumberEntered: "NUMBER ENTERED",
  CountOfAssignees: "COUNT OF EMAILS",
};

interface Props {
  title: string;
  close: () => void;
  closeAndRefresh: () => void;
}

interface FormatFieldState {
  name: string;
  value?: number;
  isValid: boolean;
  validationErrorMessage?: string;
}

export const NewOrderDialog: React.FC<Props> = (props: Props) => {
  const awsConfig = useRef(AwsConfiguration.getConfiguration());
  const apiClient = useRef(new OrderApiClient(awsConfig.current.ApiGateway.RootUrlOrders, AuthWrapper.getAccessToken));

  const maxCredentialCount = 5000;
  const [organizationId, setOrganizationId] = useState<string | undefined>(undefined);
  const [purpose, setPurpose] = useState(ConfigurationSettingsPurpose.MobileAccessCredential);
  const [configurationSettings, setConfigurationSettings] = useState<ConfigurationSettings | undefined>(undefined);
  const [format, setFormat] = useState<Format | undefined>(undefined);
  const [startIndex, setStartIndex] = useState<number | undefined>(undefined);
  const [credentialCount, setCredentialCount] = useState<number | undefined>(undefined);
  const [purchaseOrder, setPurchaseOrder] = useState<string | undefined>(undefined);
  const [credentialsPerSummary, setCredentialsPerSummary] = useState<number | undefined>(undefined);
  const [formatFields, setFormatFields] = useState<Record<string, FormatFieldState>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [combinePDFs, setCombinePDFs] = useState(false);
  const [separatePDFs, setSeparatePDFs] = useState(false);
  const [createWorksheets, setCreateWorksheets] = useState(false);
  const [assignees, setAssignees] = useState("");
  const [assigneesFilename, setAssigneesFilename] = useState<string | undefined>(undefined);
  const [countInputMethod, setCountInputMethod] = useState<string | undefined>(undefined);
  const displayNotification = useNotification();

  const [organizationIdValidationErrorMessage, setOrganizationIdValidationErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [configurationSettingsIdValidationErrorMessage, setConfigurationSettingsIdValidationErrorMessage] = useState<
    string | undefined
  >(undefined);
  const [formatValidationErrorMessage, setFormatValidationErrorMessage] = useState<string | undefined>(undefined);
  const [isStartIndexValid, setIsStartIndexValid] = useState(true);
  const [startIndexValidationErrorMessage, setStartIndexValidationErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [isAssigneesValid, setIsAssigneesValid] = useState(true);
  const [assigneesValidationErrorMessage, setAssigneesValidationErrorMessage] = useState<string | undefined>(undefined);
  const [isCredentialCountValid, setIsCredentialCountValid] = useState(true);
  const [credentialCountValidationErrorMessage, setCredentialCountValidationErrorMessage] = useState<
    string | undefined
  >(undefined);
  const [isPurchaserOrderValid, setIsPurchaserOrderValid] = useState(true);
  const [purchaserOrderValidationErrorMessage, setPurchaserOrderValidationErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [isCredentialsPerSummaryValid, setIsCredentialsPerSummaryValid] = useState(true);
  const [credentialsPerSummaryValidationErrorMessage, setCredentialsPerSummaryValidationErrorMessage] = useState<
    string | undefined
  >(undefined);

  function splitEmailsAndPhoneNumbers(emailsAndPhoneNumbers: string): {
    emails: string[];
    phoneNumbers: string[];
    neither: string[];
  } {
    const emails: string[] = [];
    const phoneNumbers: string[] = [];
    const neither: string[] = [];
    for (const candidate of emailsAndPhoneNumbers
      .split("\n")
      .map((a) => a.trim())
      .filter((a) => a.length > 0)) {
      const emailPattern = /^[^@]+@[^@]+$/; // only one @ with something (non-@) both sides of it
      const plusCount = candidate.match(/\+/gi)?.length ?? 0; // how many + symbols
      if (emailPattern.test(candidate)) {
        emails.push(candidate);
      } else if (plusCount === 1 && !candidate.includes("@") && candidate.length > 3 && candidate.length < 27) {
        phoneNumbers.push(candidate);
      } else {
        neither.push(candidate);
      }
    }

    return { emails, phoneNumbers, neither };
  }

  function validateOrganizationId(): boolean {
    let isValid: boolean;

    if (organizationId === undefined) {
      setOrganizationIdValidationErrorMessage(translations.orders.texts.valueMustBeProvided());
      isValid = false;
    } else {
      setOrganizationIdValidationErrorMessage(undefined);
      isValid = true;
    }

    return isValid;
  }

  function validateConfigurationSettingsId(): boolean {
    let isValid: boolean;

    if (configurationSettings === undefined) {
      setConfigurationSettingsIdValidationErrorMessage(translations.orders.texts.valueMustBeProvided());
      isValid = false;
    } else {
      setConfigurationSettingsIdValidationErrorMessage(undefined);
      isValid = true;
    }

    return isValid;
  }

  function validateFormat(): boolean {
    let isValid: boolean;

    if (
      format === undefined &&
      configurationSettings?.purpose === ConfigurationSettingsPurpose.MobileAccessCredential
    ) {
      setFormatValidationErrorMessage(translations.orders.texts.valueMustBeProvided());
      isValid = false;
    } else {
      setFormatValidationErrorMessage(undefined);
      isValid = true;
    }

    return isValid;
  }

  function validateFormatFields(fields: Record<string, FormatFieldState>): boolean {
    let isValid = true;
    for (const fieldDefinition of format?.extraFields ?? []) {
      const fieldCurrentState = fields[fieldDefinition.name];
      if (fieldCurrentState.value === undefined) {
        fieldCurrentState.isValid = false;
        fieldCurrentState.validationErrorMessage = translations.orders.texts.valueMustBeProvided();
        isValid = false;
      } else if (
        fieldCurrentState.value < fieldDefinition.minValue ||
        fieldCurrentState.value > fieldDefinition.maxValue
      ) {
        fieldCurrentState.isValid = false;
        fieldCurrentState.validationErrorMessage = translations.orders.texts.valueMustBeInRange({
          min: fieldDefinition.minValue,
          max: fieldDefinition.maxValue,
        });
        isValid = false;
      } else {
        fieldCurrentState.isValid = true;
        fieldCurrentState.validationErrorMessage = undefined;
      }
    }

    // React will not notice anything changed and will not re-render if we don't tell it the state has changed
    setFormatFields({ ...formatFields });

    return isValid;
  }

  function getMaxStartIndex(currentFormat?: Format, currentCredentialCount = 1): number {
    const limitedCurrentCredentialCount = Math.min(Math.max(currentCredentialCount, 1), maxCredentialCount);
    const minStartIndex = currentFormat?.idField.minValue ?? 1;
    const maxCredentialId = currentFormat?.idField.maxValue ?? maxCredentialCount;
    return Math.max(maxCredentialId - limitedCurrentCredentialCount + 1, minStartIndex);
  }

  function validateStartIndex(value?: number, currentFormat?: Format, currentCredentialCount = 0): boolean {
    let isValid: boolean;
    const minStartIndex = currentFormat?.idField.minValue ?? 1;
    const maxStartIndex = getMaxStartIndex(currentFormat, currentCredentialCount);

    if (value === undefined) {
      setIsStartIndexValid(false);
      setStartIndexValidationErrorMessage(translations.orders.texts.valueMustBeProvided());
      isValid = false;
    } else if (value < minStartIndex || value > maxStartIndex) {
      setIsStartIndexValid(false);
      setStartIndexValidationErrorMessage(
        translations.orders.texts.valueMustBeInRange({ min: minStartIndex, max: maxStartIndex })
      );
      isValid = false;
    } else {
      setIsStartIndexValid(true);
      setStartIndexValidationErrorMessage(undefined);
      isValid = true;
    }

    return isValid;
  }

  function validateCredentialCount(value?: number, currentFormat?: Format): boolean {
    let isValid: boolean;

    const minCredentialId = currentFormat?.idField.minValue ?? 1;
    const maxCredentialId = currentFormat?.idField.maxValue ?? maxCredentialCount;
    const currentMaxCredentialCount = Math.min(maxCredentialId - minCredentialId + 1, maxCredentialCount);

    if (value === undefined) {
      setIsCredentialCountValid(false);
      setCredentialCountValidationErrorMessage(translations.orders.texts.valueMustBeProvided());
      isValid = false;
    } else if (value < 1 || value > currentMaxCredentialCount) {
      setIsCredentialCountValid(false);
      setCredentialCountValidationErrorMessage(
        translations.orders.texts.valueMustBeInRange({ min: 1, max: currentMaxCredentialCount })
      );
      isValid = false;
    } else {
      setIsCredentialCountValid(true);
      setCredentialCountValidationErrorMessage(undefined);
      isValid = true;
    }

    return isValid;
  }

  function validatePurchaseOrder(value?: string): boolean {
    let isValid: boolean;

    const maxPurchaseOrderLength = 80;
    if (value === undefined || value === "") {
      setIsPurchaserOrderValid(false);
      setPurchaserOrderValidationErrorMessage(translations.orders.texts.valueMustBeProvided());
      isValid = false;
    } else if (value.length > maxPurchaseOrderLength) {
      setIsPurchaserOrderValid(false);
      setPurchaserOrderValidationErrorMessage(
        translations.orders.texts.valueCannotBeLongerThan({ maxLength: maxPurchaseOrderLength })
      );
      isValid = false;
    } else {
      setIsPurchaserOrderValid(true);
      setPurchaserOrderValidationErrorMessage(undefined);
      isValid = true;
    }

    return isValid;
  }

  function validateCredentialsPerSummary(value?: number): boolean {
    let isValid: boolean;

    if (value !== undefined && (value < 1 || value > maxCredentialCount)) {
      setIsCredentialsPerSummaryValid(false);
      setCredentialsPerSummaryValidationErrorMessage(
        translations.orders.texts.valueMustBeInRange({ min: 1, max: maxCredentialCount })
      );
      isValid = false;
    } else {
      setIsCredentialsPerSummaryValid(true);
      setCredentialsPerSummaryValidationErrorMessage(undefined);
      isValid = true;
    }

    return isValid;
  }

  function validateAssignees(assignees: string): boolean {
    let isValid: boolean;

    const { emails, phoneNumbers, neither } = splitEmailsAndPhoneNumbers(assignees);

    const maxCount = 100;
    if (emails.length > maxCount) {
      setIsAssigneesValid(false);
      setAssigneesValidationErrorMessage(translations.orders.texts.tooManyAssigneeEmails({ max: maxCount }));
      isValid = false;
    } else if (phoneNumbers.length > maxCount) {
      setIsAssigneesValid(false);
      setAssigneesValidationErrorMessage(translations.orders.texts.tooManyAssigneePhoneNumbers({ max: maxCount }));
      isValid = false;
    } else if (neither.length > 0) {
      setIsAssigneesValid(false);
      setAssigneesValidationErrorMessage(
        translations.orders.texts.invalidAssignees({ count: neither.length, sample: neither[0] })
      );
      isValid = false;
    } else {
      setIsAssigneesValid(true);
      setAssigneesValidationErrorMessage(undefined);
      isValid = true;
    }

    return isValid;
  }

  function validate(): boolean {
    const organizationIdValidationResult = validateOrganizationId();
    const configurationSettingsIdValidationResult = validateConfigurationSettingsId();
    const formatValidationResult = validateFormat();
    const formatFieldsValidationResult = validateFormatFields(formatFields);
    const startIndexValidationResult = validateStartIndex(startIndex, format, credentialCount);
    const credentialCountValidationResult =
      countInputMethod === CountInputMethod.NumberEntered ? validateCredentialCount(credentialCount) : true;
    const purchaseOrderValidationResult = validatePurchaseOrder(purchaseOrder);
    const credentialsPerSummaryValidationResult = validateCredentialsPerSummary(credentialsPerSummary);
    const assigneeValidationResults =
      countInputMethod === CountInputMethod.CountOfAssignees ? validateAssignees(assignees) : true;

    return (
      organizationIdValidationResult &&
      configurationSettingsIdValidationResult &&
      formatValidationResult &&
      formatFieldsValidationResult &&
      startIndexValidationResult &&
      credentialCountValidationResult &&
      purchaseOrderValidationResult &&
      credentialsPerSummaryValidationResult &&
      assigneeValidationResults
    );
  }
  async function createOrder(): Promise<void> {
    try {
      const { emails, phoneNumbers } = splitEmailsAndPhoneNumbers(assignees);
      const createOrderRequest: CreateOrderRequest = {
        organizationId: organizationId as string,
        configurationSettingsId: configurationSettings?.id as string,
        credentialCount:
          countInputMethod === CountInputMethod.NumberEntered
            ? (credentialCount as number)
            : emails.length + phoneNumbers.length,
        separatePDFs: separatePDFs,
        combinePDFs: combinePDFs,
        createWorksheets: createWorksheets,
        purchaseOrder: purchaseOrder,
        credentialsPerSummary: credentialsPerSummary,
        formatName: format?.name,
        credentialContents: {
          startIndex: startIndex as number,
        },
        assignToEmails: emails,
        assignToPhoneNumbers: phoneNumbers,
      };

      for (const field in formatFields) {
        createOrderRequest.credentialContents[field] = formatFields[field].value as number;
      }

      await apiClient.current.createOrder(createOrderRequest);

      displayNotification({
        title: translations.orders.texts.orderPlacedTitle(),
        message: translations.orders.texts.orderPlacedMessage(),
        variant: "success",
      });

      props.closeAndRefresh();
    } catch (error) {
      const errorDetail = error instanceof Error ? error.message : translations.orders.texts.unknownError();
      displayNotification({
        title: translations.orders.texts.orderPlacingFailedTitle(),
        message: translations.orders.texts.orderPlacingFailedMessage({
          purchaseOrder: purchaseOrder ?? "",
          message: errorDetail,
        }),
        variant: "error",
      });
    }
  }
  async function validateAndCreateOrder(): Promise<void> {
    try {
      setIsLoading(true);

      if (validate()) {
        await createOrder();
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function selectOrganization(organization: OrganizationDto): Promise<void> {
    setOrganizationId(organization.id);
    setConfigurationSettings(undefined);
    setFormat(undefined);
    setFormatFields({});
  }
  const selectPurpose = useCallback((newPurpose: ConfigurationSettingsPurpose): void => {
    setPurpose(newPurpose);
    setConfigurationSettings(undefined);
    setFormat(undefined);
    setFormatFields({});
  }, []);
  async function selectConfigurationSettings(configurationSettings: ConfigurationSettings): Promise<void> {
    try {
      setConfigurationSettings(configurationSettings);
    } catch (e) {
      console.error("selectConfigurationSettings", e);
    }
  }
  function selectFormat(format: Format): void {
    setFormat(format);
    const newFormatFields = format.extraFields.reduce<Record<string, FormatFieldState>>((result, field) => {
      result[field.name] = {
        name: field.name,
        isValid: true,
      };

      return result;
    }, {});
    setFormatFields(newFormatFields);
    if (credentialCount !== undefined) {
      validateCredentialCount(credentialCount, format);
    }
    if (startIndex !== undefined) {
      validateStartIndex(startIndex, format, credentialCount);
    }
  }

  function renderPurposeSelection(): JSX.Element {
    return (
      <>
        <InputLabel id="label-purpose-select">{translations.orders.inputs.purpose()}</InputLabel>
        <Select
          id="purpose-select"
          labelId="label-purpose-select"
          value={purpose}
          onChange={async (event: SelectChangeEvent): Promise<void> => {
            selectPurpose(event.target.value as ConfigurationSettingsPurpose);
          }}
          displayEmpty={false}
          style={{ width: "100%" }}
          size="small"
        >
          <MenuItem
            value={ConfigurationSettingsPurpose.MobileAccessCredential}
            key={ConfigurationSettingsPurpose.MobileAccessCredential}
          >
            {translations.orders.inputs.mobileAccessCredential()}
          </MenuItem>
          <MenuItem value={ConfigurationSettingsPurpose.ControlCard} key={ConfigurationSettingsPurpose.ControlCard}>
            {translations.orders.inputs.controlCard()}
          </MenuItem>
        </Select>
        <ValidationError> </ValidationError>
      </>
    );
  }

  function renderConfigurationSelection(): JSX.Element {
    return (
      <ConfigurationSelect
        selectConfigurationSettings={selectConfigurationSettings}
        organizationId={organizationId}
        purpose={purpose}
        currentlySelectedConfigurationSettings={configurationSettings?.id}
        validationErrorMessage={configurationSettingsIdValidationErrorMessage}
      />
    );
  }

  function renderFormatSelection(): JSX.Element {
    return (
      <FormatSelect
        selectFormat={selectFormat}
        organizationId={organizationId}
        currentlySelectedFormatName={format?.name}
        validationErrorMessage={formatValidationErrorMessage}
      />
    );
  }

  function renderFormatFieldInputs(): JSX.Element[] {
    const extraFields = format ? format.extraFields : [{ name: "null", minValue: 0, maxValue: 0 }];

    const result: JSX.Element[] = [];

    for (let i = 0; i < extraFields.length; i += 2) {
      result.push(
        <Grid item container direction="row" key={i} spacing={2}>
          {extraFields.slice(i, i + 2).map((field) => {
            return (
              <Grid item key={field.name} lg>
                <InputLabel htmlFor={field.name}>{field.name}</InputLabel>
                <TextField
                  id={"extraFields_" + field.name}
                  type="number"
                  value={field.name in formatFields ? formatFields[field.name].value ?? "" : ""}
                  onChange={(e): void => {
                    const newFields = { ...formatFields };
                    newFields[field.name].value = isInteger(e.target.value) ? parseInt(e.target.value) : undefined;
                    setFormatFields(newFields);
                    validateFormatFields(newFields);
                  }}
                  error={!formatFields[field.name]?.isValid ?? false}
                  helperText={formatFields[field.name]?.validationErrorMessage ?? " "}
                  InputProps={{
                    inputProps: { min: field.minValue, max: field.maxValue, "aria-label": "extraFields-" + field.name },
                  }}
                  fullWidth
                  size="small"
                  placeholder={field.minValue + " - " + field.maxValue}
                />
              </Grid>
            );
          })}

          {/* a hidden grid item to stop the last one from expanding */}
          {i === extraFields.length - 1 ? <Grid item key="spaceHolder" lg /> : <></>}
        </Grid>
      );
    }

    return result;
  }

  function renderFormatRelatedInputs(): JSX.Element {
    return (
      <Grid
        item
        visibility={
          configurationSettings?.purpose === ConfigurationSettingsPurpose.MobileAccessCredential ? "visible" : "hidden"
        }
        key={`formats_${organizationId}`}
      >
        <Box>{renderFormatSelection()}</Box>
        <Box visibility={format !== undefined ? "visible" : "hidden"}>{renderFormatFieldInputs()}</Box>
      </Grid>
    );
  }

  function renderControlCardDetails(): JSX.Element {
    return (
      <Grid
        item
        container
        direction="row"
        visibility={
          configurationSettings?.purpose === ConfigurationSettingsPurpose.ControlCard &&
          configurationSettings?.controlCardType === controlCardProjectConfigType
            ? "visible"
            : "hidden"
        }
        key={`cc_${configurationSettings?.id}`}
      >
        <Grid item xs={6}>
          <InputLabel>{translations.orders.texts.projectId()}</InputLabel>
          <Typography>{configurationSettings?.controlCardProjectId ?? "\u00a0"}</Typography>
        </Grid>
        <Grid item xs={6}>
          <InputLabel>{translations.orders.texts.newMaxSecureCode()}</InputLabel>
          <Typography>{configurationSettings?.controlCardMaxSecureCode ?? "\u00a0"}</Typography>
        </Grid>
      </Grid>
    );
  }

  function renderStartIndexAndReferenceInputs(): JSX.Element {
    return (
      <Grid
        item
        container
        direction="row"
        spacing={2}
        visibility={
          format !== undefined || configurationSettings?.purpose === ConfigurationSettingsPurpose.ControlCard
            ? "visible"
            : "hidden"
        }
        key="order_details"
      >
        <Grid item xs={6}>
          <InputLabel htmlFor="firstCardIndex">{translations.orders.inputs.credentialIdsStartFrom()}</InputLabel>
          <TextField
            id="firstCardIndex"
            type="number"
            onChange={(e): void => {
              const newValue = isInteger(e.target.value) ? parseInt(e.target.value) : undefined;
              setStartIndex(newValue);
              validateStartIndex(newValue, format, credentialCount);
            }}
            error={!isStartIndexValid}
            helperText={startIndexValidationErrorMessage ?? " "}
            InputProps={{
              inputProps: {
                min: format?.idField.minValue ?? 1,
                max: getMaxStartIndex(format, credentialCount),
                "aria-label": "card-no-start-from",
              },
            }}
            size="small"
            fullWidth
            placeholder={(format?.idField.minValue ?? 1) + " - " + getMaxStartIndex(format, credentialCount)}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel htmlFor="reference">{translations.orders.inputs.purchaseOrder()}</InputLabel>
          <TextField
            id="reference"
            onChange={(e): void => {
              const newValue = e.target.value;
              setPurchaseOrder(newValue);
              validatePurchaseOrder(newValue);
            }}
            error={!isPurchaserOrderValid}
            helperText={purchaserOrderValidationErrorMessage ?? " "}
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  function renderSelectedDeliveryMethod(): JSX.Element | undefined {
    if (countInputMethod === CountInputMethod.CountOfAssignees) {
      return (
        <>
          <Grid item>
            <InputLabel htmlFor="assignees">{translations.orders.inputs.assignees()}</InputLabel>
            <TextField
              fullWidth
              id="assignees"
              InputProps={{ inputProps: { "aria-label": "assignees" } }}
              multiline
              minRows={3}
              maxRows={3}
              value={assignees}
              error={isAssigneesValid}
              placeholder={translations.orders.inputs.enterOneNumberOrAddressEachLine()}
              onChange={(e): void => {
                const newAssignees = e.target.value;
                setAssignees(newAssignees);
                validateAssignees(newAssignees);
              }}
            />
            <ValidationError>{assigneesValidationErrorMessage ?? " "}</ValidationError>
          </Grid>
          <Grid item container direction="column" rowGap={1} sx={{ marginTop: "8px" }}>
            <Grid item>
              <InputLabel htmlFor="importAssignees">{translations.orders.inputs.importFromTextFile()}</InputLabel>
            </Grid>
            <Grid item container direction="row" columnGap={3} alignItems="center">
              <Grid item sm={3}>
                <FilePickerButton
                  isLoading={false}
                  selectFile={async (file): Promise<void> => {
                    const newAssignees = await file.text();
                    setAssignees(newAssignees);
                    setAssigneesFilename(file.name);
                    validateAssignees(newAssignees);
                  }}
                  id="importAssignees"
                />
              </Grid>
              <Grid item sm={6}>
                {assigneesFilename}
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction="column" rowGap={1} sx={{ marginTop: "16px" }}>
            <Grid item>
              <InputLabel>{translations.orders.texts.numberOfCredentials()}</InputLabel>
            </Grid>
            <Grid item>{assignees.split("\n").filter((a) => a.trim().length > 0).length}</Grid>
          </Grid>
        </>
      );
    } else if (countInputMethod === CountInputMethod.NumberEntered) {
      return (
        <Grid container direction="column">
          <Grid item sm>
            <InputLabel htmlFor="quantity">{translations.orders.inputs.quantity()}</InputLabel>
            <TextField
              id="quantity"
              type="number"
              onChange={(e): void => {
                const newValue = isInteger(e.target.value) ? parseInt(e.target.value) : undefined;
                setCredentialCount(newValue);
                validateCredentialCount(newValue);
                validateStartIndex(startIndex, format, newValue);
              }}
              error={!isCredentialCountValid}
              helperText={credentialCountValidationErrorMessage ?? " "}
              InputProps={{ inputProps: { min: 1, max: maxCredentialCount, "aria-label": "quantity" } }}
              size="small"
              placeholder={"1 - " + maxCredentialCount}
            />
          </Grid>
          <Grid
            item
            container
            direction="column"
            spacing={0}
            visibility={
              format !== undefined || configurationSettings?.purpose === ConfigurationSettingsPurpose.ControlCard
                ? "visible"
                : "hidden"
            }
          >
            <FormLabel>{translations.orders.texts.delivery()}</FormLabel>
            <Grid item>
              <FormControlLabel
                control={<Checkbox onChange={(_, v): void => setCombinePDFs(v)} color="primary" size="small" />}
                label={translations.orders.inputs.combinePDFs()}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={<Checkbox onChange={(_, v): void => setSeparatePDFs(v)} color="primary" size="small" />}
                label={translations.orders.inputs.separatePDFs()}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={<Checkbox onChange={(_, v): void => setCreateWorksheets(v)} color="primary" size="small" />}
                label={translations.orders.inputs.createWorksheets()}
              />
            </Grid>
          </Grid>
          <Grid item visibility={combinePDFs || separatePDFs || createWorksheets ? "visible" : "hidden"}>
            <InputLabel htmlFor="credentialsPerSummary" sx={{ marginTop: 2 }}>
              {translations.orders.inputs.credentialsPerSummary()}
            </InputLabel>
            <TextField
              id="credentialsPerSummary"
              type="number"
              onChange={(e): void => {
                const newValue = isInteger(e.target.value) ? parseInt(e.target.value) : undefined;
                setCredentialsPerSummary(newValue);
                validateCredentialsPerSummary(newValue);
              }}
              error={!isCredentialsPerSummaryValid}
              helperText={credentialsPerSummaryValidationErrorMessage ?? " "}
              InputProps={{
                inputProps: { min: 1, max: maxCredentialCount },
                startAdornment: (
                  <InputAdornment position="start">
                    <PictureAsPdf></PictureAsPdf>
                  </InputAdornment>
                ),
              }}
              size="small"
              sx={{ width: 120 }}
            />
          </Grid>
        </Grid>
      );
    }
  }

  function renderDeliveryMethodSelection(): JSX.Element | undefined {
    if (format !== undefined || configurationSettings?.purpose === ConfigurationSettingsPurpose.ControlCard) {
      return (
        <>
          <Grid item>
            <InputLabel htmlFor="countInputMethod">
              {translations.orders.inputs.credentialCountInputMethod()}
            </InputLabel>
            <Select
              id="countInputMethod"
              value={countInputMethod ?? ""}
              onChange={(e): void => {
                setCountInputMethod(e.target.value);
              }}
              displayEmpty={false}
              style={{ width: "100%" }}
              size="small"
              aria-label="delivery-method-selection"
            >
              <MenuItem id={CountInputMethod.NumberEntered} value={CountInputMethod.NumberEntered}>
                {translations.orders.inputs.numberEntered()}
              </MenuItem>
              <MenuItem id={CountInputMethod.CountOfAssignees} value={CountInputMethod.CountOfAssignees}>
                {translations.orders.inputs.numberOfAssignees()}
              </MenuItem>
            </Select>
            <ValidationError> </ValidationError>
          </Grid>
          {renderSelectedDeliveryMethod()}
        </>
      );
    } else {
      return <></>;
    }
  }

  return (
    <Dialog open={true} onClose={props.close} maxWidth="xl" fullWidth={true}>
      <DialogTitle onClose={props.close}>{props.title}</DialogTitle>
      <MuiDialogContent>
        <Grid
          container
          maxWidth={2100}
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          columnGap={1}
          margin="auto"
        >
          <Grid item container direction="column" rowSpacing={0} xs={12} lg={5.5} xl={3.5}>
            <Grid item>
              <OrganizationSelect
                selectedOrganizationId={organizationId}
                enableSelectingAll={false}
                selectOrganization={selectOrganization}
                validationErrorMessage={organizationIdValidationErrorMessage}
              />
            </Grid>
            <Grid item visibility={organizationId !== undefined ? "visible" : "hidden"}>
              {renderPurposeSelection()}
            </Grid>
            <Grid item visibility={organizationId !== undefined ? "visible" : "hidden"}>
              {renderConfigurationSelection()}
            </Grid>
            {purpose === ConfigurationSettingsPurpose.MobileAccessCredential
              ? [renderFormatRelatedInputs(), renderStartIndexAndReferenceInputs(), renderControlCardDetails()]
              : [renderStartIndexAndReferenceInputs(), renderControlCardDetails(), renderFormatRelatedInputs()]}
            {/* render everything every time to keep UI size the same for every selection, but some of it is hidden, hidden elements at the bottom */}
          </Grid>
          <Grid item container direction="column" rowSpacing={0} xs={12} lg={5} xl={3.5}>
            {renderDeliveryMethodSelection()}
          </Grid>
          <Grid item xs={12} xl={4}>
            <CardPreview
              configurationSettingsValues={
                configurationSettings !== undefined
                  ? {
                      configurationSettingsId: configurationSettings.id,
                      credentialId: startIndex ?? 0,
                      extraValue:
                        format?.extraFields[0]?.name !== undefined
                          ? formatFields[format.extraFields[0].name]?.value ?? 456
                          : undefined,
                    }
                  : undefined
              }
              width={492}
            />
          </Grid>
        </Grid>
      </MuiDialogContent>
      <MuiDialogActions>
        <Button variant="outlined" color="primary" onClick={props.close}>
          {translations.common.buttons.cancel()}
        </Button>
        <Button variant="contained" color="primary" onClick={validateAndCreateOrder} disabled={isLoading}>
          <Loader
            size={1}
            topBottomPadding={"0"}
            leftRightPadding={"0"}
            hidden={!isLoading}
            styles={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
          />
          <span style={{ visibility: isLoading ? "hidden" : "visible" }}>
            {translations.orders.buttons.createOrder()}
          </span>
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
};
