import React, { Component } from "react";
import { Button, Dialog, Grid, IconButton, TextField, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { AuthWrapper } from "@sade/data-access";
import { translations } from "../../../generated/translationHelper";

interface Props {
  firstName?: string;
  lastName?: string;
  dialogOpen: boolean;
  onDialogClose: (saveSuccessful?: boolean) => void;
}

interface State {
  textFieldFirstName?: string;
  textFieldLastName?: string;
  nameUpdateIsValid: boolean;
}

export default class EditNameDialog extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      nameUpdateIsValid: false,
    };
  }

  public componentDidMount(): void {
    this.setState({
      textFieldFirstName: this.props.firstName,
      textFieldLastName: this.props.lastName,
    });
  }

  private setNameChangeValidity = (): void => {
    const firstName = this.state.textFieldFirstName;
    const lastName = this.state.textFieldLastName;
    this.setState({
      nameUpdateIsValid:
        (firstName !== this.props.firstName || lastName !== this.props.lastName) &&
        firstName != null &&
        lastName != null,
    });
  };

  private updateName = async (): Promise<void> => {
    if (!this.state.nameUpdateIsValid) return;
    try {
      await AuthWrapper.setName(this.state.textFieldFirstName ?? "", this.state.textFieldLastName ?? "");
      this.props.onDialogClose(true);
    } catch (error) {
      console.error(error);
      this.props.onDialogClose(false);
    }
  };

  public render(): React.ReactNode {
    return (
      <Dialog open={this.props.dialogOpen} fullWidth>
        <Grid container direction="column" className="edit-dialog-container">
          <Grid item>
            <Typography variant="h5">{translations.user.texts.editName()}</Typography>
          </Grid>
          <Grid item container spacing={2} direction="row" justifyContent="space-between">
            <Grid item xs={12} sm={6}>
              <TextField
                defaultValue={this.props.firstName}
                label={translations.user.inputs.firstname().toUpperCase()}
                fullWidth
                variant="outlined"
                size="small"
                id="first-name-field"
                onChange={(event): void => {
                  this.setState({ textFieldFirstName: event.target.value }, () => {
                    this.setNameChangeValidity();
                  });
                }}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                defaultValue={this.props.lastName}
                label={translations.user.inputs.lastname().toUpperCase()}
                fullWidth
                variant="outlined"
                size="small"
                id="last-name-field"
                onChange={(event): void => {
                  this.setState({ textFieldLastName: event.target.value }, () => {
                    this.setNameChangeValidity();
                  });
                }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid item container xs direction="row" justifyContent="flex-end" className="dialog-item-container">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                className="dialog-button"
                onClick={(): void => this.props.onDialogClose()}
              >
                {translations.common.buttons.cancel().toUpperCase()}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={!this.state.nameUpdateIsValid}
                className="dialog-button"
                onClick={this.updateName}
              >
                {translations.common.buttons.update().toUpperCase()}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <IconButton onClick={(): void => this.props.onDialogClose()} className="exit-button">
          <Close color="primary" />
        </IconButton>
      </Dialog>
    );
  }
}
