import { Order } from "@sade/data-access";
import React from "react";
import { pieArcLabelClasses, PieChart } from "@mui/x-charts";
import { translations } from "../../../../../generated/translationHelper";
import { deepOrange, green, purple } from "@mui/material/colors";
import { CredentialStatus } from "./CredentialStatus";

export interface Props {
  order: Order;
}

export const CredentialsPieChart: React.FC<Props> = (props: Props) => {
  return (
    <PieChart
      slotProps={{
        legend: {
          direction: "column",
          position: {
            vertical: "middle",
            horizontal: "right",
          },
          padding: {
            left: 8,
          },
          itemMarkWidth: 12,
          itemMarkHeight: 12,
          markGap: 8,
          itemGap: 8,
        },
      }}
      series={[
        {
          arcLabel: "value",
          arcLabelMinAngle: 1,

          data: [
            {
              id: 1,
              value: props.order.credentialCountDeployed,
              label: translations.orders.texts.installed(),
              color: purple[400],
            },
            {
              id: 0,
              value: props.order.credentials.filter((c) => c.status === CredentialStatus.Available).length,
              label: translations.orders.texts.available(),
              color: green[800],
            },
            {
              id: 2,
              value: props.order.credentials.filter((c) => c.status === CredentialStatus.Assigned).length,
              label: translations.orders.texts.assigned(),
              color: deepOrange[500],
            },
            {
              id: 3,
              value: props.order.credentials.filter((c) => c.status === CredentialStatus.DeliveryFailed).length,
              label: translations.orders.texts.deliveryFailed(),
              color: "#000",
            },
          ],
          innerRadius: 30,
        },
      ]}
      width={327}
      height={160}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: "#ffffff",
          fontWeight: 700,
          fontSize: 14,
          lineHeight: 20,
          letterSpacing: 0.17,
        },
      }}
    />
  );
};
