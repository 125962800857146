import React, { ChangeEvent, Component, ReactNode } from "react";
import { IconButton, InputAdornment, OutlinedTextFieldProps, TextField } from "@mui/material";
import VisibilityOnIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface Props extends Omit<OutlinedTextFieldProps, "onChange" | "variant" | "type"> {
  onChange: (password: string) => void;
}

interface State {
  password: string;
  isPasswordVisible: boolean;
}

export default class PasswordField extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      password: "",
      isPasswordVisible: false,
    };
  }

  private handleTogglingPasswordVisibility = (): void => {
    this.setState((prevState: State) => ({ isPasswordVisible: !prevState.isPasswordVisible }));
  };

  private handlePasswordChange = (event: ChangeEvent<HTMLInputElement>): void => {
    this.setState({ password: event.target.value });
    this.props.onChange(event.target.value);
  };

  public render(): ReactNode {
    return (
      <TextField
        {...this.props}
        type={this.state.isPasswordVisible ? "text" : "password"}
        variant="outlined"
        onChange={this.handlePasswordChange}
        InputProps={{
          ...this.props.InputProps,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton className="visibility-icon" edge="end" onClick={this.handleTogglingPasswordVisibility}>
                {this.state.isPasswordVisible ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
}
