import React, { useEffect, useRef, useState } from "react";
import { AuthWrapper, AwsConfiguration, ConfigFiles, OrderApiClient, CredentialCardImages } from "@sade/data-access";
import { CardImageDisplay } from "./card-image-display";

interface Props {
  orderId?: number;
  configurationSettingsValues?: {
    configurationSettingsId: string;
    credentialId: number;
    extraValue: number | undefined;
  };
  width: number;
}

export const CardPreview: React.FC<Props> = (props) => {
  const [backImage, setBackImage] = useState<string | undefined>(undefined);
  const [frontImage, setFrontImage] = useState<string | undefined>(undefined);

  const awsConfig = useRef(AwsConfiguration.getConfiguration());
  const apiClient = useRef(new OrderApiClient(awsConfig.current.ApiGateway.RootUrlOrders, AuthWrapper.getAccessToken));

  useEffect(() => {
    let stale = false;

    async function fetchImages(): Promise<void> {
      try {
        setFrontImage(undefined);
        setBackImage(undefined);

        let images: ConfigFiles | CredentialCardImages | undefined;
        if (props.orderId) {
          images = await apiClient.current.getImagesForOrder(props.orderId);
        } else if (props.configurationSettingsValues?.configurationSettingsId !== undefined) {
          images = await apiClient.current.generateCardImagePreviewForConfig({
            configurationSettingsId: props.configurationSettingsValues.configurationSettingsId,
            credentialId: props.configurationSettingsValues.credentialId,
            extraValue: props.configurationSettingsValues.extraValue,
          });
        }

        if (!stale && images) {
          setFrontImage(images.frontImage);
          setBackImage(images.backImage);
        }
      } catch (e) {
        console.error("Error on card preview fetch: " + e);
      }
    }

    fetchImages();

    return (): void => {
      stale = true;
    };
  }, [
    props.configurationSettingsValues?.configurationSettingsId,
    props.configurationSettingsValues?.credentialId,
    props.configurationSettingsValues?.extraValue,
    props.orderId,
  ]);

  return (
    <CardImageDisplay
      backImage={backImage}
      frontImage={frontImage}
      hasLoadingAnimation={props.configurationSettingsValues !== undefined || props.orderId !== undefined}
      width={props.width}
    />
  );
};
