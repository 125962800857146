enum Paths {
  ROOT = "/",
  LOGIN = "/login",
  ADMIN = "/admin",
  PROFILE = "/profile",
  ORDERS = "/orders",
  CONFIGURATIONS = "/configurations",
}

export default Paths;
