import { Add, Refresh } from "@mui/icons-material";
import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import React, { useCallback } from "react";
import { translations } from "../../../../generated/translationHelper";
import { allOrganizations, OrganizationSelect } from "../../../ui/organization-select";
import { SearchField } from "../../../ui/search-field";
import { DateRange } from "../../../ui/DateRange";
import { DateRangePicker } from "../../../ui/date-range-picker";
import { OrganizationDto } from "@sade/data-access";

interface Props {
  dateRange: DateRange;
  searchTerm: string | undefined;
  selectedOrganizationId: string | undefined;
  openNewOrderDialog: () => void;
  selectOrganization: (organization: OrganizationDto | undefined) => Promise<void>;
  filterBySearchTerm: (term: string | undefined) => Promise<void>;
  filterByDateRange: (range: DateRange) => Promise<void>;
  refresh: (createdAtRange: DateRange) => Promise<void>;
}

export const OrdersListInputs: React.FC<Props> = (props) => {
  const { selectOrganization } = props;
  const selectOrganizationOrAll = useCallback(
    async (selected: OrganizationDto | typeof allOrganizations): Promise<void> => {
      if (selected !== allOrganizations) {
        await selectOrganization(selected);
      } else {
        await selectOrganization(undefined);
      }
    },
    [selectOrganization]
  );

  return (
    <Grid container direction="row" columnSpacing={1} sx={{ paddingX: 2 }}>
      <Grid item xs={12} sm={6} lg={2}>
        <SearchField
          searchTerm={props.searchTerm}
          filterBySearchTerm={props.filterBySearchTerm}
          minimumSearchTermLength={3}
          label={translations.common.inputs.search()}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={2}>
        <OrganizationSelect
          selectedOrganizationId={props.selectedOrganizationId}
          enableSelectingAll={true}
          selectOrganization={selectOrganizationOrAll}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <DateRangePicker dateRange={props.dateRange} filterByDateRange={props.filterByDateRange} />
      </Grid>
      <Grid item xs={0} lg={true} sx={{ margin: 0 }} />
      <Grid item xs={6} lg={1} container direction="row" justifyContent="flex-end">
        <Tooltip placement="left" title={translations.orders.buttons.refresh()}>
          <IconButton
            style={{ cursor: "pointer" }}
            onClick={async (): Promise<void> => await props.refresh(props.dateRange)}
          >
            <Refresh />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item>
        <Grid container direction="column" justifyContent="center" height="100%">
          <Button variant="contained" color="primary" onClick={props.openNewOrderDialog} size="medium">
            <Add sx={{ marginRight: 1 }} />
            {translations.orders.buttons.newOrder()}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
