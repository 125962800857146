import React, { useCallback, useState } from "react";
import { Organization } from "@sade/data-access";
import OrganizationChildList from "./organization-child-list";
import { Button, Grid, Typography } from "@mui/material";
import { translations } from "../../../../generated/translationHelper";
import { Add } from "@mui/icons-material";
import { OrganizationCreationDialog } from "./organization-creation-dialog";

interface Props {
  organization: Organization;
}

export const OrganizationManagement: React.FC<Props> = (props: Props) => {
  const [isOrganizationCreationDialogOpen, setIsOrganizationCreationDialogOpen] = useState(false);

  const closeOrganizationCreationDialog = useCallback(() => {
    setIsOrganizationCreationDialogOpen(false);
  }, []);

  return (
    <>
      <Grid container direction="column" rowGap={3} sx={{ padding: "0 24px" }}>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginTop: "24px" }}
        >
          <Grid item>
            <Typography variant="h6">{translations.admin.texts.subOrganisations()}</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={(): void => setIsOrganizationCreationDialogOpen(true)}
            >
              <Add sx={{ marginRight: 1 }} />
              {translations.admin.buttons.addSubOrganization()}
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <OrganizationChildList parentOrganization={props.organization} />
        </Grid>
      </Grid>
      {isOrganizationCreationDialogOpen && (
        <OrganizationCreationDialog parentOrganization={props.organization} close={closeOrganizationCreationDialog} />
      )}
    </>
  );
};
