import { User } from "./User";
import { AWSUser } from "./AWSUser";
import { Organization } from "./Organization";
import { AWSOrganization } from "./AWSOrganization";
export function verifyUserType(user) {
    if (!AWSUser.instanceOf(user)) {
        throw new Error(`Wrong ${User.constructor.name} implementation: expect '${AWSUser.constructor.name}', received '${user.constructor.name}'`);
    }
}
export function verifyOrganizationType(organization) {
    if (!AWSOrganization.instanceOf(organization)) {
        throw new Error(`Wrong ${Organization.constructor.name} implementation: expect '${AWSUser.constructor.name}', received '${organization.constructor.name}'`);
    }
}
