import React, { HTMLAttributes, useCallback, useEffect, useRef, useState } from "react";
import { BackendFactory } from "@sade/data-access";
import { translations } from "../../../generated/translationHelper";
import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import { isInteger } from "../../../utils/StringUtils";
import { ValidationError } from "../../ui/validation-error";

export interface Props {
  initialMaxSecureCode: number | undefined;
  label: string;
  selectMaxSecureCode: (selectedValue: number | undefined) => void;
  validationErrorMessage?: string;
  disabled?: boolean;
}

interface MaxSecureCode {
  maxSecureCode: number;
  name: string;
}

export const MaxSecureCodeSelect: React.FC<Props & HTMLAttributes<HTMLElement>> = (props) => {
  const organizationBackend = useRef(BackendFactory.getOrganizationBackend());

  const [selectableMaxSecureCodes, setSelectableMaxSecureCodes] = useState<MaxSecureCode[]>([
    { maxSecureCode: 0, name: translations.configurations.inputs.default() },
  ]);
  const [selectedValue, setSelectedValue] = useState<number | undefined>(props.initialMaxSecureCode);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const { selectMaxSecureCode } = props;

  const getSelectableMaxSecureCodes = useCallback(async (): Promise<MaxSecureCode[]> => {
    const results: MaxSecureCode[] = [{ maxSecureCode: 0, name: translations.configurations.inputs.default() }];
    // 0 is a value we always want to be available in the menu

    const homeOrganization = await organizationBackend.current.getCurrentHomeOrganization();
    const childOrganizations = (await homeOrganization.getAllChildOrganizationsRecursively()).sort((a, b) =>
      a.getName().localeCompare(b.getName())
    );

    for (const org of [homeOrganization, ...childOrganizations]) {
      const maxSecureCode = org.getMaxSecureCode();

      if (maxSecureCode !== 0) {
        results.push({ maxSecureCode, name: org.getName() });
      }
    }

    return results;
  }, []);

  useEffect(() => {
    getSelectableMaxSecureCodes()
      .then(setSelectableMaxSecureCodes)
      .catch((e): void => {
        setSelectableMaxSecureCodes([]);
        setErrorMessage(translations.configurations.texts.loadingMaxSecureCodesFailed());
        console.error("Error loading maxsecure codes", e);
      });
  }, [getSelectableMaxSecureCodes]);

  const select = useCallback(
    (newMaxSecureCode: number | undefined): void => {
      setSelectedValue(newMaxSecureCode);
      selectMaxSecureCode(newMaxSecureCode);
    },
    [selectMaxSecureCode]
  );

  const handleChange = useCallback(
    (_: React.SyntheticEvent, newValue: number | MaxSecureCode | string | null): void => {
      if (typeof newValue === "number") {
        select(newValue);
        setErrorMessage(undefined);
      } else if (typeof newValue === "object" && newValue !== undefined && newValue !== null) {
        select(newValue.maxSecureCode);
        setErrorMessage(undefined);
      } else if (typeof newValue === "string" && isInteger(newValue)) {
        select(parseInt(newValue));
        setErrorMessage(undefined);
      } else if (typeof newValue === "string") {
        // the value might be a string that contains a max secure code and organisation name separated by a dash
        // let's see can we find a number from the beginning of the string and use that as the new max secure code value

        const pattern = new RegExp(/^\s*(?<parsedMaxSecureCode>[0-9]+)\s*-.*$/);
        // zero or more whitespace followed by numbers followed by zero or more whitespace followed by a dash followed by anything

        const matches = pattern.exec(newValue)?.groups;
        if (matches !== undefined && "parsedMaxSecureCode" in matches) {
          select(parseInt(matches["parsedMaxSecureCode"]));
          setErrorMessage(undefined);
        } else {
          // user typed in something invalid that is not a number and does not match the pattern we are looking for
          // let's clear previously selected value
          select(undefined);
          setErrorMessage(translations.configurations.texts.maxSecureCodeMustBeNumber());
        }
      } else {
        select(undefined);
        setErrorMessage(translations.configurations.texts.maxSecureCodeMustBeNumber());
      }
    },
    [select]
  );

  return (
    <>
      <Autocomplete<number | MaxSecureCode, false, false, true>
        value={selectedValue}
        onChange={handleChange}
        options={selectableMaxSecureCodes}
        getOptionLabel={(option): string => {
          if (typeof option === "object") {
            return `${option.maxSecureCode.toString().padEnd(8, " ")}\t- ${option.name}`;
          } else {
            return option.toString();
          }
        }}
        filterOptions={(options, params): (number | MaxSecureCode)[] => {
          const filtered = createFilterOptions<number | MaxSecureCode>()(options, params);
          const { inputValue } = params;

          if (isInteger(inputValue)) {
            const inputMaxSecureCode = parseInt(inputValue);
            if (!selectableMaxSecureCodes.some((x) => x.maxSecureCode === inputMaxSecureCode)) {
              // user typed a value that is not among our predefined options, let's add the user given value to be listed in the menu
              filtered.push({
                maxSecureCode: inputMaxSecureCode,
                name: `(${translations.configurations.inputs.newValue()})`,
              });
            }
          }

          return filtered;
        }}
        disabled={props.disabled ?? false}
        freeSolo
        autoSelect
        selectOnFocus
        handleHomeEndKeys
        size="small"
        fullWidth
        renderInput={(params): React.ReactNode => <TextField {...params} />}
        slotProps={{ paper: { sx: { whiteSpace: "pre" } } }}
        id={props.id}
      />
      <ValidationError>{props.validationErrorMessage ?? errorMessage ?? "\u00a0"}</ValidationError>
    </>
  );
};
