import { Avatar, SxProps, Theme } from "@mui/material";
import React, { FunctionComponent, ReactElement } from "react";
import { AccountCircle } from "@mui/icons-material";

interface Props {
  firstname?: string;
  lastname?: string;
  sx?: SxProps<Theme>;
}

export const ProfileAvatar: FunctionComponent<Props> = (props: Props): ReactElement => {
  const { firstname, lastname } = props;
  const avatarLetters = (firstname ? firstname[0] : "") + (lastname ? lastname[0] : "");
  return avatarLetters !== "" ? (
    <Avatar sx={{ backgroundColor: "#EE2737", color: "white", ...props.sx }}>{avatarLetters}</Avatar>
  ) : (
    <Avatar sx={{ backgroundColor: "#EE2737", color: "white", ...props.sx }}>
      <AccountCircle sx={{ color: "#EE2737", backgroundColor: "white", width: "121%", height: "121%" }} />
    </Avatar>
  );
};
