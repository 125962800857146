import React, { useCallback, useRef } from "react";
import {
  AuthWrapper,
  AwsConfiguration,
  ConfigurationSettings,
  ConfigurationSettingsPurpose,
  OrderApiClient,
} from "@sade/data-access";
import { translations } from "../../../generated/translationHelper";
import { SelectItem, SelectWithLoading } from "../../ui/select-with-loading";

interface Props {
  organizationId?: string;
  purpose?: ConfigurationSettingsPurpose;
  currentlySelectedConfigurationSettings?: string;
  validationErrorMessage?: string;
  selectConfigurationSettings: (configurationSettings: ConfigurationSettings) => void;
}

export const ConfigurationSelect: React.FC<Props> = (props) => {
  const awsConfig = useRef(AwsConfiguration.getConfiguration());
  const apiClient = useRef(new OrderApiClient(awsConfig.current.ApiGateway.RootUrlOrders, AuthWrapper.getAccessToken));
  const { selectConfigurationSettings, organizationId, purpose } = props;

  const getSelectableConfigurationSettings = useCallback(async (): Promise<SelectItem<ConfigurationSettings>[]> => {
    if (organizationId !== undefined) {
      const all = await apiClient.current.getConfigurationSettingsForOrganization(organizationId);
      return all
        .filter((c) => c.organization.id === organizationId) // filter out child org configs
        .filter((c) => c.purpose === purpose || purpose === undefined)
        .filter((c) => c.orderCreationAllowed)
        .map((c) => ({ id: c.id, name: c.name.trim(), item: c }));
    } else {
      return [];
    }
  }, [organizationId, purpose]);

  const select = useCallback(
    async (selected: ConfigurationSettings): Promise<void> => {
      selectConfigurationSettings(selected);
    },
    [selectConfigurationSettings]
  );

  return (
    <SelectWithLoading<ConfigurationSettings>
      label={translations.orders.inputs.configuration()}
      selectedId={props.currentlySelectedConfigurationSettings}
      enableSelectingAll={false}
      select={select}
      loadItems={getSelectableConfigurationSettings}
      validationErrorMessage={props.validationErrorMessage}
      id="configuration-select"
      key={`${organizationId}_${purpose}`}
    />
  );
};
