import React, { ChangeEvent, Component } from "react";
import { Autocomplete, Button, Dialog, Grid, IconButton, TextField, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { translations } from "../../../generated/translationHelper";
import countryCodes from "country-codes-list";
import { AuthWrapper } from "@sade/data-access";
import ConfirmCodeDialog from "./confirm-code-dialog";

type CountryCode = { countryName: string; countryCode: string; countryId: string };
const EmptyCountry: CountryCode = { countryName: "", countryCode: "", countryId: "" };

interface Props {
  mobile?: string;
  dialogOpen: boolean;
  onDialogClose: (saveSuccessful?: boolean) => void;
}

interface State {
  countryCode?: string;
  countryId?: string;
  countryCodesList: CountryCode[];
  phoneNumber?: string;
  autoCompleteInput?: string;
  confirmRequest?: {
    title: string;
    msg: string;
    confirm: (code: string) => Promise<void>;
  };
  confirmDialogOpen: boolean;
  errorState: boolean;
}

export default class EditMobileDialog extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      countryCodesList: this.generateCountryCodesList(),
      confirmDialogOpen: false,
      errorState: false,
    };
  }

  public componentDidMount(): void {
    const { mobile } = this.props;
    const countryCode = this.findCountryCode();
    if (!countryCode) return;
    const phoneNumber = mobile?.substring(countryCode.length);
    this.setState({
      countryCode,
      phoneNumber,
    });
  }

  private updateMobile = async (): Promise<void> => {
    const { phoneNumber, countryCode } = this.state;
    if (!phoneNumber || !countryCode) return;
    const completeNumber = countryCode?.concat(phoneNumber);
    try {
      this.setState({ confirmDialogOpen: true });
      const confirmCodeCallback = await AuthWrapper.setPhoneNumber(completeNumber);
      this.setState({
        confirmRequest: {
          title: translations.user.texts.confirmPhoneNumberTitle(),
          msg: translations.user.texts.confirmPhoneNumberMsg({ phoneNumber: completeNumber }),
          confirm: confirmCodeCallback,
        },
      });
    } catch (error) {
      this.setState({ errorState: true });
      console.error("handlePhoneNumberSubmit", error);
    }
  };

  private handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    this.setState({ errorState: false });
    const input = event.target.value;
    this.setPhoneNumber(input);
  };

  private setPhoneNumber = (input: string): void => {
    const { countryCode } = this.state;
    this.setState({
      phoneNumber: input.substring(countryCode ? countryCode?.length + 1 : 0),
    });
  };

  private generateCountryCodesList = (): CountryCode[] => {
    const countries = countryCodes.customArray({
      countryName: "{countryNameEn}",
      countryCode: "+{countryCallingCode}",
      countryId: "{countryCode}",
    }) as CountryCode[];
    return countries;
  };

  private findCountryCode = (): string | undefined => {
    const { mobile } = this.props;
    if (!mobile) return;
    const countryCodes = new Set(this.state.countryCodesList.map((country) => country.countryCode));
    for (
      let countryCode = mobile.substring(0, 5);
      countryCode.length > 1;
      countryCode = countryCode.substring(0, countryCode.length - 1)
    ) {
      if (countryCodes.has(countryCode)) return countryCode;
    }
  };

  private findCountry = (): CountryCode | undefined => {
    return this.state.countryCodesList.find((country) => {
      return country.countryId === this.state.countryId || country.countryCode === this.state.countryCode;
    });
  };

  private handleDialogClose = (confirmSuccessful?: boolean): void => {
    this.setState({
      confirmDialogOpen: false,
    });
    this.props.onDialogClose(confirmSuccessful);
  };

  private confirmationDialog = (): React.ReactNode => {
    const { confirmRequest, phoneNumber, countryCode } = this.state;
    if (!confirmRequest || !phoneNumber || !countryCode) return;
    return (
      <ConfirmCodeDialog
        dialogOpen={this.state.confirmDialogOpen}
        title={confirmRequest.title}
        msg={confirmRequest.msg}
        onConfirm={confirmRequest.confirm}
        onDialogClose={this.handleDialogClose}
        onResend={this.updateMobile}
      />
    );
  };

  public render(): React.ReactNode {
    const { phoneNumber, countryCode, errorState } = this.state;
    return (
      <Dialog open={this.props.dialogOpen} fullWidth>
        {this.confirmationDialog()}
        <Grid container direction="column" className="edit-dialog-container">
          <Grid item>
            <Typography variant="h5">{translations.user.texts.editMobile()}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{translations.user.texts.editMobileInstructions()}</Typography>
          </Grid>
          <Grid item container spacing={2} direction="row" justifyContent="space-between">
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="country-code-field"
                value={this.findCountry() ?? EmptyCountry}
                options={this.state.countryCodesList.sort((a, b) => -b.countryName[0].localeCompare(a.countryName[0]))}
                getOptionLabel={(option): string =>
                  option !== EmptyCountry ? `${option.countryName} (${option.countryCode})` : ""
                }
                onInputChange={(_event, newInputValue): void => {
                  this.setState({ autoCompleteInput: newInputValue });
                }}
                onChange={(_event, value): void => {
                  if (value === null) return;
                  this.setState({ countryCode: value.countryCode, countryId: value.countryId });
                }}
                renderInput={(params): React.ReactNode => {
                  return (
                    <TextField
                      {...params}
                      label={translations.user.inputs.countryRegion().toUpperCase()}
                      variant="outlined"
                    />
                  );
                }}
                isOptionEqualToValue={(option: CountryCode, value: CountryCode): boolean =>
                  value === option || value === EmptyCountry
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={translations.user.inputs.phonenumber().toUpperCase()}
                fullWidth
                value={`${countryCode ?? ""} ${phoneNumber ?? ""}`}
                variant="outlined"
                size="small"
                id="phone-number-field"
                onChange={this.handleChange}
                error={errorState}
                helperText={errorState ? translations.user.texts.invalidPhoneNumber() : null}
              ></TextField>
            </Grid>
          </Grid>
          <Grid item container xs direction="row" justifyContent="flex-end" className="dialog-item-container">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                className="dialog-button"
                onClick={(): void => this.props.onDialogClose()}
              >
                {translations.common.buttons.cancel().toUpperCase()}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={!countryCode || !phoneNumber || countryCode.concat(phoneNumber) === this.props.mobile}
                className="dialog-button"
                onClick={this.updateMobile}
              >
                {translations.common.buttons.update().toUpperCase()}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <IconButton onClick={(): void => this.props.onDialogClose()} className="exit-button">
          <Close color="primary" />
        </IconButton>
      </Dialog>
    );
  }
}
