import { User } from "./User";
import { Organization } from "./Organization";
import { AWSOrganization } from "./AWSOrganization";
import { verifyOrganizationType } from "./AWSTypeUtils";
import { PromiseSemaphore } from "../private-utils/PromiseSemaphore";
import { RankingOrder, Role } from "./Role";
import { isDefined } from "../../common";
import { AppSyncClientFactory, Service } from "../backend";
import { UsersOrganizationsListDocument } from "../../generated/gqlUsers";
import { throwGQLError } from "../private-utils/throwGQLError";
const PERMISSION_ASSIGN_ROLE = "organizationsRolesAssign";
export class AWSUser extends User {
    constructor(backend, parameters) {
        super(parameters);
        this.backend = backend;
        this.entityType = AWSUser;
        this.roleSemaphore = new PromiseSemaphore(() => this.backendFetchUserRoles());
        this.organizationsSemaphore = new PromiseSemaphore(() => this.backendFetchOrganizations(this));
        /**
         * Saves {@link backendFetchUserRoles} results.
         * For now, its contents are not updated (subscriptions would be needed to properly implement that)
         * @private
         */
        this.roleCache = new Map();
        /**
         * Roles user has active in {@link cacheRootId} via role inheritance
         * @private
         */
        this.inheritedRoles = [];
    }
    async getEffectivePermissions(organizationId) {
        const roles = await this.getEffectiveRoles(organizationId);
        return Role.getMinimizedPermissionsForRoles(roles);
    }
    async getHomeOrganization() {
        if (!this.homeOrganization) {
            this.homeOrganization = await this.backendGetHomeOrganization();
        }
        return this.homeOrganization;
    }
    async getOrganizations() {
        await this.organizationsSemaphore.guard();
        return this.backend.entityRelationCache.listFor(this, AWSOrganization);
    }
    async hasPermissions(organizationId, ...permissions) {
        const roles = await this.getEffectiveRoles(organizationId);
        return Role.rolesHavePermissions(roles, permissions);
    }
    async hasRole(roleId, organizationId) {
        const roles = await this.getEffectiveRoles(organizationId);
        return Boolean(roles.find((role) => role.identifier === roleId));
    }
    async assignOrganizationRoles(organizationId, roles) {
        await this.backend.assignUserOrganizationRoles(this.id, organizationId, roles.map((role) => role.identifier));
        this.roleCache.set(organizationId, roles);
        this.notifyRoleChange();
    }
    async getEffectiveRoles(organizationId) {
        const organization = organizationId !== null && organizationId !== void 0 ? organizationId : this.homeOrganizationId;
        await this.roleSemaphore.guard();
        const effectiveRoles = [...this.roleCache.entries()]
            .filter(([id]) => Organization.isParentOrEqualOrganizationId(organization, id))
            .flatMap(([_, roles]) => roles);
        // Adds inherited roles to the effective role list, if they are inherited within the context of the parameter
        if (this.cacheRootId && Organization.isParentOrEqualOrganizationId(organization, this.cacheRootId)) {
            this.inheritedRoles.forEach((role) => effectiveRoles.push(role));
        }
        const roleMap = new Map(effectiveRoles.map((role) => [role.identifier, role]));
        return [...roleMap.values()];
    }
    async getOrganizationRoles(organizationId) {
        var _a;
        await this.roleSemaphore.guard();
        return (_a = this.roleCache.get(organizationId)) !== null && _a !== void 0 ? _a : [];
    }
    async delete() {
        await this.backend.deleteUser(this.id);
        this.notifyAction((observer) => { var _a; return (_a = observer.onDelete) === null || _a === void 0 ? void 0 : _a.call(observer, this); });
        this.clearObservers();
    }
    async onRelationChange(change) {
        if (change.ofType(AWSOrganization) && this.organizationsSemaphore.invoked()) {
            const organizations = this.backend.entityRelationCache.listFor(this, AWSOrganization);
            this.notifyAction((observer) => { var _a; return (_a = observer.onOrganizationsChange) === null || _a === void 0 ? void 0 : _a.call(observer, organizations, this); });
        }
    }
    async hasSuperiorRolesToAnother(organizationId, other) {
        const [myRoles, theirRoles] = await Promise.all([
            this.getEffectiveRoles(organizationId),
            other.getEffectiveRoles(organizationId),
        ]);
        return myRoles.some((myRole) => theirRoles.every((theirRole) => myRole.getRankingOrder(theirRole) === RankingOrder.Superior));
    }
    async canAssignRole(organizationId, role) {
        const roles = await this.getEffectiveRoles(organizationId);
        if (!Role.rolesHavePermissions(roles, [PERMISSION_ASSIGN_ROLE]))
            return false;
        return roles.some((myRole) => myRole.getRankingOrder(role) === RankingOrder.Superior);
    }
    async getRolesUserCanAssign(organizationId) {
        const effectiveRoles = await this.getEffectiveRoles(organizationId);
        if (!Role.rolesHavePermissions(effectiveRoles, [PERMISSION_ASSIGN_ROLE]))
            return [];
        const allRoles = await this.backend.listRoles();
        return allRoles.filter((roleToAssign) => effectiveRoles.some((effectiveRole) => {
            const rankOfEffectiveRole = effectiveRole.getRankingOrder(roleToAssign);
            return rankOfEffectiveRole === RankingOrder.Superior || rankOfEffectiveRole === RankingOrder.Equal;
        }));
    }
    async backendFetchOrganizations(user) {
        const orgIds = await this.getUsersOrganizationsIds(user.getId());
        const organizations = await Promise.all(orgIds.map((orgId) => this.backend.getOrganization(orgId)));
        organizations.filter(isDefined).forEach((org) => this.backend.entityRelationCache.link(user, org));
    }
    async getUsersOrganizationsIds(userId) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.query(UsersOrganizationsListDocument, { userId });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.usersOrganizationsList)) {
            throwGQLError(response, "Failed to retrieve user's organizations");
        }
        return response.data.usersOrganizationsList.organizations;
    }
    async backendGetHomeOrganization() {
        const organization = await this.backend.getOrganization(this.homeOrganizationId);
        if (!organization) {
            throw new Error(`Home organization of user '${this.id}' does not exist`);
        }
        verifyOrganizationType(organization);
        return organization;
    }
    async backendFetchUserRoles() {
        var _a;
        const currentUser = await this.backend.getCurrentUser();
        if (!currentUser)
            console.error("No current user");
        const rootOrganizationId = (_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.homeOrganizationId) !== null && _a !== void 0 ? _a : this.homeOrganizationId;
        const result = await this.backend.getUserRoles(this.id, rootOrganizationId);
        this.roleCache = result.roleTree;
        this.cacheRootId = rootOrganizationId;
        this.inheritedRoles = result.inheritedRoles;
        this.notifyRoleChange();
    }
    notifyRoleChange() {
        const newRolesRecord = [...this.roleCache.entries()].reduce((record, entry) => {
            record[entry[0]] = entry[1];
            return record;
        }, {});
        this.notifyAction((observer) => { var _a; return (_a = observer.onRolesChange) === null || _a === void 0 ? void 0 : _a.call(observer, newRolesRecord, this); });
    }
    static instanceOf(value) {
        return value instanceof AWSUser;
    }
}
