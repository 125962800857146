import React, { JSX, ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { AuthWrapper, AwsConfiguration, Credential, Order, OrderApiClient } from "@sade/data-access";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
  useGridApiRef,
} from "@mui/x-data-grid";
import { CredentialStatusChip } from "./credential-status-chip";
import { Button, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { CredentialsPieChart } from "./CredentialsPieChart";
import { SearchField } from "../../../../ui/search-field";
import { ValidationError } from "../../../../ui/validation-error";
import { translations } from "../../../../../generated/translationHelper";
import { useNotification } from "../../../../ui/notification";
import { DeviceId } from "./DeviceId";
import { CredentialStatus } from "./CredentialStatus";
import { useSearchParams } from "react-router-dom";
import { CredentialActionsMenu } from "./credential-actions-menu";
import { CredentialAssignmentDialog } from "./credential-assignment-dialog";

export interface Props {
  order: Order;
}

class ResultIsStale extends Error {}

export const Credentials: React.FC<Props> = (props: Props) => {
  const [credentialToAssign, setCredentialToAssign] = useState<Credential | undefined>(undefined);
  function openCredentialAssignmentDialog(credential: Credential): void {
    setCredentialToAssign(credential);
  }
  function closeCredentialAssignmentDialog(): void {
    setCredentialToAssign(undefined);
  }

  const columns = useRef<GridColDef[]>([
    {
      field: "registrationKey",
      headerName: translations.configurations.texts.registrationKey(),
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams<string>): string => {
        const value = params.value;
        return value.length === 16
          ? `${value.slice(0, 4)}-${value.slice(4, 8)}-${value.slice(8, 12)}-${value.slice(12)}`
          : value; // something unexpected, but don't let that stop us, display it as is
      },
    },
    {
      field: "credentialId",
      headerName: translations.configurations.texts.credentialId(),
      flex: 1,
    },
    {
      field: "deviceId",
      headerName: translations.configurations.texts.deviceId(),
      flex: 1,
      renderCell: (params: GridRenderCellParams<Credential>): ReactElement => {
        return <DeviceId credential={params.row} orderId={props.order.orderId} />;
      },
    },
    {
      field: "statusTimestamp",
      headerName: `${translations.configurations.texts.lastChange()} (${translations.common.texts.gmt()})`,
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams<string>): string => {
        return params.value.replace(/##[0-9]{5}$/, "");
        // strip the credential ID suffix that was appended by valueGetter
      },
      valueGetter: (params: GridValueGetterParams<Credential>): string => {
        // Return value of this function is used for sorting
        // and is also passed to valueFormatter function that produces the value displayed to the user.
        // The same value is both shown in the web UI and also exported to files.
        // To keep the exported files consistent, we here format the timestamp always the same way and in UTC time,
        // ignoring user's local time zone and locale date format settings.

        const timestamp = params.row.statusTimestamp;
        const year = timestamp.getUTCFullYear();
        const month = (timestamp.getUTCMonth() + 1).toString().padStart(2, "0");
        const day = timestamp.getUTCDate().toString().padStart(2, "0");
        const hours = timestamp.getUTCHours().toString().padStart(2, "0");
        const minutes = timestamp.getUTCMinutes().toString().padStart(2, "0");
        const timestampDisplay = `${year}/${month}/${day} ${hours}:${minutes}`;

        return `${timestampDisplay}##${params.row.credentialId.toString().padStart(5, "0")}`;
        // append credential ID to the end so that when sorting by this column equal timestamp values get sorted as greater credential ID having a greater value
      },
    },
    {
      field: "status",
      headerName: translations.common.texts.status(),
      flex: 1,
      renderCell: (params: GridRenderCellParams<Credential>): ReactElement => {
        return <CredentialStatusChip credentialStatus={params.row.status} sx={{ height: "50%" }} />;
      },
    },
    {
      field: "assignedTo",
      headerName: translations.orders.texts.assignedTo(),
      flex: 1,
    },
    {
      field: "actions",
      headerName: "",
      flex: 0,
      sortable: false,
      renderCell: (params: GridRenderCellParams<Credential>): ReactElement => {
        return (
          <CredentialActionsMenu
            credential={params.row}
            openCredentialAssignmentDialog={openCredentialAssignmentDialog}
          />
        );
      },
    },
  ]);
  const searchAbortion = useRef<AbortController | undefined>(undefined);
  const awsConfig = useRef(AwsConfiguration.getConfiguration());
  const apiClient = useRef(new OrderApiClient(awsConfig.current.ApiGateway.RootUrlOrders, AuthWrapper.getAccessToken));
  const hasInitialSearchFinished = useRef(false);
  const credentialsTable = useGridApiRef();
  const displayNotification = useNotification();

  const [credentialIds, setCredentialIds] = useState<number[] | undefined>(undefined);
  const [status, setStatus] = useState(CredentialStatus.All);
  const [currentSlowOperationsCount, setCurrentSlowOperationsCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  function incrementSlowOperationsCount(): void {
    setCurrentSlowOperationsCount((previousValue) => {
      if (previousValue < 0) {
        console.debug("Incrementing credential list slow operation count from below zero, that should never happen");
      }
      return previousValue + 1;
    });
  }
  function decrementSlowOperationsCount(): void {
    setCurrentSlowOperationsCount((previousValue) => {
      if (previousValue <= 0) {
        console.debug("Decrementing credential list slow operation count to below zero, that should never happen");
      }
      return previousValue - 1;
    });
  }

  async function refreshRowAndCloseAssignmentDialog(credential: Credential): Promise<void> {
    try {
      incrementSlowOperationsCount();
      const refreshedCredential = await apiClient.current.getCredential(props.order.orderId, credential.credentialId);
      credentialsTable.current.updateRows([
        {
          credentialId: credential.credentialId,
          status: refreshedCredential.status,
          assignedTo: refreshedCredential.assignedTo,
          deviceId: refreshedCredential.deviceId,
        },
      ]);
    } catch (error) {
      const errorDetail = error instanceof Error ? error.message : translations.orders.texts.unknownError();
      displayNotification({
        title: translations.orders.texts.failedToRefreshCredential(),
        message: errorDetail,
        variant: "error",
      });
    } finally {
      decrementSlowOperationsCount();
      closeCredentialAssignmentDialog();
    }
  }

  const exportCsv = useCallback(() => {
    credentialsTable.current.exportDataAsCsv({ fileName: `credentials-${props.order.purchaseOrder}` });
  }, [credentialsTable, props.order.purchaseOrder]);

  const applyFilterCredentialIds = useCallback(
    (rows: Credential[], credentialIds?: number[]): Credential[] =>
      credentialIds !== undefined ? rows.filter((r) => credentialIds.some((id) => id === r.credentialId)) : rows,
    []
  );
  const applyFilterCredentialStatus = useCallback(
    (rows: Credential[], credentialStatus: string): Credential[] =>
      credentialStatus !== CredentialStatus.All ? rows.filter((r) => r.status === credentialStatus) : rows,
    []
  );
  let filteredRows = applyFilterCredentialIds(props.order.credentials, credentialIds);
  filteredRows = applyFilterCredentialStatus(filteredRows, status);

  const search = useCallback(
    async (term: string | undefined) => {
      function resultsAreStillRelevant(abortion: AbortController): boolean {
        return !abortion.signal.aborted || !(abortion.signal.reason instanceof ResultIsStale);
      }

      const abortion = new AbortController();
      try {
        incrementSlowOperationsCount();
        searchAbortion.current?.abort(new ResultIsStale());
        searchAbortion.current = abortion;

        if (term !== undefined) {
          const newCredentialIds = await apiClient.current.searchCredentials(
            term,
            props.order.orderId,
            searchAbortion.current
          );

          if (resultsAreStillRelevant(abortion)) {
            setCredentialIds(newCredentialIds);
          } else {
            console.debug("Stale credentials search result discarded");
          }
        } else {
          setCredentialIds(undefined);
        }
      } catch (e) {
        if (resultsAreStillRelevant(abortion)) {
          setCredentialIds([]);
          const message = e instanceof Error ? e.message : translations.orders.texts.unknownError();
          displayNotification({
            title: translations.orders.texts.searchCredentialsFailed(),
            message: message,
            variant: "error",
          });
        } else {
          console.debug("Credentials search interrupted, result would have been stale", e);
        }
      } finally {
        decrementSlowOperationsCount();
      }
    },
    [displayNotification, props.order.orderId]
  );

  useEffect(() => {
    // run the search if user opened this page with a search term in the url
    if (!hasInitialSearchFinished.current) {
      hasInitialSearchFinished.current = true;

      const searchTerm = searchParams.get("searchTerm") ?? undefined;
      if (searchTerm !== undefined) {
        search(searchTerm).catch(console.error);
      }
    }

    return (): void => {
      hasInitialSearchFinished.current = true;
    };
    // dependencies missing on purpose to not run this every time state changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectCredentialStatus = useCallback((event: SelectChangeEvent): void => {
    setStatus(event.target.value);
  }, []);

  const renderCredentialStatusList = useCallback((): React.ReactElement[] => {
    // API might return credentials with statuses that we don't have in our enum, we want those included in the list.
    // On the other hand, we want all statuses from the enum in the list even if there is a status no credential has.

    const statuses = new Set(Object.values(CredentialStatus).concat(props.order.credentials.map((c) => c.status)));

    return Array.from(statuses).map((status, index) => (
      <MenuItem value={status} key={index}>
        {status}
      </MenuItem>
    ));
  }, [props.order.credentials]);

  function renderCredentialAssignmentDialog(): JSX.Element | undefined {
    if (credentialToAssign !== undefined) {
      return (
        <CredentialAssignmentDialog
          orderId={props.order.orderId}
          credential={credentialToAssign}
          close={closeCredentialAssignmentDialog}
          closeAndRefresh={refreshRowAndCloseAssignmentDialog}
        />
      );
    }
  }

  // The following contains invisible non-breaking spaces in labels to make all grid items the same height.
  // This makes it so that vertical centering lines up the visible elements nicely.
  return (
    <>
      <Grid container direction="row" alignItems="center" justifyContent="space-between" columnGap={2} columns={21}>
        <Grid item lg={7}>
          <SearchField
            label={translations.common.inputs.search()}
            searchTerm={searchParams.get("searchTerm") ?? undefined}
            filterBySearchTerm={async (term: string | undefined): Promise<void> => {
              setSearchParams(new URLSearchParams(term !== undefined ? { searchTerm: term } : {}), { replace: true });
              await search(term);
            }}
          />
        </Grid>
        <Grid item lg={7}>
          <InputLabel>{translations.configurations.texts.credentialStatus()}</InputLabel>
          <Select onChange={selectCredentialStatus} defaultValue={"All"} displayEmpty={false} size="small" fullWidth>
            {renderCredentialStatusList()}
          </Select>
          <ValidationError sx={{ visibility: "hidden" }}>{"\u00a0"}</ValidationError>
        </Grid>
        <Grid item lg={2}>
          <InputLabel sx={{ visibility: "hidden" }}>{"\u00a0"}</InputLabel>
          <Button variant="outlined" color="primary" onClick={exportCsv}>
            {translations.configurations.buttons.exportAsCsv()}
          </Button>
          <ValidationError sx={{ visibility: "hidden" }}>{"\u00a0"}</ValidationError>
        </Grid>
        <Grid item lg={4}>
          <CredentialsPieChart order={props.order} />
        </Grid>
        <Grid item xs={21} flexGrow={1} flexShrink={1} flexBasis={0}>
          <DataGrid
            apiRef={credentialsTable}
            getRowId={(r): number => r.credentialId}
            columns={columns.current}
            rows={filteredRows}
            loading={currentSlowOperationsCount > 0}
            autoHeight={true}
            disableRowSelectionOnClick
            disableColumnSelector
            pageSizeOptions={[25, 50, 100]}
            initialState={{
              sorting: {
                sortModel: [{ field: "statusTimestamp", sort: "desc" }],
              },
              pagination: {
                paginationModel: { pageSize: 25, page: 0 },
              },
            }}
          />
        </Grid>
      </Grid>
      {renderCredentialAssignmentDialog()}
    </>
  );
};
