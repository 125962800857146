import React, { Component, Fragment } from "react";
import { Button } from "@mui/material";
import Loader from "../../../components/ui/loader";
import PasswordField from "../../ui/password-field";
import { translations } from "../../../generated/translationHelper";
import { AuthWrapper, isError, isErrorWithCode, Maybe } from "@sade/data-access";
import AuthCode from "react-auth-code-input";
import PasswordRequirements from "./password-requirements";

export interface Props {
  username: string;
  onLoginRequest: () => void;
}

interface State {
  newPassword: string;
  confirmPassword: string;
  verificationCode: string;
  codeError?: string;
  newPasswordError?: string;
  confirmPasswordError?: string;
  generalError?: string;
  isLoaderVisible?: boolean;
}

const CODE_LENGTH = 6;

export default class ForgotPasswordResetForm extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      newPassword: "",
      confirmPassword: "",
      verificationCode: "",
    };
  }

  private resendCode = async (): Promise<void> => {
    try {
      this.resetErrors();
      this.setState({ isLoaderVisible: true });
      await AuthWrapper.forgotPassword(this.props.username);
    } catch (error) {
      console.error("resendCode", error);
      if (isErrorWithCode(error)) {
        this.handleErrors(error.message);
      }
    } finally {
      this.setState({ isLoaderVisible: false });
    }
  };

  private fieldsHaveValues(): boolean {
    const { verificationCode, newPassword, confirmPassword } = this.state;
    return Boolean(verificationCode.length === CODE_LENGTH && newPassword.length && confirmPassword.length);
  }

  private passwordsMatch(): boolean {
    return this.state.newPassword === this.state.confirmPassword;
  }

  private async submitNewPassword(): Promise<void> {
    this.resetErrors();

    if (!this.passwordsMatch()) {
      this.setState({ confirmPasswordError: translations.common.texts.passwordsNotMatching() });
      return;
    }

    //passwords of length 1 will throw a regex error. This is clearer to the user.
    if (this.state.newPassword.length < 2) {
      this.setState({ newPasswordError: translations.common.texts.passwordMustBeLongEnough() });
      return;
    }

    try {
      this.setState({ isLoaderVisible: true });
      await AuthWrapper.checkCodeAndSubmitNewPassword(
        this.props.username,
        this.state.verificationCode,
        this.state.newPassword
      );
      await AuthWrapper.logIn(this.props.username, this.state.newPassword);
    } catch (error) {
      console.error("submitNewPassword", error);
      if (isError(error)) {
        this.handleErrors(error.message);
      } else {
        this.setState({ generalError: translations.common.texts.unableToPerformAction() });
      }
    } finally {
      this.setState({ isLoaderVisible: false });
    }
  }

  private handleErrors(code?: string): void {
    switch (code) {
      case "Attempt limit exceeded, please try after some time.":
        this.setState({ generalError: translations.common.texts.tooManyAttempts() });
        break;
      case "Username/client id combination not found.":
      case "Invalid verification code provided, please try again.":
        this.setState({ codeError: translations.logIn.texts.invalidCode() });
        break;
      case "Invalid code provided, please request a code again.":
        this.setState({ codeError: translations.logIn.texts.codeExpired() });
        break;
      case "Network error":
        this.setState({ generalError: translations.common.texts.networkError() });
        break;
      case "Password does not conform to policy: Password must have numeric characters":
        this.setState({ newPasswordError: translations.common.texts.passwordMustHaveNumbers() });
        break;
      case "Password does not conform to policy: Password must have lowercase characters":
        this.setState({ newPasswordError: translations.common.texts.passwordMustHaveLowercaseCharacters() });
        break;
      case "Password does not conform to policy: Password must have uppercase characters":
        this.setState({ newPasswordError: translations.common.texts.passwordMustHaveUppercaseCharacters() });
        break;
      case "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$":
        this.setState({ newPasswordError: translations.common.texts.passwordInvalid() });
        break;
      case "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
      case "Password does not conform to policy: Password not long enough":
      case "Password did not conform with policy: Password not long enough":
        this.setState({ newPasswordError: translations.common.texts.passwordMustBeLongEnough() });
        break;
      case "Provided password cannot be used for security reasons.":
        this.setState({ newPasswordError: translations.common.texts.passwordRejectedForSecurityReasons() });
        break;
      case "Invalid session for the user, session is expired.":
        this.setState({ generalError: translations.common.texts.userSessionExpired() });
        break;
      default:
        this.setState({ generalError: translations.common.texts.unableToPerformAction() });
        break;
    }
  }

  private resetErrors(): void {
    this.setState({
      generalError: undefined,
      codeError: undefined,
      newPasswordError: undefined,
      confirmPasswordError: undefined,
    });
  }

  private renderLoader = (): Maybe<JSX.Element> => {
    if (this.state.isLoaderVisible) {
      return <Loader size={1} leftRightPadding="1rem" />;
    }
  };

  private renderLoaderCounter = (): Maybe<JSX.Element> => {
    if (this.state.isLoaderVisible) {
      return <div style={{ width: "3rem" }} />;
    }
  };

  private renderBackToLogInForm(): JSX.Element {
    return (
      <Fragment>
        <div className="login-links small">
          <a className="login-back" onClick={(): void => this.props.onLoginRequest()}>
            {translations.logIn.buttons.backToLogin()}
          </a>
        </div>
      </Fragment>
    );
  }

  private renderGeneralError(): Maybe<JSX.Element> {
    if (this.state.generalError) {
      return (
        <div className="login-errortext">
          {translations.common.texts.errorOccurred({ error: this.state.generalError })}
        </div>
      );
    }
  }

  private renderCodeError(): Maybe<JSX.Element> {
    if (this.state.codeError) {
      return <div className="login-errortext">{this.state.codeError}</div>;
    }
  }

  private renderInputs(): JSX.Element {
    return (
      <Fragment>
        <div className="login-fields">
          <div className="field-container nomargin">
            <label htmlFor="VerificationCode">{translations.logIn.inputs.confirmationCode()}</label>
            <AuthCode
              inputClassName="login-code-field"
              allowedCharacters="numeric"
              length={CODE_LENGTH}
              onChange={(val): void => this.setState({ verificationCode: val })}
            />
          </div>
          {this.renderCodeError()}
          <div className="login-links right nomargin">
            <a onClick={(): Promise<void> => this.resendCode()}>{translations.logIn.buttons.resendCode()}</a>
          </div>
          <div className="field-container">
            <label htmlFor="password">{translations.common.inputs.newPassword()}</label>
            <PasswordField
              id="password"
              autoComplete="new-password"
              fullWidth={true}
              required={true}
              margin="none"
              error={this.state.newPasswordError != null}
              helperText={this.state.newPasswordError}
              onChange={(newPassword: string): void => {
                this.resetErrors();
                this.setState({ newPassword });
              }}
            />
          </div>
          <div className="field-container">
            <label htmlFor="confirm-password">{translations.common.inputs.confirmNewPassword()}</label>
            <PasswordField
              id="confirm-password"
              autoComplete="confirm-password"
              fullWidth={true}
              required={true}
              margin="none"
              error={this.state.confirmPasswordError != null}
              helperText={this.state.confirmPasswordError}
              onChange={(confirmPassword: string): void => {
                this.resetErrors();
                this.setState({ confirmPassword });
              }}
            />
          </div>
        </div>
        <PasswordRequirements />
        <div className="login-buttons">
          <Button
            disabled={!this.fieldsHaveValues() || this.state.isLoaderVisible}
            variant="contained"
            color="primary"
            onClick={(): Promise<void> => this.submitNewPassword()}
          >
            {this.renderLoader()}
            {translations.logIn.buttons.changePassword()}
            {this.renderLoaderCounter()}
          </Button>
        </div>
      </Fragment>
    );
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        <p className="login-header">{translations.logIn.texts.resetYourPassword()}</p>
        <p className="login-subheader">
          {translations.logIn.texts.enterVerificationCodeAndPassword({ email: "<split>" }).split("<split>")[0]}
          <b>{this.props.username}</b>
          {translations.logIn.texts.enterVerificationCodeAndPassword({ email: "<split>" }).split("<split>")[1]}
        </p>
        {this.renderInputs()}
        {this.renderBackToLogInForm()}
        {this.renderGeneralError()}
      </Fragment>
    );
  }
}
