import React, { PropsWithChildren, ReactElement } from "react";

interface Props<TKey> {
  value: TKey;
  index: TKey;
  retainChildrenWhenInvisible?: boolean;
}

/**
 * Displays a tab panel when {@code props.value === props.index}.
 * The index is the unique key value of the tab.
 * The value is the currently selected tab index, assigned to <Tabs value={}>
 */
const TabPanel = <TKey,>(props: PropsWithChildren<Props<TKey>>): ReactElement => {
  const { value, index, retainChildrenWhenInvisible, children, ...rest } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...rest}
    >
      {(value === index || retainChildrenWhenInvisible) && children}
    </div>
  );
};

export default TabPanel;
