import { AwsConfiguration } from "./AwsConfiguration";
import { Auth } from "@aws-amplify/auth";
import { CognitoIdentity } from "@aws-sdk/client-cognito-identity";
import { Amplify } from "@aws-amplify/core";
import { v4 as uuidv4 } from "uuid";
const PHONE_NUMBER_REGEX = /\+[0-9]+/;
const ORG_ID_PREFIX = "ORG/";
const USER_ID_PREFIX = "USER/";
// the custom claims are set by preTokenGenerationHook in users-service (but many vtl files depend on these values)
const GRANTS_CLAIM = "custom:policies";
const HOME_CLAIM = "custom:home";
const ORGANIZATIONS_CLAIM = "custom:orgs";
// REFACTOR: Hard to unit test as dependencies are built in. Could use dependency injection.
// Usage of static methods can make mocking hard in classes that use AuthWrapper.
export class AuthWrapper {
    static configureAmplify() {
        Amplify.configure(AwsConfiguration.getConfiguration());
    }
    /**
     * Log in the user
     * @param loginAlias - verified email or phone number
     * @param password
     */
    static async logIn(loginAlias, password) {
        if (!password.length) {
            throw new Error("Empty password");
        }
        const result = await Auth.signIn(loginAlias, password);
        return result;
    }
    /**
     * Refreshes users authentication tokens, allowing user to benefit
     * from potential changes to their access.
     */
    static async refreshAuthentication() {
        const [currentUser, currentSession] = await Promise.all([Auth.currentUserPoolUser(), Auth.currentSession()]);
        // Simple helper to get null check and type-coercion in one
        const isAuthenticatedUser = (user) => user != null;
        if (isAuthenticatedUser(currentUser) && currentSession) {
            // since the refreshSession is based on callback, we need to do some promise wrapping
            await new Promise((resolve, reject) => {
                currentUser.refreshSession(currentSession.getRefreshToken(), (err, result) => err ? reject(err) : resolve(result));
            });
            // resetting claim cache: getCurrentAuthenticatedUserClaims fills in the cache when called
            AuthWrapper.userClaims = undefined;
        }
    }
    /**
     * Signs up a new user
     * @param attributes - attributes for the new user
     * @param password - user's password
     */
    static async signUp(attributes, password) {
        var _a;
        if (!((_a = attributes.email) === null || _a === void 0 ? void 0 : _a.length)) {
            throw new Error("Invalid email");
        }
        if (!password.length) {
            throw new Error("Empty password");
        }
        const username = uuidv4();
        const result = await Auth.signUp({
            username,
            password,
            attributes: {
                email: attributes.email.toLowerCase(),
                given_name: attributes.givenName,
                family_name: attributes.familyName,
                "custom:language": attributes.language.toLowerCase(),
            },
        });
        return {
            result,
            confirm: (code) => this.confirmSignUp(username, code),
            resendCode: () => this.resendSignUpConfirmationCode(username),
        };
    }
    /**
     * Confirms user's signup process.
     * @param username - a verified username alias (email, phone_number), or user's uuid
     * @param code - verification code
     */
    static async confirmSignUp(username, code) {
        await Auth.confirmSignUp(username, code);
    }
    /**
     * Resends code for attribute verification
     * @param attributeToVerify
     */
    static async resendAttributeConfirmationCode(attributeToVerify) {
        await Auth.verifyCurrentUserAttribute(attributeToVerify);
    }
    /**
     * Resends code for user that is signing up.
     *
     * @param username
     */
    static async resendSignUpConfirmationCode(username) {
        await Auth.resendSignUp(username);
    }
    /**
     * Logs out the user.
     */
    static async logOut() {
        await Auth.signOut();
        AuthWrapper.userClaims = undefined;
    }
    /**
     * Sends forgot password request to cognito.
     * @param username - a verified username alias (email, phone_number), or user's uuid
     */
    static async forgotPassword(username) {
        return Auth.forgotPassword(username);
    }
    /**
     * Checks if the code is valid and submits new password.
     * @param username - a verified username alias (email, phone_number), or user's uuid
     * @param code - verification code
     * @param newPassword - new password
     */
    static async checkCodeAndSubmitNewPassword(username, code, newPassword) {
        await Auth.forgotPasswordSubmit(username, code, newPassword);
    }
    /**
     * Sets new password for the user after they have logged in with temporary password.
     * @param user - user from {@link AuthWrapper.logIn}. {@link AuthWrapper.getCurrentAuthenticatedUser} return value does not work.
     * @param password - new password
     */
    static async completeNewPassword(user, password) {
        return Auth.completeNewPassword(user, password, {});
    }
    static async getCurrentAuthenticatedUser(bypassCache) {
        try {
            return await Auth.currentAuthenticatedUser({
                bypassCache: bypassCache !== null && bypassCache !== void 0 ? bypassCache : false,
            });
        }
        catch (err) {
            console.error("Failed to get authenticated user", err);
        }
    }
    static async isCurrentUserAuthenticated(bypassCache) {
        const result = await this.getCurrentAuthenticatedUser(bypassCache);
        return Boolean(result);
    }
    static async getCurrentAuthenticatedUserClaims() {
        var _a, _b;
        if (!AuthWrapper.userClaims) {
            const authenticatedUser = await AuthWrapper.getCurrentAuthenticatedUser(false);
            if (!authenticatedUser) {
                console.error("No authenticated user");
                return;
            }
            const { username } = authenticatedUser;
            const claims = (_b = (_a = authenticatedUser === null || authenticatedUser === void 0 ? void 0 : authenticatedUser.getSignInUserSession()) === null || _a === void 0 ? void 0 : _a.getIdToken()) === null || _b === void 0 ? void 0 : _b.decodePayload();
            if (!claims || !username) {
                console.error("Cannot retrieve details for an unauthenticated user");
                return;
            }
            const organizations = (claims[ORGANIZATIONS_CLAIM] ? JSON.parse(claims[ORGANIZATIONS_CLAIM]) : [])
                // add organization prefix to IDs
                .map((organization) => ORG_ID_PREFIX + organization);
            const grantsRaw = claims[GRANTS_CLAIM] ? JSON.parse(claims[GRANTS_CLAIM]) : {};
            AuthWrapper.userClaims = {
                userId: USER_ID_PREFIX + username,
                homeOrganizationId: ORG_ID_PREFIX + claims[HOME_CLAIM],
                uniqueParentOrganizations: organizations,
                // user id in canSee value does not have user prefix, but organizations have organization prefix
                canSee: [username].concat(organizations),
                // add organization prefix to raw id keys
                grants: Object.entries(grantsRaw).reduce((acc, [key, value]) => {
                    acc[ORG_ID_PREFIX + key] = value;
                    return acc;
                }, {}),
            };
        }
        return AuthWrapper.userClaims;
    }
    static async getEmail() {
        return this.getAttribute("email");
    }
    static async getGivenName() {
        return this.getAttribute("given_name");
    }
    static async getFamilyName() {
        return this.getAttribute("family_name");
    }
    static async getPhoneNumber() {
        return this.getAttribute("phone_number");
    }
    static async getHomeOrganizationId() {
        var _a;
        return (_a = (await AuthWrapper.getCurrentAuthenticatedUserClaims())) === null || _a === void 0 ? void 0 : _a.homeOrganizationId;
    }
    static async getLanguage() {
        const attribute = await this.getAttribute("custom:language");
        return attribute === null || attribute === void 0 ? void 0 : attribute.toLowerCase();
    }
    static async setLanguage(language) {
        return this.setAttributes({ "custom:language": language.toLowerCase() });
    }
    //platform param should be the output of Platform.OS. string type used here to avoid a dependency to react native in this repo/NPM
    static async getPushNotificationTokensAttribute(platform) {
        return this.getAttribute(platform === "android" ? "custom:android_pn" : "custom:ios_pn");
    }
    static async getRdUserFlag() {
        const flag = await this.getAttribute("custom:is_rd_user");
        return (flag === null || flag === void 0 ? void 0 : flag.toLowerCase()) === "true";
    }
    static async setName(firstname, lastname) {
        return this.setAttributes({ given_name: firstname, family_name: lastname });
    }
    /**
     * Sets user's phone number and returns an {@link AttributeVerificationCB} object for entering the verification code.
     *
     * @param number
     *  International phone number
     * @see verifyPhoneNumber
     * @throws if invalid phone number
     */
    static async setPhoneNumber(number) {
        const phone_number = number.trim();
        if (!PHONE_NUMBER_REGEX.test(phone_number))
            throw new Error("Invalid phone number");
        await this.setAttributes({ phone_number });
        return (code) => AuthWrapper.verifyPhoneNumber(code);
    }
    /**
     * Verifies the phone number with the code sent to the user.
     * @param code
     */
    static async verifyPhoneNumber(code) {
        await Auth.verifyCurrentUserAttributeSubmit("phone_number", code);
    }
    /**
     * Sets user's email address and returns an {@link AttributeVerificationCB} object for entering the verification code.
     *
     * @param email
     *  International phone number
     * @see verifyEmail
     * @throws if invalid email address
     */
    static async setEmail(email) {
        const addr = email.trim();
        if (!addr.includes("@"))
            throw new Error("Invalid email address");
        await this.setAttributes({ email: addr });
        return (code) => AuthWrapper.verifyEmail(code);
    }
    /**
     * Verifies the email address with the code sent to the user.
     * @param code
     */
    static async verifyEmail(code) {
        await Auth.verifyCurrentUserAttributeSubmit("email", code);
    }
    //platform param should be the output of Platform.OS. string type used here to avoid a dependency to react native in this repo/NPM
    static async setPushNotificationTokens(tokens, platform) {
        console.log(`setPushNotificationTokens ${tokens}`);
        switch (platform) {
            case "android":
                return this.setAttributes({ "custom:android_pn": tokens });
            case "ios":
                return this.setAttributes({ "custom:ios_pn": tokens });
        }
    }
    /**
     * Changes the user's password.
     * @param oldPassword
     * @param newPassword
     */
    static async submitNewPassword(oldPassword, newPassword) {
        const loggedInUser = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(loggedInUser, oldPassword, newPassword);
    }
    static async getAccessToken() {
        const currentSession = await Auth.currentSession();
        return currentSession.getAccessToken().getJwtToken();
    }
    static async getOpenIdToken() {
        const currentSession = await Auth.currentSession();
        const awsConfig = AwsConfiguration.getConfiguration();
        const cognitoAuthenticatedLoginsKey = `cognito-idp.${awsConfig.Auth.region}.amazonaws.com/${awsConfig.Auth.userPoolId}`;
        const cognitoAuthenticatedLogins = { [cognitoAuthenticatedLoginsKey]: currentSession.getIdToken().getJwtToken() };
        if (!awsConfig.Auth.identityPoolId) {
            console.error("No identity pool id available");
            return;
        }
        const getIdParams = {
            IdentityPoolId: awsConfig.Auth.identityPoolId,
            Logins: cognitoAuthenticatedLogins,
        };
        const cognitoIdentity = new CognitoIdentity({ region: awsConfig.Auth.region });
        try {
            const cognitoIdResponse = await cognitoIdentity.getId(getIdParams);
            if (!cognitoIdResponse.IdentityId) {
                console.error("No identity id available");
                return;
            }
            const getOpenIdParams = {
                IdentityId: cognitoIdResponse.IdentityId,
                Logins: cognitoAuthenticatedLogins,
            };
            const getOpenIdTokenResponse = await cognitoIdentity.getOpenIdToken(getOpenIdParams);
            return getOpenIdTokenResponse.Token;
        }
        catch (error) {
            console.error("getOpenIdToken", error);
        }
    }
    static async getUsername() {
        const user = await this.getCurrentAuthenticatedUser();
        if (!user)
            throw new Error("User is not authenticated");
        return user.username;
    }
    static async setAttributes(attributes) {
        const loggedInUser = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(loggedInUser, Object.assign({}, attributes));
    }
    static async getAttribute(key) {
        const userInfo = await Auth.currentUserInfo();
        const attributes = userInfo === null || userInfo === void 0 ? void 0 : userInfo.attributes;
        return attributes === null || attributes === void 0 ? void 0 : attributes[key];
    }
}
