import React, { useRef, useState } from "react";
import { IconButton, InputLabel, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import { translations } from "../../generated/translationHelper";

interface Props {
  label?: string;
  searchTerm?: string;
  minimumSearchTermLength?: number;
  debounceSeconds?: number;
  filterBySearchTerm: (term: string | undefined) => Promise<void>;
}

export const SearchField: React.FC<Props> = (props) => {
  const debounceTimer = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(props.searchTerm);
  const [searchTermValidationErrorMessage, setSearchTermValidationErrorMessage] = useState<string | undefined>(
    undefined
  );

  function clearDebounceTimer(): void {
    if (debounceTimer.current !== undefined) {
      clearTimeout(debounceTimer.current);
    }
  }

  async function search(currentSearchTerm: string | undefined): Promise<void> {
    clearDebounceTimer();

    const minLength = props.minimumSearchTermLength ?? 0;
    const trimmedTerm = currentSearchTerm?.trim();
    const finalTerm = trimmedTerm !== "" ? trimmedTerm : undefined;
    if (finalTerm === undefined || finalTerm.length >= minLength) {
      setSearchTermValidationErrorMessage(undefined);
      await props.filterBySearchTerm(finalTerm);
    } else {
      setSearchTermValidationErrorMessage(translations.common.texts.searchTermMustBeAtLeastCharacters({ minLength }));
      await props.filterBySearchTerm(undefined); // stop filtering if user erased characters so that length is too short now
    }
  }

  function searchOnDebounceTimer(currentSearchTerm: string | undefined): void {
    const debounceDurationSeconds = props.debounceSeconds ?? 2;
    clearDebounceTimer();
    debounceTimer.current = setTimeout(async () => {
      try {
        await search(currentSearchTerm);
      } catch (e) {
        console.error("Error while searching on a debounce timer", e);
      }
    }, debounceDurationSeconds * 1000);
  }

  return (
    <>
      <InputLabel htmlFor="search-term-input">{props.label}</InputLabel>
      <TextField
        value={searchTerm ?? ""}
        id="search-term-input"
        onChange={(e): void => {
          setSearchTerm(e.target.value);
          searchOnDebounceTimer(e.target.value);
        }}
        size="small"
        fullWidth
        InputProps={{
          endAdornment: (
            <IconButton onClick={async (): Promise<void> => await search(searchTerm)}>
              <Search />
            </IconButton>
          ),
        }}
        onKeyDown={async (e): Promise<void> => {
          if (e.key === "Enter") {
            e.preventDefault();
            await search(searchTerm);
          }
        }}
        error={searchTermValidationErrorMessage !== undefined}
        helperText={searchTermValidationErrorMessage ?? " "}
      />
    </>
  );
};
