import { Done, ErrorOutline, PhoneIphone, PhonelinkLock, PriorityHigh } from "@mui/icons-material";
import { Chip, SxProps, Theme } from "@mui/material";
import { OrderStatus } from "@sade/data-access";
import React from "react";
import { CredentialStatus } from "./CredentialStatus";

interface Props {
  credentialStatus: string;
  sx?: SxProps<Theme>;
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    black: true;
  }
}

export const CredentialStatusChip: React.FC<Props> = (props: Props) => {
  function getColor(): "primary" | "info" | "success" | "warning" | "default" | "black" {
    switch (props.credentialStatus) {
      case CredentialStatus.Available:
        return "success";
      case CredentialStatus.Assigned:
        return "warning";
      case CredentialStatus.Deployed:
        return "info";
      case CredentialStatus.DeliveryFailed:
        return "black";
      default:
        return "default";
    }
  }

  function getIcon(): React.JSX.Element {
    switch (props.credentialStatus) {
      case CredentialStatus.Available:
        return <Done />;
      case CredentialStatus.Deployed:
        return <PhoneIphone />;
      case CredentialStatus.Assigned:
        return <PhonelinkLock />;
      case CredentialStatus.DeliveryFailed:
        return <PriorityHigh />;
      case OrderStatus.Failed:
        return <ErrorOutline />;
      default:
        return <PhoneIphone />;
    }
  }

  return (
    <Chip
      variant="filled"
      label={props.credentialStatus.replace("_", " ").toLowerCase()}
      color={getColor()}
      icon={getIcon()}
      sx={{
        color: "white",
        textTransform: "capitalize",
        ...props.sx,
      }}
    />
  );
};
