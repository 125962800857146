import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, Dialog, FormControlLabel, Grid } from "@mui/material";
import { DialogTitle } from "../../../ui/dialog-title";
import { translations } from "../../../../generated/translationHelper";
import { AuthWrapper, AwsConfiguration, OrderApiClient, Organization } from "@sade/data-access";
import MuiDialogActions from "@mui/material/DialogActions";
import Loader from "../../../ui/loader";
import { useNotification } from "../../../ui/notification";
import MuiDialogContent from "@mui/material/DialogContent";
import { SearchField } from "../../../ui/search-field";

interface Props {
  organization: Organization;
  availableFormats: Set<string>;
  selectedFormats: Set<string>;
  isLoadingFormats: boolean;
  assignFormats: (newFormats: Set<string>) => void;
  close: () => void;
}

export const FormatAssignment: React.FC<Props> = (props) => {
  const awsConfig = useRef(AwsConfiguration.getConfiguration());
  const apiClient = useRef(new OrderApiClient(awsConfig.current.ApiGateway.RootUrlOrders, AuthWrapper.getAccessToken));

  const { organization, isLoadingFormats } = props;

  const displayNotification = useNotification();

  const [isAssigningFormats, setIsAssigningFormats] = useState(false);
  const [availableFormats, setAvailableFormats] = useState<Set<string>>(new Set<string>());
  const [selectedFormats, setSelectedFormats] = useState<Set<string>>(new Set<string>());
  const [filteredFormats, setFilteredFormats] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  useEffect(() => {
    setSelectedFormats(props.selectedFormats);
  }, [props.selectedFormats]);
  useEffect(() => {
    setAvailableFormats(props.availableFormats);
  }, [props.availableFormats]);

  useEffect(() => {
    if (searchTerm !== undefined) {
      const regExPattern = new RegExp(searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "i"); // escape regex special characters
      const filtered = Array.from(availableFormats).filter((formatName) => regExPattern.test(formatName));
      setFilteredFormats(filtered);
    } else {
      setFilteredFormats(Array.from(availableFormats));
    }
  }, [availableFormats, searchTerm]);

  async function assignSelectedFormats(): Promise<void> {
    try {
      setIsAssigningFormats(true);

      await apiClient.current.assignFormats(selectedFormats, organization.getId());
      props.assignFormats(selectedFormats);

      props.close();
    } catch (e) {
      const message = e instanceof Error ? e.message : translations.common.texts.unknownError();
      displayNotification({
        title: translations.admin.texts.assigningFormatsFailed(),
        message: message,
        variant: "error",
      });
    } finally {
      setIsAssigningFormats(false);
    }
  }

  function handleSelectedFormatsChanged(format: string, isSelected: boolean): void {
    if (isSelected) {
      setSelectedFormats((previous) => {
        const newSelectedFormats = new Set(previous);
        return newSelectedFormats.add(format);
      });
    } else {
      setSelectedFormats((previous) => {
        const newSelectedFormats = new Set(previous);
        newSelectedFormats.delete(format);
        return new Set(newSelectedFormats);
      });
    }
  }

  function renderFormatsList(): React.JSX.Element[] {
    return filteredFormats.map((f) => {
      return (
        <Grid item key={f}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedFormats.has(f)}
                onChange={(e, v): void => handleSelectedFormatsChanged(f, v)}
                color="primary"
                size="small"
                disabled={isAssigningFormats}
                sx={{ marginTop: -0.5 }}
              />
            }
            label={f}
            sx={{
              display: "block",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              borderBottom: 1,
              borderColor: "rgb(0, 0, 0, 0.12)",
              paddingBottom: 1,
            }}
          />
        </Grid>
      );
    });
  }

  return (
    <Dialog open={true} onClose={props.close} maxWidth="md">
      <DialogTitle onClose={props.close}>
        {translations.admin.texts.assignFormatsTo({ organizationName: organization.getName() })}
      </DialogTitle>
      <MuiDialogContent sx={{ height: "50vh" }}>
        <Grid container direction="column" rowGap={1}>
          <SearchField
            filterBySearchTerm={async (term: string | undefined): Promise<void> => setSearchTerm(term)}
            minimumSearchTermLength={0}
            debounceSeconds={0.1}
          />
          {isLoadingFormats ? <Loader /> : renderFormatsList()}
        </Grid>
      </MuiDialogContent>
      <MuiDialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={assignSelectedFormats}
          disabled={isLoadingFormats || isAssigningFormats}
        >
          <Loader
            size={1}
            topBottomPadding={"0"}
            leftRightPadding={"0"}
            hidden={!isAssigningFormats}
            styles={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
          />
          <span style={{ visibility: isAssigningFormats ? "hidden" : "visible" }}>
            {translations.admin.buttons.assign()}
          </span>
        </Button>
        <Button variant="outlined" color="primary" onClick={props.close}>
          {translations.common.buttons.cancel()}
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
};
