import React, { useCallback, useEffect, useRef, useState } from "react";
import { AuthWrapper, AwsConfiguration, BackendFactory, Format, OrderApiClient, Organization } from "@sade/data-access";
import { Button, Chip, Grid, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { translations } from "../../../../generated/translationHelper";
import { useNotification } from "../../../ui/notification";
import Loader from "../../../ui/loader";
import { FormatAssignment } from "./format-assignment";

export interface Props {
  organization: Organization;
}

export const Formats: React.FC<Props> = (props) => {
  const organizationBackend = useRef(BackendFactory.getOrganizationBackend());
  const awsConfig = useRef(AwsConfiguration.getConfiguration());
  const apiClient = useRef(new OrderApiClient(awsConfig.current.ApiGateway.RootUrlOrders, AuthWrapper.getAccessToken));
  const displayNotification = useNotification();

  const [formatsOfHomeOrganization, setFormatsOfHomeOrganization] = useState<Format[]>([]);
  const [formatOfSelectedOrganization, setFormatOfSelectedOrganization] = useState<Format[]>([]);
  const [isFormatAssignmentDialogOpen, setIsFormatAssignmentDialogOpen] = useState(false);
  const [isHomeOrganization, setIsHomeOrganization] = useState(true);
  const [currentSlowOperationsCount, setCurrentSlowOperationsCount] = useState(0);
  const [isRevoking, setIsRevoking] = useState(false);

  const organizationId = props.organization.getId();

  const incrementSlowOperationsCountBy = useCallback((count: number): void => {
    setCurrentSlowOperationsCount((previousValue) => {
      if (previousValue < 0) {
        console.debug("Incrementing order list slow operation count from below zero, that should never happen");
      }
      return previousValue + count;
    });
  }, []);
  const decrementSlowOperationsCountBy = useCallback((value: number): void => {
    setCurrentSlowOperationsCount((previousValue) => {
      if (previousValue <= 0) {
        console.debug("Decrementing order list slow operation count to below zero, that should never happen");
      }
      return previousValue - value;
    });
  }, []);

  useEffect(() => {
    organizationBackend.current
      .getCurrentHomeOrganization()
      .then((home) => apiClient.current.getFormats(home.getId()).then(setFormatsOfHomeOrganization))
      .catch((e) => {
        const message = e instanceof Error ? e.message : translations.common.texts.unknownError();
        displayNotification({
          title: translations.admin.texts.loadingFormatsFailed(),
          message: message,
          variant: "error",
        });
      });
  }, [displayNotification]);

  useEffect(() => {
    incrementSlowOperationsCountBy(2);

    console.debug("CLEARING IT NOW");
    setFormatOfSelectedOrganization([]);
    setIsHomeOrganization(true);
    // Disable some functionality by assuming newly selected org is the home until we find out from backend is it the home.
    // This is better than assuming the other way around and enabling functionality that possibly shouldn't be enabled

    organizationBackend.current
      .getCurrentHomeOrganization()
      .then((home) => setIsHomeOrganization(home.getId() === organizationId))
      .catch((e) => {
        const message = e instanceof Error ? e.message : translations.common.texts.unknownError();
        displayNotification({
          title: translations.admin.texts.initializingFormatAdministrationFailed(),
          message: message,
          variant: "error",
        });
      })
      .finally(() => decrementSlowOperationsCountBy(1));

    apiClient.current
      .getFormats(organizationId)
      .then(setFormatOfSelectedOrganization)
      .catch((e) => {
        const message = e instanceof Error ? e.message : translations.common.texts.unknownError();
        displayNotification({
          title: translations.admin.texts.loadingFormatsFailed(),
          message: message,
          variant: "error",
        });
      })
      .finally(() => decrementSlowOperationsCountBy(1));
  }, [organizationId, displayNotification, incrementSlowOperationsCountBy, decrementSlowOperationsCountBy]);

  function assignFormats(newFormatNames: Set<string>): void {
    setFormatOfSelectedOrganization(formatsOfHomeOrganization.filter((f) => newFormatNames.has(f.name)));
  }

  async function revoke(formatName: string): Promise<void> {
    try {
      setIsRevoking(true);

      const newFormats = formatOfSelectedOrganization.filter((f) => f.name !== formatName);
      await apiClient.current.assignFormats(new Set(newFormats.map((f) => f.name)), organizationId);
      setFormatOfSelectedOrganization((previous) => previous.filter((f) => f.name !== formatName));
    } catch (e) {
      const message = e instanceof Error ? e.message : translations.common.texts.unknownError();
      displayNotification({
        title: translations.admin.texts.revokingFormatFailed({ formatName: formatName }),
        message: message,
        variant: "error",
      });
    } finally {
      setIsRevoking(false);
    }
  }

  function renderFormatsList(): React.JSX.Element[] {
    return formatOfSelectedOrganization.map((f) => {
      return (
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ paddingBottom: 1, borderBottom: 1, borderColor: "rgb(0, 0, 0, 0.12)" }}
          key={f.name}
        >
          <Grid item>{f.name}</Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={isRevoking || isHomeOrganization}
              onClick={async (): Promise<void> => await revoke(f.name)}
              sx={{ visibility: isHomeOrganization ? "hidden" : "visible" }}
            >
              {translations.admin.buttons.revokeAccess()}
            </Button>
          </Grid>
        </Grid>
      );
    });
  }

  function renderFormatAssignmentDialog(): React.JSX.Element | undefined {
    if (isFormatAssignmentDialogOpen) {
      return (
        <FormatAssignment
          organization={props.organization}
          availableFormats={new Set(formatsOfHomeOrganization.map((f) => f.name))}
          selectedFormats={new Set(formatOfSelectedOrganization.map((f) => f.name))}
          isLoadingFormats={currentSlowOperationsCount > 0}
          assignFormats={assignFormats}
          close={(): void => setIsFormatAssignmentDialogOpen(false)}
        />
      );
    }
  }

  return (
    <>
      <Grid container direction="column" rowGap={3} sx={{ padding: "0 24px" }}>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginTop: "24px" }}
        >
          <Grid item>
            <Typography variant="h6">
              {translations.admin.texts.assignedFormats()}{" "}
              <Chip
                color="default"
                label={currentSlowOperationsCount === 0 ? formatOfSelectedOrganization.length.toString() : "???"}
              />
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              disabled={isRevoking || isHomeOrganization}
              onClick={(): void => setIsFormatAssignmentDialogOpen(true)}
              sx={{ visibility: isHomeOrganization ? "hidden" : "visible" }}
            >
              <Add sx={{ marginRight: 1 }} />
              {translations.admin.buttons.assignFormats()}
            </Button>
          </Grid>
        </Grid>
        <Grid item container direction="column" rowGap={2}>
          {currentSlowOperationsCount > 0 ? <Loader /> : renderFormatsList()}
        </Grid>
      </Grid>
      {renderFormatAssignmentDialog()}
    </>
  );
};
