import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { AuthWrapper } from "@sade/data-access";
import ViewAccessMethods from "../../ViewAccessMethods";
import accessControlled from "../access-control/access-controlled";
import HEADER_LOGO from "../../assets/CloudCONEKT-temporaryLogo.svg";
import Paths from "../Paths";
import { translations } from "../../generated/translationHelper";
import { ProfileAvatar } from "../profile-view/components/profile-avatar";
import { IconButton, Tab, Tabs } from "@mui/material";
import { Close, Menu } from "@mui/icons-material";

const AdminTab = accessControlled(Tab, ViewAccessMethods.hasAdminAccess);
const AdminFragment = accessControlled(React.Fragment, ViewAccessMethods.hasAdminAccess);

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [firstName, setFirstname] = useState<string | undefined>();
  const [lastName, setLastname] = useState<string | undefined>();
  const [selectedTab, setSelectedTab] = useState<Paths | false>(false);
  const [adminAccessResolved, setAdminAccessResolved] = useState<boolean>(false);
  const [nextUrl, setNextUrl] = useState<string | undefined>();
  const hiddenNavLink = useRef<HTMLAnchorElement>(null);
  const location = useLocation();

  useEffect(() => {
    async function initializeName(): Promise<void> {
      const [firstName, lastName] = await Promise.all([AuthWrapper.getGivenName(), AuthWrapper.getFamilyName()]);
      setFirstname(firstName);
      setLastname(lastName);
    }

    initializeName().catch((error) => console.error("getName error: " + error));
  }, []);

  function toggleMenu(): void {
    setIsMenuOpen((isMenuOpen) => !isMenuOpen);
  }

  function menuItemsAsTabs(): JSX.Element {
    return (
      <Tabs
        value={selectedTab}
        onChange={(event: React.SyntheticEvent, value: Paths): void => {
          setSelectedTab(value);
          setNextUrl(value);
        }}
        sx={{ "&:last-child": { position: "absolute", right: 0, top: 0, transform: "translate(0, -50%)" } }}
      >
        <Tab label={translations.common.texts.orders()} value={Paths.ORDERS} />
        <Tab label={translations.common.texts.configurations()} value={Paths.CONFIGURATIONS} />
        <AdminTab
          onAccessGranted={(): void => {
            setAdminAccessResolved(true);
          }}
          accessDeniedProps={{ style: { display: "none" } }}
          showLoader={false}
          showAccessError={false}
          label={translations.common.texts.admin()}
          value={Paths.ADMIN}
        />
        <Tab icon={<ProfileAvatar firstname={firstName} lastname={lastName} />} value={Paths.PROFILE} />
      </Tabs>
    );
  }

  function menuItemsAsList(): JSX.Element {
    return (
      <ul className="menu-list">
        <li className="menu-item">
          <NavLink
            to={Paths.ORDERS}
            onClick={toggleMenu}
            className={({ isActive }): string => `menu-item-link ${isActive ? "active" : ""}`}
            data-testid="orders-tab"
          >
            {translations.common.texts.orders()}
          </NavLink>
        </li>
        <li className="menu-item">
          <NavLink
            to={Paths.CONFIGURATIONS}
            onClick={toggleMenu}
            className={({ isActive }): string => `menu-item-link ${isActive ? "active" : ""}`}
            data-testid="configurations-tab"
          >
            {translations.common.texts.configurations()}
          </NavLink>
        </li>
        <AdminFragment showLoader={false} showAccessError={false}>
          <li className="menu-item">
            <NavLink
              to={Paths.ADMIN}
              onClick={toggleMenu}
              className={({ isActive }): string => `menu-item-link ${isActive ? "active" : ""}`}
              data-testid="admin-tab"
            >
              {translations.common.texts.admin()}
            </NavLink>
          </li>
        </AdminFragment>
        <li className="menu-item">
          <NavLink
            to={Paths.PROFILE}
            onClick={toggleMenu}
            className={({ isActive }): string => (isActive ? " active" : "")}
            data-testid="my-profile-tab"
          >
            <IconButton>
              <ProfileAvatar firstname={firstName} lastname={lastName} sx={{ fontSize: "20px" }} />
            </IconButton>
          </NavLink>
        </li>
      </ul>
    );
  }

  function MenuButton(): JSX.Element {
    if (!isMenuOpen) {
      return <Menu className="menu-button" onClick={toggleMenu} />;
    } else {
      return <Close className="menu-button" onClick={toggleMenu} />;
    }
  }

  useEffect(() => {
    if (nextUrl) {
      hiddenNavLink.current?.click();
      setNextUrl(undefined);
    }
  }, [nextUrl, selectedTab]);

  useEffect(() => {
    let stale = false;

    async function getSelectedTabFromUrl(): Promise<void> {
      const tabPaths = [Paths.ORDERS, Paths.CONFIGURATIONS, Paths.ADMIN, Paths.PROFILE];
      const tabPathStrings = tabPaths.map((path) => path.toString());
      const currentPathString = "/" + location.pathname.split("/")[1];
      const currentPathIndex = tabPathStrings.indexOf(currentPathString);
      const currentPath = currentPathIndex !== -1 ? tabPaths[currentPathIndex] : false;

      if (stale === false && (currentPath !== Paths.ADMIN || adminAccessResolved)) {
        setSelectedTab(currentPath);
      }
    }

    if (selectedTab === false) {
      getSelectedTabFromUrl().catch((error) => console.error("getSelectedTabFromUrl error: " + error));
    }

    return (): void => {
      stale = true;
    };
  }, [selectedTab, location.pathname, adminAccessResolved]);

  return (
    <>
      <NavLink hidden ref={hiddenNavLink} to={nextUrl ?? ""} />
      <header className="header-container col-sm-11">
        <div className="mobile-menu-container col-sm-1 col-xsm-2">
          <MenuButton />
          <div className={isMenuOpen ? "menu-content" : "menu-content hidden"}>{isMenuOpen && menuItemsAsList()}</div>
        </div>
        <div className="logo-container col-md-4 col-sm-5 col-xsm-10">
          <a href="/" rel="noopener noreferrer">
            <img className="header-logo" src={HEADER_LOGO} alt="header-logo" />
          </a>
          <div className="version-container">{process.env.REACT_APP_WEBUI_VERSION}</div>
        </div>
        <div className="menu-container col-md-8 col-sm-7">
          <div className="menu">{menuItemsAsTabs()}</div>
        </div>
      </header>
    </>
  );
};

export default Header;
