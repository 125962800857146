/**
 * Strips users-service entity id to a non-prefixed version.
 * TODO: update API so that prefixes are no longer leaked to the clients.
 * @param id
 */
export function prefixlessId(id) {
    var _a;
    const parts = id.split("/");
    const strippedId = (_a = parts[1]) !== null && _a !== void 0 ? _a : parts[0];
    if (!strippedId) {
        throw new Error(`Not a valid entity id: '${id}'`);
    }
    return strippedId;
}
