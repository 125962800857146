import { AwsConfig } from "@sade/data-access";

export class AwsConfigFactory {
  private static awsConfig?: AwsConfig;

  public static getConfig(): AwsConfig {
    if (!AwsConfigFactory.awsConfig) {
      AwsConfigFactory.awsConfig = AwsConfigFactory.generateConfig();
    }

    return AwsConfigFactory.awsConfig;
  }

  private static generateConfig(): AwsConfig {
    return {
      Auth: {
        identityPoolId: process.env.REACT_APP_AWS_CONFIG_AUTH_IDENTITY_POOL_ID!,
        region: process.env.REACT_APP_AWS_CONFIG_AUTH_REGION!,
        userPoolId: process.env.REACT_APP_AWS_CONFIG_AUTH_USER_POOL_ID!,
        userPoolWebClientId: process.env.REACT_APP_AWS_CONFIG_AUTH_USER_POOL_WEB_CLIENT_ID!,
      },
      Analytics: {
        disabled: true,
      },
      AppSync: {
        GqlEndpointUsers: process.env.REACT_APP_AWS_CONFIG_USERS_GRAPHQL_ENDPOINT!,
        GqlEndpointData: process.env.REACT_APP_AWS_CONFIG_DATA_GRAPHQL_ENDPOINT!,
        GqlEndpointDevice: process.env.REACT_APP_AWS_CONFIG_DEVICE_GRAPHQL_ENDPOINT!,
        GqlEndpointEvents: process.env.REACT_APP_AWS_CONFIG_EVENTS_GRAPHQL_ENDPOINT!,
        GqlEndpointStats: process.env.REACT_APP_AWS_CONFIG_STATS_GRAPHQL_ENDPOINT!,
        Region: process.env.REACT_APP_AWS_CONFIG_APPSYNC_REGION!,
        AuthenticationType: process.env.REACT_APP_AWS_CONFIG_APPSYNC_AUTH_TYPE!,
      },
      ApiGateway: {
        RootUrlOrders: process.env.REACT_APP_AWS_CONFIG_SERVICE_APIGATEWAY_ROOT!,
      },
    };
  }
}
