import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  AuthWrapper,
  AwsConfiguration,
  BackendFactory,
  ConfigurationSettings,
  OrderApiClient,
  OrganizationDto,
} from "@sade/data-access";
import { DateRange, isInRange } from "../ui/DateRange";
import { translations } from "../../generated/translationHelper";
import { useNotification } from "../ui/notification";
import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import { SearchField } from "../ui/search-field";
import { allOrganizations, OrganizationSelect } from "../ui/organization-select";
import { DateRangePicker } from "../ui/date-range-picker";
import { Add, Refresh } from "@mui/icons-material";
import { DataGrid, GridColDef, GridValueFormatterParams, GridValueGetterParams } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import Paths from "../Paths";
import { ConfigurationDetails, Mode } from "./configuration-details";
import accessControlled from "../access-control/access-controlled";
import ViewAccessMethods from "../../ViewAccessMethods";

class ResultIsStale extends Error {}

interface CreationButtonProps {
  onClick: () => void;
  hidden?: boolean;
}

const ConfigurationCreationButton: React.FC<CreationButtonProps> = (props) => {
  return (
    <Grid container direction="column" justifyContent="center" height="100%">
      <Button
        variant="contained"
        color="primary"
        size="medium"
        onClick={props.onClick}
        disabled={props.hidden}
        sx={{ visibility: props.hidden ? "hidden" : "visible" }}
      >
        <Add sx={{ marginRight: 1 }} />
        {translations.configurations.buttons.newConfiguration()}
      </Button>
    </Grid>
  );
};
const CreationButtonForAdmins = accessControlled(ConfigurationCreationButton, ViewAccessMethods.hasAdminAccess);
const ConfigurationDetailsForAdmins = accessControlled(ConfigurationDetails, ViewAccessMethods.hasAdminAccess);

export const ConfigurationsListView: React.FC = () => {
  const organizationBackend = useRef(BackendFactory.getOrganizationBackend());
  const awsConfig = useRef(AwsConfiguration.getConfiguration());
  const apiClient = useRef(new OrderApiClient(awsConfig.current.ApiGateway.RootUrlOrders, AuthWrapper.getAccessToken));
  const refreshAbortion = useRef<AbortController | undefined>(undefined);

  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [organizationId, setOrganizationId] = useState<string | undefined>(undefined);
  const [dateRange, setDateRange] = useState<DateRange>({});
  const [rows, setRows] = useState<ConfigurationSettings[] | undefined>(undefined);
  const [filteredRows, setFilteredRows] = useState<ConfigurationSettings[]>([]);
  const [currentSlowOperationsCount, setCurrentSlowOperationsCount] = useState(0);
  const [selectedConfiguration, setSelectedConfiguration] = useState<ConfigurationSettings | undefined>(undefined);

  function resolveMode(potentialValue?: string): Mode {
    const [, mode] = Object.entries(Mode).find(([_key, value]) => value === potentialValue) ?? [
      "DetailsViewClosed",
      Mode.DetailsViewClosed,
    ];
    return mode;
  }

  const displayNotification = useNotification();
  const navigate = useNavigate();
  const { configurationId } = useParams<{ configurationId?: string }>();
  const mode = resolveMode(useParams<{ mode?: string }>().mode) ?? Mode.DetailsViewClosed;

  function incrementSlowOperationsCount(): void {
    setCurrentSlowOperationsCount((previousValue) => {
      if (previousValue < 0) {
        console.debug("Incrementing config list slow operation count from below zero, that should never happen");
      }
      return previousValue + 1;
    });
  }
  function decrementSlowOperationsCount(): void {
    setCurrentSlowOperationsCount((previousValue) => {
      if (previousValue <= 0) {
        console.debug("Decrementing config list slow operation count to below zero, that should never happen");
      }
      return previousValue - 1;
    });
  }

  function resultsAreStillRelevant(abortion: AbortController): boolean {
    return !abortion.signal.aborted || !(abortion.signal.reason instanceof ResultIsStale);
  }

  const refreshConfigList = useCallback(async (): Promise<void> => {
    incrementSlowOperationsCount();
    const abortion = new AbortController();
    try {
      refreshAbortion.current?.abort(new ResultIsStale());
      refreshAbortion.current = abortion;

      const user = await organizationBackend.current.getCurrentUser();
      if (user !== undefined) {
        const configurationSettings = await apiClient.current.getConfigurationSettingsForOrganization(
          user.getHomeOrganizationId(),
          refreshAbortion.current
        );
        const newRows: ConfigurationSettings[] = configurationSettings.sort((a, b) => b.name.localeCompare(a.name));

        if (resultsAreStillRelevant(abortion)) {
          setRows(([] as ConfigurationSettings[]).concat(...newRows));
        }
      }
    } catch (e) {
      if (resultsAreStillRelevant(abortion)) {
        setRows(undefined);
        const message = e instanceof Error ? e.message : translations.common.texts.unknownError();
        displayNotification({
          title: translations.configurations.texts.listingConfigurationsFailed(),
          message: message,
          variant: "error",
        });
      }
    } finally {
      if (!resultsAreStillRelevant(abortion)) {
        console.debug("Stale configuration list refresh result discarded");
      }

      decrementSlowOperationsCount();
    }
  }, [displayNotification]);

  const selectOrganization = useCallback(
    async (organization: OrganizationDto | undefined): Promise<void> => {
      setOrganizationId(organization?.id);
    },
    [setOrganizationId]
  );

  const filterBySearchTerm = useCallback(
    async (term: string | undefined): Promise<void> => {
      setSearchTerm(term);
    },
    [setSearchTerm]
  );

  const filterByDateRange = useCallback(
    async (range: DateRange): Promise<void> => {
      setDateRange(range);
    },
    [setDateRange]
  );

  const applyFilterOrganizationId = useCallback(
    (rows: ConfigurationSettings[], organizationId?: string): ConfigurationSettings[] =>
      organizationId !== undefined ? rows.filter((r) => r.organization.id === organizationId) : rows,
    []
  );
  const applyFilterDateRange = useCallback(
    (rows: ConfigurationSettings[], range: DateRange): ConfigurationSettings[] =>
      rows.filter((r) => isInRange(range, r.createdAt)),
    []
  );
  const applyFilterSearchTerm = useCallback(
    (rows: ConfigurationSettings[], searchTerm?: string): ConfigurationSettings[] => {
      // for case insensitivity we avoid string.includes and use regex instead
      function match(value: string, substring: string): boolean {
        // first escape special regex characters in the substring we will use as a regex pattern
        const regExPattern = substring.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        const matches = value.match(new RegExp(regExPattern, "i"));
        return matches !== null && matches.length > 0;
      }
      return searchTerm !== undefined
        ? rows.filter(
            (r) =>
              match(r.name, searchTerm) ||
              match(r.organization.name, searchTerm) ||
              match(r.orderTemplate.name, searchTerm)
          )
        : rows;
    },
    []
  );
  useEffect((): void => {
    let newRows = rows !== undefined ? applyFilterOrganizationId(rows, organizationId) : [];
    newRows = applyFilterDateRange(newRows, dateRange);
    newRows = applyFilterSearchTerm(newRows, searchTerm);
    setFilteredRows(newRows);
  }, [
    rows,
    organizationId,
    dateRange,
    searchTerm,
    applyFilterOrganizationId,
    applyFilterDateRange,
    applyFilterSearchTerm,
  ]);

  const closeDetails = useCallback(() => {
    setSelectedConfiguration(undefined);
    navigate(Paths.CONFIGURATIONS);
  }, [setSelectedConfiguration, navigate]);
  const openDetails = useCallback(
    async (configuration: ConfigurationSettings): Promise<void> => {
      const mode = (await ViewAccessMethods.hasAdminAccess()) ? Mode.EditExisting : Mode.ViewExisting;
      setSelectedConfiguration(configuration);
      navigate(`${Paths.CONFIGURATIONS}/${mode}/${configuration.id}`);
    },
    [setSelectedConfiguration, navigate]
  );
  const closeDetailsAndRefresh = useCallback(async (): Promise<void> => {
    closeDetails();
    await refreshConfigList();
  }, [closeDetails, refreshConfigList]);

  useEffect(() => {
    if (configurationId !== undefined && rows !== undefined) {
      const selectedConfig = rows.find((c) => c.id === configurationId);
      setSelectedConfiguration(selectedConfig);
    } else {
      setSelectedConfiguration(undefined);
    }
  }, [configurationId, rows]);

  useEffect(() => {
    refreshConfigList().catch(console.error);
  }, [refreshConfigList]);

  const columns = useRef<GridColDef[]>([
    {
      field: "createdAt",
      headerName: translations.configurations.texts.created(),
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams<Date>): string => {
        return `${params.value.toLocaleString()}`;
      },
    },
    {
      field: "name",
      headerName: translations.configurations.texts.configurationName(),
      flex: 1,
    },
    {
      field: "organizationName",
      headerName: translations.configurations.texts.customer(),
      flex: 1,
      valueGetter: (params: GridValueGetterParams<ConfigurationSettings>): string => params.row.organization.name,
    },
    {
      field: "orderTemplateName",
      headerName: translations.configurations.texts.orderTemplate(),
      flex: 1,
      valueGetter: (params: GridValueGetterParams<ConfigurationSettings>): string => params.row.orderTemplate.name,
    },
  ]);

  return mode === Mode.DetailsViewClosed || (mode !== Mode.CreateNew && selectedConfiguration === undefined) ? (
    <>
      <Grid container direction="row" columnSpacing={1} sx={{ paddingX: 2 }}>
        <Grid item xs={12} sm={6} lg={2}>
          <SearchField
            searchTerm={searchTerm}
            filterBySearchTerm={filterBySearchTerm}
            minimumSearchTermLength={3}
            label={translations.common.inputs.search()}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={2}>
          <OrganizationSelect
            selectedOrganizationId={organizationId}
            enableSelectingAll={true}
            selectOrganization={async (selected): Promise<void> => {
              if (selected !== allOrganizations) {
                await selectOrganization(selected);
              } else {
                await selectOrganization(undefined);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <DateRangePicker dateRange={dateRange} filterByDateRange={filterByDateRange} />
        </Grid>
        <Grid item xs={0} lg={true} sx={{ margin: 0 }} />
        <Grid item xs={6} lg={1} container direction="row" justifyContent="flex-end">
          <Tooltip placement="left" title={translations.configurations.buttons.refresh()}>
            <IconButton style={{ cursor: "pointer" }} onClick={refreshConfigList}>
              <Refresh />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <CreationButtonForAdmins
            accessDeniedProps={{ hidden: true }}
            onClick={(): void => {
              navigate(`${Paths.CONFIGURATIONS}/${Mode.CreateNew}`);
            }}
          />
        </Grid>
      </Grid>
      <DataGrid
        columns={columns.current}
        rows={filteredRows}
        loading={currentSlowOperationsCount > 0}
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        onRowClick={async (params): Promise<void> => await openDetails(params.row)}
      />
    </>
  ) : mode === Mode.CreateNew || selectedConfiguration === undefined ? (
    <ConfigurationDetailsForAdmins
      mode={Mode.CreateNew}
      key={Mode.CreateNew}
      close={closeDetails}
      closeAndRefresh={closeDetailsAndRefresh}
    />
  ) : mode === Mode.EditExisting ? (
    <ConfigurationDetailsForAdmins
      mode={Mode.EditExisting}
      key={`${Mode.EditExisting}_${selectedConfiguration.id}`}
      configurationSettings={selectedConfiguration}
      close={closeDetails}
      closeAndRefresh={closeDetailsAndRefresh}
    />
  ) : (
    <ConfigurationDetails
      mode={mode}
      key={`${Mode.ViewExisting}_${selectedConfiguration.id}`}
      configurationSettings={selectedConfiguration}
      close={closeDetails}
      closeAndRefresh={closeDetailsAndRefresh}
    />
  );
};
