import React, { useCallback, useState } from "react";
import { Organization } from "@sade/data-access";
import { Button, Dialog, Grid, InputLabel, TextField } from "@mui/material";
import { DialogTitle } from "../../../ui/dialog-title";
import { translations } from "../../../../generated/translationHelper";
import MuiDialogActions from "@mui/material/DialogActions";
import Loader from "../../../ui/loader";
import { isInteger } from "../../../../utils/StringUtils";
import MuiDialogContent from "@mui/material/DialogContent";
import { ValidationError } from "../../../ui/validation-error";
import accessControlled from "../../../access-control/access-controlled";
import { useNotification } from "../../../ui/notification";

export interface Props {
  parentOrganization: Organization;
  close: () => void;
}

const ACTextField = accessControlled(TextField, ["organizationsUpdate"]);

export const OrganizationCreationDialog: React.FC<Props> = (props: Props) => {
  const { parentOrganization, close } = props;

  const [isCreatingNewOrganization, setIsCreatingNewOrganization] = useState(false);
  const [name, setName] = useState<string>("");
  const [maxSecureCode, setMaxSecureCode] = useState<string>("0");
  const [nameValidationError, setNameValidationError] = useState<string | undefined>(undefined);
  const [maxSecureCodeValidationError, setMaxSecureCodeValidationError] = useState<string | undefined>(undefined);

  const displayNotification = useNotification();

  const submit = useCallback(async (): Promise<void> => {
    const newOrganizationName = name.trim();
    const newOrganizationMaxSecureCode = parseInt(maxSecureCode);

    await parentOrganization.createOrganization({
      name: newOrganizationName,
      maxSecureCode: newOrganizationMaxSecureCode,
    });
  }, [maxSecureCode, name, parentOrganization]);

  const validateAndSubmit = useCallback(async (): Promise<void> => {
    try {
      setIsCreatingNewOrganization(true);

      let isValid = true;
      const minNameLength = 4;
      if (name.trim().length === 0) {
        isValid = false;
        setNameValidationError(translations.admin.texts.valueMustBeProvided());
      }
      if (name.trim().length < minNameLength) {
        isValid = false;
        setNameValidationError(
          translations.admin.texts.organizationNameMustBeAtLeastCharactersLong({ minLength: minNameLength })
        );
      }

      if (maxSecureCode.length === 0) {
        isValid = false;
        setMaxSecureCodeValidationError(translations.admin.texts.valueMustBeProvided());
      } else if (!isInteger(maxSecureCode)) {
        isValid = false;
        setMaxSecureCodeValidationError(translations.admin.texts.maxSecureCodeMustBeNumber());
      } else {
        const maxSecureCodeNumber = parseInt(maxSecureCode);
        const min = 0;
        const max = 65535;

        if (maxSecureCodeNumber < min || maxSecureCodeNumber > max) {
          isValid = false;
          setMaxSecureCodeValidationError(translations.admin.texts.maxSecureCodeMustBeInRange({ min, max }));
        }
      }

      if (isValid) {
        await submit();
        close();
      }
    } catch (e) {
      const errorDetails = e instanceof Error ? e.message : JSON.stringify(e);
      displayNotification({
        title: translations.admin.texts.organizationCreationFailedTitle(),
        message: translations.admin.texts.organizationCreationFailedMessage({
          organizationName: name,
          message: errorDetails,
        }),
        variant: "error",
      });
    } finally {
      setIsCreatingNewOrganization(false);
    }
  }, [name, maxSecureCode, submit, close, displayNotification]);

  return (
    <Dialog open={true} onClose={props.close} maxWidth="md">
      <DialogTitle onClose={props.close}>
        {translations.admin.texts.addNewSubOrganizationTo({ parentOrganizationName: parentOrganization.getName() })}
      </DialogTitle>
      <MuiDialogContent sx={{ height: "30vh" }}>
        <Grid item container direction="row">
          <Grid item container direction="column" rowGap={1} xs={12} sm={6}>
            <Grid item>
              <InputLabel id="label-organizationName">{translations.admin.inputs.organisationName()}</InputLabel>
              <ACTextField
                value={name}
                contextOrganization={parentOrganization}
                accessDeniedProps={{ disabled: true }}
                onChange={(event): void => {
                  setName(event.target.value ?? "");
                  setNameValidationError(undefined);
                }}
                variant="outlined"
                size="small"
                fullWidth
                id="organizationName"
              />
              <ValidationError>{nameValidationError ?? "\u00a0"}</ValidationError>
            </Grid>
            <Grid item>
              <InputLabel id="label-organizationMaxSecureCode">{translations.admin.inputs.maxSecureCode()}</InputLabel>
              <ACTextField
                value={maxSecureCode}
                contextOrganization={parentOrganization}
                accessDeniedProps={{ disabled: true }}
                onChange={(event): void => {
                  setMaxSecureCode(event.target.value ?? "");
                  setMaxSecureCodeValidationError(undefined);
                }}
                variant="outlined"
                size="small"
                fullWidth
                id="organizationMaxSecureCode"
              />
              <ValidationError>{maxSecureCodeValidationError ?? "\u00a0"}</ValidationError>
            </Grid>
          </Grid>
        </Grid>
      </MuiDialogContent>
      <MuiDialogActions>
        <Button variant="contained" color="primary" onClick={validateAndSubmit} disabled={isCreatingNewOrganization}>
          <Loader
            size={1}
            topBottomPadding={"0"}
            leftRightPadding={"0"}
            hidden={!isCreatingNewOrganization}
            styles={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
          />
          <span style={{ visibility: isCreatingNewOrganization ? "hidden" : "visible" }}>
            {translations.admin.buttons.save()}
          </span>
        </Button>
        <Button variant="outlined" color="primary" onClick={props.close}>
          {translations.common.buttons.cancel()}
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
};
