import { Link, MenuItem, Menu, IconButton } from "@mui/material";
import { Organization } from "@sade/data-access";
import React, { Component, Fragment, ReactNode } from "react";
import ArrowDropDownIcon from "../../../assets/arrow_drop_down_24px.svg";

interface Props {
  currentOrganization: Organization;
  onOrganizationSelected: (organization: Organization) => void;
  data_test_id: string;
}
interface State {
  siblingOrganizations: Organization[];
  menuOpen: boolean;
  menuAnchor?: Element;
}

export class ParentLinkRenderer extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = { siblingOrganizations: [], menuOpen: false };
  }
  public componentDidMount(): void {
    this.props.currentOrganization
      .getParentOrganization()
      .then((parentOrganization) => {
        return parentOrganization?.getDirectChildOrganizations();
      })
      .then((childOrganizations) => {
        if (childOrganizations) this.setState({ siblingOrganizations: childOrganizations });
      });
  }

  public render(): ReactNode {
    const listOrganizations = this.state.siblingOrganizations.filter(
      (organization) => organization !== this.props.currentOrganization
    );
    return (
      <Fragment>
        <Link
          key={this.props.currentOrganization.getId()}
          color="inherit"
          href="#"
          onClick={(): void => void this.props.onOrganizationSelected(this.props.currentOrganization)}
          data-testid={`organization-link-${this.props.data_test_id}`}
        >
          {this.props.currentOrganization.getName()}
        </Link>
        {listOrganizations.length > 0 && (
          <Fragment>
            <IconButton
              style={{ padding: "0.1em" }}
              id="menuButton"
              onClick={(event): void => {
                this.setState({ menuOpen: true, menuAnchor: event.currentTarget });
              }}
            >
              <img src={ArrowDropDownIcon} />
            </IconButton>
            <Menu
              open={this.state.menuOpen}
              onClose={(): void => {
                this.setState({ menuOpen: false });
              }}
              anchorEl={this.state.menuAnchor}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {listOrganizations.map((organization) => {
                return (
                  <MenuItem
                    key={organization.getId()}
                    onClick={(): void => {
                      this.setState({ menuOpen: false });
                      this.props.onOrganizationSelected(organization);
                    }}
                  >
                    {organization.getName()}
                  </MenuItem>
                );
              })}
            </Menu>
          </Fragment>
        )}
      </Fragment>
    );
  }
}
