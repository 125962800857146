import React, { useState } from "react";
import { Checkbox, FormControlLabel, Grid, InputAdornment, TextField } from "@mui/material";
import { isInteger } from "../../../utils/StringUtils";
import { ValidationError } from "../../ui/validation-error";

interface Props {
  label: string;
  x?: number;
  y?: number;
  setX: (x: number | undefined) => void;
  setY: (y: number | undefined) => void;
  enabled: boolean;
  onEnabledChange: (newEnabledValue: boolean) => void;
  validationErrorMessage?: string;
}

export const CoordinatesInput: React.FC<Props> = (props) => {
  const [enabled, setEnabled] = useState(props.enabled);

  function handleEnabledChanged(newValue: boolean): void {
    setEnabled(newValue);
    props.onEnabledChange(newValue);
  }

  return (
    <Grid item>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item md={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={enabled}
                onChange={(e, v): void => handleEnabledChanged(v)}
                color="primary"
                size="small"
                sx={{ marginTop: -0.5 }}
              />
            }
            label={props.label}
            sx={{ display: "block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
          />
        </Grid>
        <Grid item container direction="row" justifyContent="space-between" md={6} paddingLeft={1}>
          <Grid item md={5.5}>
            <TextField
              type="number"
              value={props.x ?? ""}
              onChange={(event): void => {
                const newX = isInteger(event.target.value) ? parseInt(event.target.value) : undefined;
                props.setX(newX);
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">X</InputAdornment>,
              }}
              disabled={!enabled}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item md={5.5}>
            <TextField
              type="number"
              value={props.y ?? ""}
              onChange={(event): void => {
                const newY = isInteger(event.target.value) ? parseInt(event.target.value) : undefined;
                props.setY(newY);
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">Y</InputAdornment>,
              }}
              disabled={!enabled}
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <ValidationError>{props.validationErrorMessage ?? " "}</ValidationError>
    </Grid>
  );
};
