import React from "react";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { translations } from "../../../../../generated/translationHelper";
import { GridMoreVertIcon } from "@mui/x-data-grid";
import { Credential } from "@sade/data-access";
import { CredentialStatus } from "./CredentialStatus";

interface Props {
  credential: Credential;
  openCredentialAssignmentDialog: (credential: Credential) => void;
}

export const CredentialActionsMenu: React.FC<Props> = (props: Props) => {
  const { credential } = props;
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchor);

  function openMenu(event: React.MouseEvent<HTMLButtonElement>): void {
    setMenuAnchor(event.currentTarget);
  }
  function closeMenu(): void {
    setMenuAnchor(null);
  }

  return credential.status !== CredentialStatus.Deployed ? (
    <>
      <Tooltip placement="top" title={translations.orders.buttons.actions()}>
        <IconButton onClick={openMenu} style={{ cursor: "pointer", margin: "0 16px" }}>
          <GridMoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id={`credential-actions-menu-${props.credential.credentialId}`}
        anchorEl={menuAnchor}
        open={isMenuOpen}
        onClose={closeMenu}
      >
        <MenuItem
          onClick={(): void => {
            closeMenu();
            props.openCredentialAssignmentDialog(credential);
          }}
        >
          {credential.assignedTo === undefined
            ? translations.orders.buttons.assignToEndUser()
            : translations.orders.buttons.reassign()}
        </MenuItem>
      </Menu>
    </>
  ) : (
    <></>
  );
};
