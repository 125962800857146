import { BaseObservable } from "../observer/BaseObservable";
import { AuthWrapper } from "../auth/AuthWrapper";
export class User extends BaseObservable {
    constructor(parameters) {
        super();
        this.id = parameters.id;
        this.email = parameters.email;
        if (typeof parameters.homeOrganization === "string") {
            this.homeOrganizationId = parameters.homeOrganization;
        }
        else {
            this.homeOrganizationId = parameters.homeOrganization.getId();
            this.homeOrganization = parameters.homeOrganization;
        }
    }
    /**
     * User identifier used by the backend service
     */
    getId() {
        return this.id;
    }
    /**
     * Email address of the user
     */
    getEmail() {
        return this.email;
    }
    /**
     * User identifier used by user registry that stores user attributes
     */
    getUsername() {
        return this.getId().split("/")[1];
    }
    /**
     * Identifier of the user's home (primary) organization.
     */
    getHomeOrganizationId() {
        return this.homeOrganizationId;
    }
    /**
     * Checks whether this user represents, in fact, the logged-in user.
     */
    async isCurrentUser() {
        const claims = await AuthWrapper.getCurrentAuthenticatedUserClaims();
        return this.id === (claims === null || claims === void 0 ? void 0 : claims.userId);
    }
    static alphabeticUserOrdering(a, b) {
        return a.getEmail().localeCompare(b.getEmail());
    }
}
