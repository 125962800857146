export function throwGQLError(response, fallback = "Unexpected error") {
    if (!response.errors || response.errors.length === 0) {
        throw new Error(fallback);
    }
    else {
        const error = response.errors[0]; // for now, ignore all the other errors
        const msg = `${error.name}: ${error.message}${error.originalError ? "( " + error.originalError.message + " )" : ""}`;
        throw new Error(msg);
    }
}
