import React, { useCallback, useRef } from "react";
import { BackendFactory, OrganizationDto } from "@sade/data-access";
import { translations } from "../../generated/translationHelper";
import { allItems, SelectItem, SelectWithLoading } from "./select-with-loading";

export const allOrganizations: unique symbol = Symbol();
interface Props {
  validationErrorMessage?: string;
  selectedOrganizationId: string | undefined;
}
interface PropsEnableAll extends Props {
  enableSelectingAll: true;
  selectOrganization: (organization: OrganizationDto | typeof allOrganizations) => void;
}
interface PropsDisableAll extends Props {
  enableSelectingAll: false;
  selectOrganization: (organization: OrganizationDto) => Promise<void>;
}

export const OrganizationSelect: React.FC<PropsEnableAll | PropsDisableAll> = (props) => {
  const organizationBackend = useRef(BackendFactory.getOrganizationBackend());

  const { enableSelectingAll, selectOrganization } = props;

  const getSelectableOrganizations = useCallback(async (): Promise<SelectItem<OrganizationDto>[]> => {
    const home = await organizationBackend.current.getCurrentHomeOrganization();
    const children = await home.getAllChildOrganizationsRecursively();
    return [home, ...children]
      .sort((a, b) => {
        const aParentId = a.getParentOrganizationId();
        const bParentId = b.getParentOrganizationId();
        if (aParentId === bParentId) {
          return a.getName().localeCompare(b.getName());
        } else {
          return a.getId().localeCompare(b.getId());
        }
      })
      .map((o) => {
        const indentLevel = o.getId().replace(home.getId(), "").split(":").length - 1;
        return {
          id: o.getId(),
          name: "    ".repeat(indentLevel) + o.getName(),
          item: { id: o.getId(), name: o.getName() },
        };
      });
  }, []);

  const selectOrganizationOrAll = useCallback(
    async (organization: OrganizationDto | typeof allItems): Promise<void> => {
      if (!enableSelectingAll && organization !== allItems) {
        await selectOrganization(organization);
      } else if (enableSelectingAll && organization !== allItems) {
        await selectOrganization(organization);
      } else if (enableSelectingAll && organization === allItems) {
        await selectOrganization(allOrganizations);
      }
    },
    [enableSelectingAll, selectOrganization]
  );

  return (
    <SelectWithLoading<OrganizationDto>
      label={translations.orders.inputs.customer()}
      selectedId={props.selectedOrganizationId}
      enableSelectingAll={props.enableSelectingAll}
      select={selectOrganizationOrAll}
      loadItems={getSelectableOrganizations}
      validationErrorMessage={props.validationErrorMessage}
    />
  );
};
