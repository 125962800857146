import React, { Component, Fragment } from "react";
import { translations } from "../../../generated/translationHelper";

export default class PasswordRequirements extends Component {
  public render(): JSX.Element {
    return (
      <Fragment>
        <p className="login-password-requirements-header">{translations.logIn.texts.passwordMustContain()}</p>
        <ul className="login-password-requirements">
          <li>{translations.logIn.texts.passwordNumber({ count: 1 })}</li>
          <li>{translations.logIn.texts.passwordLength({ min: 14, max: 256 })}</li>
          <li>{translations.logIn.texts.passwordLowercase({ count: 1 })}</li>
          <li>{translations.logIn.texts.passwordUppercase({ count: 1 })}</li>
        </ul>
      </Fragment>
    );
  }
}
