import React, { Component, ReactNode } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Gatekeeper from "./components/access-control/gatekeeper";
import Content from "./components/content";
import LoginView from "./components/login-view";
import Paths from "./components/Paths";
import { SnackbarProvider } from "notistack";
import { Notification } from "./components/ui/notification";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

export default class App extends Component {
  private static renderWithGatekeeper(
    authenticatedComponent: ReactNode,
    redirectPath: Paths,
    redirectIfAuthenticated?: boolean
  ): JSX.Element {
    return (
      <Gatekeeper redirectIfAuthenticated={redirectIfAuthenticated} redirectPath={redirectPath}>
        {authenticatedComponent}
      </Gatekeeper>
    );
  }

  public render(): ReactNode {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          Components={{ default: Notification, error: Notification, success: Notification }}
          classes={{ containerRoot: "snackbar-notification" }}
        >
          <BrowserRouter>
            <Routes>
              <Route path={Paths.LOGIN} element={App.renderWithGatekeeper(<LoginView />, Paths.ROOT, true)} />
              <Route path={`${Paths.ROOT}*`} element={App.renderWithGatekeeper(<Content />, Paths.LOGIN)} />
            </Routes>
          </BrowserRouter>
        </SnackbarProvider>
      </LocalizationProvider>
    );
  }
}
