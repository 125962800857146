import { Card, CardContent, CardHeader, FormLabel, Grid } from "@mui/material";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts";
import { Order } from "@sade/data-access";
import React, { useEffect, useState } from "react";
import { deepOrange, green, purple } from "@mui/material/colors";
import { translations } from "../../../../../../../generated/translationHelper";
import { theme } from "../../../../../../../styles/globalTheme";
import Loader from "../../../../../../ui/loader";
import { CredentialStatus } from "../../CredentialStatus";

interface Props {
  order?: Order;
}

export const CredentialsCard: React.FC<Props> = (props: Props) => {
  const [pieMarginLeft, setPieMarginLeft] = useState(window.innerWidth > theme.breakpoints.values.xl ? 85 : 0);

  useEffect(() => {
    function resizeListener(): void {
      setPieMarginLeft(window.innerWidth > theme.breakpoints.values.xl ? 85 : 0);
    }

    window.addEventListener("resize", resizeListener);
  });

  return (
    <Card sx={{ Width: "100%", position: "relative" }}>
      <Loader
        size="medium"
        topBottomPadding={"0"}
        leftRightPadding={"0"}
        hidden={props.order !== undefined}
        styles={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
      />
      <Grid item visibility={props.order !== undefined ? "visible" : "hidden"}>
        <CardHeader title={translations.orders.texts.credentials()} />
        <CardContent>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item container direction="row">
              <FormLabel>{translations.orders.texts.credentialsTotal()}</FormLabel>
              <div style={{ flexGrow: 1 }} />
              {props.order?.credentialCountTotal}
            </Grid>
            <Grid item container direction="row">
              <FormLabel>{translations.orders.texts.credentialsAvailable()}</FormLabel>
              <div style={{ flexGrow: 1 }} />
              {props.order ? props.order?.credentialCountTotal - props.order?.credentialCountDeployed : 0}
            </Grid>
            <Grid item container direction="row">
              <FormLabel>{translations.orders.texts.credentialsInstalled()}</FormLabel>
              <div style={{ flexGrow: 1 }} />
              {props.order?.credentialCountDeployed}
            </Grid>
            <Grid item container direction="row">
              <Grid item flexGrow={1} />
              <Grid item>
                <PieChart
                  slotProps={{
                    legend: {
                      direction: "column",
                      position: {
                        vertical: "middle",
                        horizontal: "right",
                      },
                      padding: {
                        left: 8,
                      },
                      itemMarkWidth: 12,
                      itemMarkHeight: 12,
                      markGap: 8,
                      itemGap: 8,
                    },
                  }}
                  series={[
                    {
                      arcLabel: "value",
                      arcLabelMinAngle: 1,

                      data: [
                        {
                          id: 1,
                          value: props.order?.credentialCountDeployed ?? 0,
                          label: translations.orders.texts.installed(),
                          color: purple[400],
                        },
                        {
                          id: 0,
                          value:
                            props.order?.credentials.filter((c) => c.status === CredentialStatus.Available).length ?? 1,
                          label: translations.orders.texts.available(),
                          color: green[800],
                        },
                        {
                          id: 2,
                          value:
                            props.order?.credentials.filter((c) => c.status === CredentialStatus.Assigned).length ?? 0,
                          label: translations.orders.texts.assigned(),
                          color: deepOrange[500],
                        },
                        {
                          id: 3,
                          value:
                            props.order?.credentials.filter((c) => c.status === CredentialStatus.DeliveryFailed)
                              .length ?? 0,
                          label: translations.orders.texts.deliveryFailed(),
                          color: "#000",
                        },
                      ],
                      innerRadius: 30,
                    },
                  ]}
                  width={357 + pieMarginLeft}
                  height={160}
                  margin={{ left: pieMarginLeft, right: 85 }}
                  sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                      fill: "#ffffff",
                      fontWeight: 700,
                      fontSize: 14,
                      lineHeight: 20,
                      letterSpacing: 0.17,
                    },

                    text: {
                      fontWeight: 400,
                      fontSize: 14,
                      lineHeight: 20,
                      letterSpacing: 0.17,
                    },
                    rect: {
                      rx: "50%",
                    },
                  }}
                />
              </Grid>
              <Grid item flexGrow={1} />
            </Grid>
          </Grid>
        </CardContent>
      </Grid>
    </Card>
  );
};
