export interface ControlCardType {
  controlByteValue: number;
  name: string;
}

export const ControlCardTypes: ControlCardType[] = [
  { controlByteValue: 0x01, name: "BLE Project Configuration Card" },
  { controlByteValue: 0x02, name: "Wiegand Output Lock Mode" },
  { controlByteValue: 0x04, name: "MagStripe Output Lock Mode" },
  { controlByteValue: 0x10, name: "LED Flash on Decode Toggle" },
  { controlByteValue: 0x20, name: "Beep on Decode Toggle" },
  { controlByteValue: 0x80, name: "LED Wire Control Toggle" },
  { controlByteValue: 0x8e, name: "PIV 40 bit output" },
  { controlByteValue: 0xa2, name: "FleaPowerMode Enable/Disable" },
  { controlByteValue: 0xa4, name: "SupervisorMode Enable/Disable" },
  { controlByteValue: 0xa6, name: "MaxSecure Card " },
  { controlByteValue: 0xae, name: "Sector plus CSN" },
  { controlByteValue: 0xb2, name: "CSN only" },
  { controlByteValue: 0xb4, name: "Sector Only" },
  { controlByteValue: 0xb6, name: "Delta32Bit" },
  { controlByteValue: 0xb8, name: "Delta26Bit" },
  { controlByteValue: 0xba, name: "Delta34Bit" },
  { controlByteValue: 0xbc, name: "Delta40Bit" },
  { controlByteValue: 0xc2, name: "OC_PUR card for IDTech" },
  { controlByteValue: 0xc6, name: "PIV baud rate card" },
  { controlByteValue: 0xc8, name: "Reader Mifare Key Configuration Card" },
  { controlByteValue: 0xce, name: "FIPS Only Mode enable" },
  { controlByteValue: 0xd2, name: "Output Sector+CSN,enables the output of Sector+CSN" },
  { controlByteValue: 0xd4, name: "Reverse CSN" },
  { controlByteValue: 0xd6, name: "PIV 200 bit FASC-N output, no expiration date" },
  { controlByteValue: 0xd8, name: "PIV 200 bit FASC-N with embedded expiration date output" },
  { controlByteValue: 0xda, name: "PIV 245 bit FASC-N with appended expiration date" },
  { controlByteValue: 0xdc, name: "FIPS_TWIC toggle card" },
  { controlByteValue: 0xe0, name: "Set factory defaults" },
  { controlByteValue: 0xe2, name: "AESEnable/Disable" },
  { controlByteValue: 0xe6, name: "Ignore_MAD_CRC Enable/Disable" },
  { controlByteValue: 0xf0, name: "Output Entire CSN" },
  { controlByteValue: 0xf2, name: "PIV 64 bit output" },
  { controlByteValue: 0xf8, name: "PIV 50 bit output" },
  { controlByteValue: 0xfe, name: "BLE Reader Configuration Card" },
];
