import React, { Component } from "react";
import ContentController from "../content-controller";
import Header from "../header";

interface Props {}

export default class Content extends Component<Props> {
  public render(): JSX.Element {
    return (
      <>
        <Header />
        <ContentController />
      </>
    );
  }
}
