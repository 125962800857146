import React, { Component } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AdminView from "../../components/admin-view";
import ProfileView from "../../components/profile-view";
import Paths from "../Paths";
import { OrdersListView } from "../orders-list-view";
import { ConfigurationsListView } from "../configurations-list-view";

interface Props {}

export default class ContentController extends Component<Props> {
  public constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    return (
      <section className="body-container col-sm-12 col-xsm-12">
        <Routes>
          <Route path={Paths.ORDERS} element={<OrdersListView />} />
          <Route path={Paths.ORDERS + "/:orderId/:tab"} element={<OrdersListView />} />
          <Route path={Paths.CONFIGURATIONS + "/:mode?/:configurationId?"} element={<ConfigurationsListView />} />
          <Route path={Paths.PROFILE} element={<ProfileView />} />
          <Route path={Paths.ADMIN} element={<AdminView />} />
          <Route path={Paths.ROOT + "*"} element={<Navigate to={Paths.ORDERS} replace />} />
        </Routes>
      </section>
    );
  }
}
