import { isError, isObject } from "./types";
export class ErrorWithCode extends Error {
    constructor(code, message) {
        super(message);
        this.code = code;
    }
}
export function isErrorWithCode(error) {
    return isObject(error) && isError(error) && typeof error.code === "string";
}
