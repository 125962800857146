import { Service } from "./AppSyncClientProvider";
import { AppSyncClientFactory } from "./AppSyncClientFactory";
import { AuthWrapper } from "../auth/AuthWrapper";
import { isDefined } from "../../common/isDefined";
import { UrlsQsEmbedGenerateDocument } from "../../generated/gqlStats";
import { AuthListener } from "../auth/AuthListener";
import { EntityRelationCache } from "../private-utils/EntityRelationCache";
import { Auth } from "@aws-amplify/auth";
export class AWSBackend {
    constructor() {
        this.entityRelationCache = new EntityRelationCache();
        /////
        /// AWSBackend specific public methods
        /////
        this.authEventHandler = (event) => {
            if (event === "SignedOut") {
                this.entityRelationCache.clear();
                this.rootGroupIds = undefined;
            }
        };
        this.authListener = new AuthListener(this.authEventHandler);
    }
    static async getOrganization() {
        const claims = await AuthWrapper.getCurrentAuthenticatedUserClaims();
        const org = claims === null || claims === void 0 ? void 0 : claims.homeOrganizationId;
        if (!org) {
            throw new Error("No organization available");
        }
        return org;
    }
    async getQsEmbedUrl(openIdToken, dashboardId) {
        var _a;
        try {
            const user = await Auth.currentAuthenticatedUser();
            const variables = {
                request: {
                    dashboardId,
                    emailAddress: user.username,
                    openIdToken,
                    sessionName: user.username,
                    undoRedoDisabled: true,
                    resetDisabled: true,
                    sessionLifetimeInMinutes: 600,
                },
            };
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.STATS);
            const embedUrlResponse = await client.query(UrlsQsEmbedGenerateDocument, variables);
            return (_a = embedUrlResponse.data.urlsQsEmbedGenerate) === null || _a === void 0 ? void 0 : _a.embedUrl;
        }
        catch (error) {
            console.error("getQsEmbedUrl: ", error);
        }
    }
    /**
     * Takes a collection of fragments, which are either
     * - if id matches something in cache, replaced with the cached entity
     * - converted into the desired entity, and then cached
     *
     * @param cache
     *    an AsyncCache into which to store the converted fragments
     * @param fragments
     *    list of fragments to go through
     * @param fragmentConverter
     *    method for converting fragment into the desired entity type
     * @private
     */
    async cacheFragments(cache, fragments, fragmentConverter) {
        const results = await Promise.all(fragments.map((fragment) => cache.get(fragment.id, () => fragmentConverter(fragment))));
        return results.filter(isDefined);
    }
}
