import React, { Component } from "react";
import { Button, Dialog, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { AuthWrapper } from "@sade/data-access";
import { translations } from "../../../generated/translationHelper";

interface Props {
  language: string;
  dialogOpen: boolean;
  onDialogClose: (saveSuccessful?: boolean) => void;
}

interface State {
  selectedLanguage?: string;
}

export default class EditLanguageDialog extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public componentDidMount(): void {
    this.setState({
      selectedLanguage: this.props.language,
    });
  }

  private handleLanguageChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      selectedLanguage: (event.target as HTMLInputElement).value,
    });
  };

  private updateLanguage = async (): Promise<void> => {
    if (!this.state.selectedLanguage) return;
    try {
      await AuthWrapper.setLanguage(this.state.selectedLanguage);
      this.props.onDialogClose(true);
    } catch (error) {
      console.log(error);
      this.props.onDialogClose(false);
    }
  };

  public render(): React.ReactNode {
    return (
      <Dialog open={this.props.dialogOpen} fullWidth>
        <Grid container direction="column" className="edit-dialog-container">
          <Grid item>
            <Typography variant="h5">{translations.user.buttons.changeLanguage()}</Typography>
          </Grid>
          <Grid item container xs justifyContent="space-between">
            <Grid item>
              <RadioGroup value={this.state.selectedLanguage} onChange={this.handleLanguageChange}>
                <FormControlLabel value="en" control={<Radio />} label={translations.user.languages.en()} />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid item container xs direction="row" justifyContent="flex-end" className="dialog-item-container">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                className="dialog-button"
                onClick={(): void => {
                  this.setState({ selectedLanguage: this.props.language }, () => this.props.onDialogClose());
                }}
              >
                {translations.common.buttons.cancel().toUpperCase()}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={this.state.selectedLanguage === this.props.language}
                className="dialog-button"
                onClick={this.updateLanguage}
              >
                {translations.common.buttons.update().toUpperCase()}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <IconButton
          onClick={(): void => {
            this.setState({ selectedLanguage: this.props.language }, () => this.props.onDialogClose());
          }}
          className="exit-button"
        >
          <Close color="primary" />
        </IconButton>
      </Dialog>
    );
  }
}
