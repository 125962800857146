import { Button, Dialog, Grid, Typography } from "@mui/material";
import React, { Component, ReactNode } from "react";
import Loader from "./loader";
import { translations } from "../../generated/translationHelper";

interface Props {
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel?: () => void;
  showLoader?: boolean;
}

export default class ConfirmationDialog extends Component<Props> {
  private renderButtons(): ReactNode {
    return (
      <Grid container={true} spacing={2} justifyContent="center">
        <Grid item={true}>
          <Button variant="contained" color="primary" onClick={this.props.onConfirm}>
            {translations.common.buttons.confirm()}
          </Button>
        </Grid>
        {this.props.onCancel && (
          <Grid item={true}>
            <Button variant="contained" color="primary" onClick={this.props.onCancel}>
              {translations.common.buttons.cancel()}
            </Button>
          </Grid>
        )}
      </Grid>
    );
  }

  public render(): JSX.Element {
    return (
      <Dialog onClose={this.props.onCancel} open={true}>
        <div className="popup-inner">
          <Typography variant="h6">{this.props.title}</Typography>
          <div className="popup-body">
            <Grid container direction="column" spacing={2}>
              <Grid item={true}>
                <Typography variant="subtitle1">{this.props.message}</Typography>
              </Grid>
              <Grid item={true}>{!this.props.showLoader ? this.renderButtons() : <Loader />}</Grid>
            </Grid>
          </div>
        </div>
      </Dialog>
    );
  }
}
