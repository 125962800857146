import React, { useEffect, useState } from "react";
import { Grid, InputLabel } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DateRange } from "./DateRange";
import { treatLocalTimeAsUtcTime } from "../../utils/TimeUtils";
import { translations } from "../../generated/translationHelper";
import { ValidationError } from "./validation-error";

export interface Props {
  dateRange: DateRange;
  filterByDateRange: (range: DateRange) => Promise<void>;
}

/**
 * Date range objects output by this picker are inclusive for the beginning of the range, exclusive for the end of the
 * range. However, user does the picking in the UI as inclusive for both ends. This means, if the user picks end date
 * 2023/06/07, the end of the range in the DateRange object will have value 2023/06/08 (one day greater).
 */
export const DateRangePicker: React.FC<Props> = (props) => {
  const { dateRange, filterByDateRange } = props;
  const [validationErrorMessage, setValidationErrorMessage] = useState<string | undefined>(undefined);

  async function setBegin(value: Date | undefined): Promise<void> {
    const newRange = { beginning: value, end: dateRange?.end };
    await filterByDateRange(newRange);
  }
  async function setEnd(value: Date | undefined): Promise<void> {
    // make the end of the range exclusive to make "less than" comparison against the date only (no time) simpler
    value?.setDate(value.getDate() + 1);
    const newRange = { beginning: dateRange?.beginning, end: value };
    await filterByDateRange(newRange);
  }

  useEffect(() => {
    if (dateRange.end !== undefined && dateRange.beginning !== undefined && dateRange.end <= dateRange.beginning) {
      setValidationErrorMessage(translations.common.texts.beginningGreaterThanEnd());
    } else {
      setValidationErrorMessage(undefined);
    }
  }, [dateRange]);

  function transformLocalDateTimeToUtcDateOnly(value: Date): Date {
    // User picked a local Date from the UI, but we want to pretend they picked UTC Date.
    // If user's UTC offset is +2 and they picked 2023/12/01, we got the Date value 2023/11/30 22:00:00.000Z
    // This function transforms that value to 2023/12/01 00:00:00.000Z
    // (i.e. same value the user sees in the UI, but in UTC, not user's local time).
    // We also set the time component to zero, so it is done here once and every comparison site does not need to do it.

    return new Date(treatLocalTimeAsUtcTime(value).setUTCHours(0, 0, 0, 0));
  }

  function isValidDate(value: Date | null): value is Date {
    return value !== null && !isNaN(value.valueOf());
  }

  // props.dateRange.end is exclusive day, transform it into inclusive by subtracting one day
  const inclusiveEnd = dateRange.end !== undefined ? new Date(dateRange.end) : undefined;
  inclusiveEnd?.setDate(inclusiveEnd?.getDate() - 1);
  return (
    <Grid container direction="column">
      <Grid item container direction="row" alignContent="center" flexWrap="nowrap" columnGap={1}>
        <Grid item container direction="column">
          <InputLabel htmlFor="date-range-picker-begin">{translations.common.inputs.beginning()}</InputLabel>
          <DatePicker<Date>
            value={dateRange.beginning ?? null}
            onChange={async (value): Promise<void> => {
              const filterValue = isValidDate(value) ? transformLocalDateTimeToUtcDateOnly(value) : undefined;
              await setBegin(filterValue);
            }}
            slotProps={{
              field: { clearable: true },
              textField: { id: "date-range-picker-begin", size: "small", variant: "outlined" },
            }}
            sx={{ "& .MuiInputBase-input": { height: "auto" } }}
            disableFuture
          />
        </Grid>
        <Grid item container direction="column">
          <InputLabel htmlFor="date-range-picker-end">{translations.common.inputs.end()}</InputLabel>
          <DatePicker<Date>
            value={inclusiveEnd ?? null}
            onChange={async (value): Promise<void> => {
              const filterValue = isValidDate(value) ? transformLocalDateTimeToUtcDateOnly(value) : undefined;
              await setEnd(filterValue);
            }}
            slotProps={{
              field: { clearable: true },
              textField: { id: "date-range-picker-end", size: "small", variant: "outlined" },
            }}
            sx={{ "& .MuiInputBase-input": { height: "auto" } }}
          />
        </Grid>
      </Grid>
      <ValidationError>{validationErrorMessage}</ValidationError>
    </Grid>
  );
};
