import { Grid, Tab, Tabs } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { OrderDetailsContent } from "./components/order-details-content";
import { AuthWrapper, AwsConfiguration, Order, OrderApiClient, OrderStatus } from "@sade/data-access";
import { OrderDetailsBanner } from "./components/order-details-banner";
import { translations } from "../../../../generated/translationHelper";
import { useNotification } from "../../../ui/notification";
import TabPanel from "../../../ui/tab-panel";
import { KeyOutlined, SpaceDashboardOutlined } from "@mui/icons-material";
import { Credentials } from "./components/credentials";
import Loader from "../../../ui/loader";
import { useNavigate, useParams } from "react-router-dom";
import Paths from "../../../Paths";

interface Props {
  orderId: number;
}

export enum OrderViewTab {
  Overview = "overview",
  Credentials = "credentials",
}

export const OrderDetails: React.FC<Props> = (props: Props) => {
  const awsConfig = useRef(AwsConfiguration.getConfiguration());
  const apiClient = useRef(new OrderApiClient(awsConfig.current.ApiGateway.RootUrlOrders, AuthWrapper.getAccessToken));

  const [order, setOrder] = useState<Order | undefined>(undefined);
  const displayNotification = useNotification();
  const navigate = useNavigate();

  const resolveTab = useCallback((potentialValue?: string): OrderViewTab => {
    const [, tab] = Object.entries(OrderViewTab).find(([_key, value]) => value === potentialValue) ?? [
      "overview",
      OrderViewTab.Overview,
    ];
    return tab;
  }, []);
  const currentTab = resolveTab(useParams<{ tab?: string }>().tab);

  useEffect(() => {
    async function fetchOrder(): Promise<void> {
      try {
        setOrder(await apiClient.current.getOrder(props.orderId));
      } catch (error) {
        const errorDetail = error instanceof Error ? error.message : translations.orders.texts.unknownError();
        displayNotification({
          title: translations.orders.texts.errorOnGetOrder(),
          message: errorDetail,
          variant: "error",
        });
      }
    }

    fetchOrder().catch((e) => console.error("Error on getting the order: " + e));

    if (order?.status === OrderStatus.InProgress) {
      const interval = setInterval(() => {
        fetchOrder().catch((e) => console.error("Error on getting the order: " + e));
      }, Math.min(order.credentialCountTotal, 120) * 1000);
      return (): void => {
        clearInterval(interval);
      };
    }
  }, [props.orderId, order?.status, order?.credentialCountTotal, displayNotification]);

  const handleTabChange = (event: React.ChangeEvent<unknown>, value: OrderViewTab): void => {
    navigate(`${Paths.ORDERS}/${props.orderId}/${value}`, { replace: true });
  };

  return (
    <Grid container direction="column" rowSpacing={2} sx={{ padding: "0px 24px" }}>
      <Grid item>
        <OrderDetailsBanner order={order} orderId={props.orderId} />
      </Grid>
      <Grid item width="100%">
        <Tabs value={currentTab} onChange={handleTabChange}>
          <Tab
            label={translations.orders.texts.overview()}
            icon={<SpaceDashboardOutlined />}
            iconPosition="start"
            value={OrderViewTab.Overview}
          />
          <Tab
            label={translations.orders.texts.credentials()}
            icon={<KeyOutlined />}
            iconPosition="start"
            value={OrderViewTab.Credentials}
          />
        </Tabs>
        <TabPanel value={currentTab} index={OrderViewTab.Overview}>
          <OrderDetailsContent order={order} />
        </TabPanel>
        <TabPanel value={currentTab} index={OrderViewTab.Credentials}>
          {order !== undefined ? <Credentials order={order} /> : <Loader size="medium" />}
        </TabPanel>
      </Grid>
    </Grid>
  );
};
