import { Grid } from "@mui/material";
import { Order } from "@sade/data-access";
import React from "react";
import { CredentialsCard } from "./components/credentials-card";
import { CardPreview } from "../../../../../ui/card-preview";
import { DetailsCard } from "./components/details-card";

interface Props {
  order?: Order;
}

export const OrderDetailsContent: React.FC<Props> = (props: Props) => {
  return (
    <Grid item container direction="row" columnSpacing={3}>
      <Grid item flexGrow={1}>
        <DetailsCard order={props.order} />
      </Grid>
      <Grid item flexGrow={1}>
        <CredentialsCard order={props.order} />
      </Grid>
      <Grid item>
        <CardPreview orderId={props.order?.orderId} width={432} />
      </Grid>
    </Grid>
  );
};
