import React, { Component, Fragment, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { AuthListener, AuthEvent, AuthWrapper } from "@sade/data-access";
import { changeLanguage } from "../../locales/localisator";
import Paths from "../Paths";

interface Props {
  redirectPath: Paths;
  redirectIfAuthenticated?: boolean;
  children?: ReactNode;
}

interface State {
  isAuthenticated?: boolean;
}

export default class Gatekeeper extends Component<Props, State> {
  private authListener?: AuthListener;

  public constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public async componentDidMount(): Promise<void> {
    console.log("Gatekeeper mounted");
    this.authListener = new AuthListener(this.listener);

    // first pass, use the cached user credentials
    const isAuthenticated = await AuthWrapper.isCurrentUserAuthenticated(false);
    await this.changeLanguage();

    this.setState({
      isAuthenticated,
    });

    // once the cached credentials are checked, check the actual credentials in the background
    const isCurrentUserAuthenticated = async (): Promise<boolean> => AuthWrapper.isCurrentUserAuthenticated(true);
    isCurrentUserAuthenticated().then((isAuthenticated) => this.setState({ isAuthenticated }));
    // jest test run fails mysteriously if we don't wrap AuthWrapper.isCurrentUserAuthenticated in another function
    // solve that and then this can be changed to directly call AuthWrapper.isCurrentUserAuthenticated
  }

  public async componentWillUnmount(): Promise<void> {
    console.log("Gatekeeper unmounting");
    this.authListener?.removeListener();
    this.authListener = undefined;
  }

  private async changeLanguage(): Promise<void> {
    const newLanguage = await AuthWrapper.getLanguage();
    await changeLanguage(newLanguage);
  }

  private listener = (event: AuthEvent): void => {
    if (event === "SignedOut") {
      this.setState({ isAuthenticated: false });
    }

    if (event === "SignedIn") {
      this.setState({ isAuthenticated: true });
    }
  };

  private renderContentOrRedirect(): ReactNode {
    const redirectIfAuthenticated = this.props.redirectIfAuthenticated ?? false;

    if (this.state.isAuthenticated == null) return;

    if (this.state.isAuthenticated !== redirectIfAuthenticated) {
      return this.props.children;
    } else {
      return <Navigate to={this.props.redirectPath} replace />;
    }
  }

  public render(): JSX.Element {
    return <Fragment>{this.renderContentOrRedirect()}</Fragment>;
  }
}
