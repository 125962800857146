import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import { ThemeProvider } from "@mui/material/styles";
import { AuthWrapper, AwsConfiguration } from "@sade/data-access";
import { AwsConfigFactory } from "./utils/AwsConfigFactory";

// Initializes translation instance
import { initLocalization } from "./locales/localisator";
import App from "./app";
import "./styles/css/main.css";
import Loader from "./components/ui/loader";
import { theme } from "./styles/globalTheme";
import { StyledEngineProvider } from "@mui/material";

//this configures the aws connection for the data access module
AwsConfiguration.configure(AwsConfigFactory.getConfig());
AuthWrapper.configureAmplify();
initLocalization();

// Wraps App component with withTranslation HOC for delivering language changes throughout the app
const TranslatedApp = withTranslation()(App);

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <Suspense fallback={<Loader />}>
      <ThemeProvider theme={theme}>
        <TranslatedApp />
      </ThemeProvider>
    </Suspense>
  </StyledEngineProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
