import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack";
import React, { forwardRef, useEffect, useState } from "react";
import { Alert, AlertTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

declare module "notistack" {
  interface VariantOverrides {
    success: { title: string };
    error: { title: string };
  }
}

export type NotificationVariant = "success" | "error";

export interface NotificationMessage {
  title: string;
  message: string;
  variant: NotificationVariant;
}

export const Notification = forwardRef<HTMLDivElement, NotificationMessage & CustomContentProps>(
  ({ style, id, ...props }, ref) => {
    const { closeSnackbar } = useSnackbar();

    return (
      <SnackbarContent ref={ref} style={style}>
        <Alert
          severity={props.variant}
          sx={{ whiteSpace: "pre-line" }}
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={(): void => closeSnackbar(id)}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle>{props.title}</AlertTitle>
          {props.message}
        </Alert>
      </SnackbarContent>
    );
  }
);
Notification.displayName = "Notification";

export const useNotification = (): React.Dispatch<React.SetStateAction<NotificationMessage | undefined>> => {
  const [notification, setNotification] = useState<NotificationMessage | undefined>(undefined);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (notification !== undefined) {
      enqueueSnackbar(notification.message, {
        variant: notification.variant,
        title: notification.title,
      });
    }
  }, [enqueueSnackbar, notification]);

  return setNotification;
};
