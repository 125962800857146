import { Card, CardContent, CardHeader, FormLabel, Grid } from "@mui/material";
import { AuthWrapper, AwsConfiguration, ConfigurationSettingsPurpose, Order, OrderApiClient } from "@sade/data-access";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { translations } from "../../../../../../../generated/translationHelper";
import Loader from "../../../../../../ui/loader";

interface Props {
  order?: Order;
}

export const DetailsCard: React.FC<Props> = (props: Props) => {
  const [configurationSettingsName, setConfigurationSettingsName] = useState<string>();
  const [purpose, setPurpose] = useState<string>();

  const awsConfig = useRef(AwsConfiguration.getConfiguration());
  const apiClient = useRef(new OrderApiClient(awsConfig.current.ApiGateway.RootUrlOrders, AuthWrapper.getAccessToken));

  const getPurposeDisplayName = useCallback((orderPurpose: string | undefined): string => {
    if (orderPurpose === undefined) {
      return "";
    } else if (orderPurpose === ConfigurationSettingsPurpose.MobileAccessCredential) {
      return translations.orders.texts.mobileAccessCredential();
    } else if (orderPurpose === ConfigurationSettingsPurpose.ControlCard) {
      return translations.orders.texts.controlCard();
    } else {
      return orderPurpose;
    }
  }, []);

  useEffect(() => {
    async function getConfigurationSettingsName(): Promise<void> {
      if (props.order) {
        const configurationSettings = await apiClient.current.getConfigurationSettingsForOrder(props.order.orderId);
        setConfigurationSettingsName(configurationSettings.name);
        setPurpose(configurationSettings.purpose);
      }
    }

    getConfigurationSettingsName().catch((e) => console.error("Error on getting the configuration: " + e));
  }, [setConfigurationSettingsName, setPurpose, props.order]);

  function getFormatSpecificFields(): JSX.Element[] {
    const result: JSX.Element[] = [];

    if (props.order) {
      for (const property in props.order.credentialContents) {
        result.push(
          <Grid item container direction="row" key={"CredentialContents_" + property}>
            <FormLabel>{property}</FormLabel>
            <Grid item sx={{ flexGrow: 1 }} />
            {props.order.credentialContents[property]}
          </Grid>
        );
      }
    } else {
      result.push(
        <Grid item container direction="row" key={"CredentialContents_placeholder"}>
          <FormLabel>Placeholder</FormLabel>
          <Grid item sx={{ flexGrow: 1 }} />
          placeholder
        </Grid>
      );
    }

    return result;
  }

  const maxWidthStyles = {
    maxWidth: 300,
  };

  return (
    <Card sx={{ Width: "100%", position: "relative" }}>
      <Loader
        size="medium"
        topBottomPadding={"0"}
        leftRightPadding={"0"}
        hidden={props.order !== undefined}
        styles={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
      />
      <Grid item visibility={props.order !== undefined ? "visible" : "hidden"}>
        <CardHeader title={translations.orders.texts.details()} />
        <CardContent>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item container direction="row">
              <FormLabel>{translations.orders.texts.orderDate()}</FormLabel>
              <Grid item sx={{ flexGrow: 1 }} />
              {props.order?.createdAt.toLocaleString()}
            </Grid>
            <Grid item container direction="row">
              <FormLabel>{translations.orders.texts.purchaseOrder()}</FormLabel>
              <Grid item sx={{ flexGrow: 1 }} />
              <Grid item sx={maxWidthStyles}>
                {props.order?.purchaseOrder}
              </Grid>
            </Grid>
            <Grid item container direction="row">
              <FormLabel>{translations.orders.texts.orderId()}</FormLabel>
              <Grid item sx={{ flexGrow: 1 }} />
              {props.order?.orderId}
            </Grid>
            <Grid item container direction="row">
              <FormLabel>{translations.orders.texts.purpose()}</FormLabel>
              <Grid item sx={{ flexGrow: 1 }} />
              {getPurposeDisplayName(purpose)}
            </Grid>
            <Grid item container direction="row">
              <FormLabel>{translations.orders.texts.customer()}</FormLabel>
              <Grid item sx={{ flexGrow: 1 }} />
              <Grid item sx={maxWidthStyles}>
                {props.order?.organizationName}
              </Grid>
            </Grid>
            <Grid item container direction="row">
              <FormLabel>{translations.orders.inputs.configuration()}</FormLabel>
              <Grid item sx={{ flexGrow: 1 }} />
              {configurationSettingsName ?? ""}
            </Grid>
            <Grid item container direction="row">
              <FormLabel>{translations.orders.inputs.format()}</FormLabel>
              <Grid item sx={{ flexGrow: 1 }} />
              {props.order?.formatName}
            </Grid>
            {getFormatSpecificFields()}
            <Grid item container direction="row">
              <FormLabel>{translations.orders.texts.credentialIdRange()}</FormLabel>
              <Grid item sx={{ flexGrow: 1 }} />
              {props.order?.startIndex} -{" "}
              {props.order ? props.order?.startIndex + props.order?.credentialCountTotal - 1 : 0}
            </Grid>
            <Grid item container direction="row">
              <FormLabel>{translations.orders.texts.programmer()}</FormLabel>
              <Grid item sx={{ flexGrow: 1 }} />
              <Grid item sx={maxWidthStyles}>
                {props.order?.programmerName}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Grid>
    </Card>
  );
};
