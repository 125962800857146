import { AuthWrapper, AwsConfiguration, OrderApiClient, OrderSearchResult } from "@sade/data-access";
import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams, GridValueFormatterParams } from "@mui/x-data-grid";
import { translations } from "../../../../generated/translationHelper";
import { DownloadButton } from "./download-button";
import { OrderActions, OrderRow } from "../OrderRow";
import { NavLink } from "react-router-dom";
import { OrderStatusChip } from "../order-status-chip";
import { OrderViewTab } from "../order-details";

interface Props {
  rows: OrderRow[];
  organizationId?: string;
  searchResults?: OrderSearchResult[];
  searchTerm: string | undefined;
  isLoading: boolean;
}

export const OrdersList: React.FC<Props> = (props) => {
  const orderAnchor = useRef<HTMLAnchorElement>(null);
  const [orderUrlComponent, setOrderUrlComponent] = useState<string | undefined>(undefined);

  const awsConfig = useRef(AwsConfiguration.getConfiguration());
  const apiClient = useRef(new OrderApiClient(awsConfig.current.ApiGateway.RootUrlOrders, AuthWrapper.getAccessToken));

  const [filteredRows, setFilteredRows] = useState<OrderRow[]>([]);

  const applyFilterOrganizationId = useCallback(
    (rows: OrderRow[], organizationId?: string): OrderRow[] =>
      organizationId !== undefined ? rows.filter((r) => r.organizationId === organizationId) : rows,
    []
  );
  const applyFilterSearchResults = useCallback(
    (rows: OrderRow[], searchResults?: OrderSearchResult[]): OrderRow[] =>
      searchResults !== undefined
        ? rows
            .filter((row) => searchResults.some((result) => result.orderId === row.id))
            .map((foundRow) => ({ ...foundRow, credentialMatchesSearch: true }))
        : rows.map((row) => ({ ...row, credentialMatchesSearch: false })),
    []
  );
  useEffect((): void => {
    let newRows = applyFilterOrganizationId(props.rows, props.organizationId);
    newRows = applyFilterSearchResults(newRows, props.searchResults);
    setFilteredRows(newRows);
  }, [props.rows, props.organizationId, props.searchResults, applyFilterOrganizationId, applyFilterSearchResults]);

  async function getFreshDownloadLink(order: OrderRow): Promise<OrderActions | undefined> {
    const orderDetails = await apiClient.current.getOrder(order.id);
    return orderDetails.downloads
      ? {
          downloadUrl: orderDetails.downloads.archiveUrl,
          expiresAt: orderDetails.downloads.expiresAt,
        }
      : undefined;
  }

  const columns = useRef<GridColDef[]>([
    {
      field: "createdAt",
      headerName: translations.orders.texts.created(),
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams<Date>): string => {
        return `${params.value.toLocaleString()}`;
      },
    },
    {
      field: "purchaseOrder",
      headerName: translations.orders.texts.purchaseOrder(),
      flex: 1,
    },
    {
      field: "organizationName",
      headerName: translations.orders.texts.customer(),
      flex: 1,
    },
    {
      field: "status",
      headerName: translations.orders.texts.orderStatus(),
      flex: 1,
      renderCell: (params: GridRenderCellParams<OrderRow>): ReactElement => {
        return <OrderStatusChip orderStatus={params.row.status} sx={{ height: 26 }} />;
      },
    },
    {
      field: "credentialCountTotal",
      headerName: translations.orders.texts.credentialsTotal(),
      flex: 1,
    },
    {
      field: "programmerName",
      headerName: translations.orders.texts.programmer(),
      flex: 1,
    },
    {
      field: "actions",
      headerName: translations.orders.texts.actions(),
      flex: 0,
      renderCell: (params: GridRenderCellParams<OrderRow>): ReactElement => {
        return <DownloadButton order={params.row} getFreshDownloadLink={getFreshDownloadLink} />;
      },
    },
  ]);

  useEffect(() => {
    if (orderUrlComponent !== undefined) {
      orderAnchor.current?.click();
    }
  }, [orderUrlComponent]);

  return (
    <>
      <DataGrid
        columns={columns.current}
        rows={filteredRows}
        loading={props.isLoading}
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        onRowClick={(params: GridRowParams<OrderRow>): void => {
          if (params.row.credentialMatchesSearch && props.searchTerm !== undefined) {
            setOrderUrlComponent(`${params.row.id}/${OrderViewTab.Credentials}?searchTerm=${props.searchTerm}`);
          } else {
            setOrderUrlComponent(`${params.row.id}/${OrderViewTab.Overview}`);
          }
        }}
        autoHeight={true}
      />
      <NavLink hidden to={orderUrlComponent ?? "#"} ref={orderAnchor} />
    </>
  );
};
