const PASSWORD_MISMATCH_ERROR_DELAY = 700;

import React, { ChangeEvent, Component } from "react";
import {
  Button,
  debounce,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import { translations } from "../../../generated/translationHelper";
import { AuthWrapper, isErrorWithCode } from "@sade/data-access";

type PasswordObject = { password: string; errorMessage?: string };

interface Props {
  dialogOpen: boolean;
  onDialogClose: (saveSuccessful?: boolean) => void;
}

interface State {
  oldPassword: PasswordObject;
  newPassword: PasswordObject;
  confirmNewPassword: PasswordObject;
  generalError?: string;
  showPassword: boolean;
}

export default class EditPasswordDialog extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      oldPassword: { password: "" },
      newPassword: { password: "" },
      confirmNewPassword: { password: "" },
      showPassword: false,
    };
  }

  private closeDialog = (saveSuccessful?: boolean): void => {
    this.setState({
      oldPassword: { password: "" },
      newPassword: { password: "" },
      confirmNewPassword: { password: "" },
    });
    this.props.onDialogClose(saveSuccessful);
  };

  private updatePassword = async (): Promise<void> => {
    const { oldPassword, newPassword } = this.state;
    if (!newPassword.password || !oldPassword.password) return;
    try {
      await AuthWrapper.submitNewPassword(oldPassword.password, newPassword.password);
      this.closeDialog(true);
    } catch (error) {
      console.error("handlePasswordSubmit", error);
      if (isErrorWithCode(error)) this.handleErrorCode(error.message);
    }
  };

  private handleErrorCode(code?: string): void {
    const oldPassword = { ...this.state.oldPassword };
    const newPassword = { ...this.state.newPassword };
    let generalError = "";
    switch (code) {
      case "Attempt limit exceeded, please try after some time.":
        generalError = translations.common.texts.tooManyAttempts();
        break;
      case "1 validation error detected: Value at 'previousPassword' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$":
      case "2 validation errors detected: Value at 'previousPassword' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$; Value at 'proposedPassword' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$":
      case "Incorrect username or password.":
        oldPassword.errorMessage = translations.user.texts.incorrectCredentials();
        break;
      case "Network error":
        generalError = translations.common.texts.networkError();
        break;
      case "Password did not conform with policy: Password must have numeric characters":
        newPassword.errorMessage = translations.common.texts.passwordMustHaveNumbers();
        break;
      case "Password did not conform with policy: Password must have lowercase characters":
        newPassword.errorMessage = translations.common.texts.passwordMustHaveLowercaseCharacters();
        break;
      case "Password did not conform with policy: Password must have uppercase characters":
        newPassword.errorMessage = translations.common.texts.passwordMustHaveUppercaseCharacters();
        break;
      case "1 validation error detected: Value at 'proposedPassword' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$":
        newPassword.errorMessage = translations.common.texts.passwordInvalid();
        break;
      case "1 validation error detected: Value at 'previousPassword' failed to satisfy constraint: Member must have length greater than or equal to 6":
      case "2 validation errors detected: Value at 'previousPassword' failed to satisfy constraint: Member must have length greater than or equal to 6; Value at 'proposedPassword' failed to satisfy constraint: Member must have length greater than or equal to 6":
      case "1 validation error detected: Value at 'proposedPassword' failed to satisfy constraint: Member must have length greater than or equal to 6":
      case "Password did not conform with policy: Password not long enough":
        newPassword.errorMessage = translations.common.texts.passwordMustBeLongEnough();
        break;
      case "1 validation error detected: Value at 'proposedPassword' failed to satisfy constraint: Member must have length less than or equal to 256":
        newPassword.errorMessage = translations.common.texts.passwordMustBeShortEnough();
        break;
      case "Provided password cannot be used for security reasons.":
        newPassword.errorMessage = translations.common.texts.passwordRejectedForSecurityReasons();
        break;
      case "Invalid session for the user, session is expired.":
        generalError = translations.common.texts.userSessionExpired();
        break;
      default:
        generalError = translations.common.texts.unableToPerformAction();
        break;
    }
    this.setState({ oldPassword, newPassword, generalError });
  }

  private handleOldChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const password = event.target.value;
    this.setState({ oldPassword: { password } }, () => this.debouncedMatch());
  };

  private handleNewChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const password = event.target.value;
    this.setState({ newPassword: { password } }, () => this.debouncedMatch());
  };

  private handleConfirmChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const password = event.target.value;
    this.setState({ confirmNewPassword: { password } }, () => this.debouncedMatch());
  };

  private checkPasswordMatch = (): void => {
    const oldPassword = this.state.oldPassword.password;
    const newPassword = { ...this.state.newPassword };
    newPassword.errorMessage =
      oldPassword && oldPassword === newPassword.password ? translations.common.texts.passwordMustDiffer() : undefined;
    this.setState({ newPassword });
    const confirmNewPassword = { ...this.state.confirmNewPassword };
    if (!newPassword.password || !confirmNewPassword.password) return;
    confirmNewPassword.errorMessage =
      newPassword.password !== confirmNewPassword.password
        ? translations.common.texts.passwordsNotMatching()
        : undefined;
    this.setState({ confirmNewPassword });
    this.setState({ generalError: undefined });
  };

  private debouncedMatch = debounce(this.checkPasswordMatch, PASSWORD_MISMATCH_ERROR_DELAY);

  private passwordUpdatePossible(): boolean {
    const { oldPassword, newPassword, confirmNewPassword, generalError } = this.state;
    return (
      !!(oldPassword.password && newPassword.password && confirmNewPassword.password) &&
      oldPassword.password !== newPassword.password &&
      newPassword.password === confirmNewPassword.password &&
      !(generalError || oldPassword.errorMessage || newPassword.errorMessage || confirmNewPassword.errorMessage)
    );
  }

  private toggleShowPassword = (): void => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  private handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
    // prevent text fields from loosing focus
    event.preventDefault();
  };

  private renderPasswordFields(): React.ReactNode {
    const { oldPassword, newPassword, confirmNewPassword, showPassword } = this.state;
    return (
      <Grid item container direction="column" spacing={6}>
        <Grid item>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel htmlFor="old-password-field" error={!!oldPassword.errorMessage}>
              {translations.user.inputs.oldPassword().toUpperCase()}
            </InputLabel>
            <OutlinedInput
              id="old-password-field"
              label={translations.user.inputs.oldPassword().toUpperCase()}
              type={showPassword ? "text" : "password"}
              value={oldPassword.password}
              onChange={this.handleOldChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={this.toggleShowPassword} onMouseDown={this.handleMouseDownPassword} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              error={!!oldPassword.errorMessage}
            />
          </FormControl>
          {oldPassword.errorMessage ? (
            <Typography className="password-error-text">{oldPassword.errorMessage}</Typography>
          ) : null}
        </Grid>
        <Grid item>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel htmlFor="new-password-field" error={!!newPassword.errorMessage}>
              {translations.common.inputs.newPassword().toUpperCase()}
            </InputLabel>
            <OutlinedInput
              id="new-password-field"
              label={translations.common.inputs.newPassword().toUpperCase()}
              type={showPassword ? "text" : "password"}
              value={newPassword.password}
              onChange={this.handleNewChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={this.toggleShowPassword} onMouseDown={this.handleMouseDownPassword} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              error={!!newPassword.errorMessage}
            />
          </FormControl>
          {newPassword.errorMessage ? (
            <Typography className="password-error-text">{newPassword.errorMessage}</Typography>
          ) : null}
        </Grid>
        <Grid item>
          <FormControl variant="outlined" fullWidth size="small" className="">
            <InputLabel htmlFor="confirm-new-password-field" error={!!confirmNewPassword.errorMessage}>
              {translations.common.inputs.confirmNewPassword().toUpperCase()}
            </InputLabel>
            <OutlinedInput
              id="confirm-new-password-field"
              label={translations.common.inputs.confirmNewPassword().toUpperCase()}
              type={showPassword ? "text" : "password"}
              value={confirmNewPassword.password}
              onChange={this.handleConfirmChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={this.toggleShowPassword} onMouseDown={this.handleMouseDownPassword} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              error={!!confirmNewPassword.errorMessage}
            />
          </FormControl>
          {confirmNewPassword.errorMessage ? (
            <Typography className="password-error-text">{confirmNewPassword.errorMessage}</Typography>
          ) : null}
        </Grid>
      </Grid>
    );
  }

  private renderPasswordRequirements = (): React.ReactNode => {
    return (
      <Grid item container direction="row" justifyContent="space-between">
        <Grid item xs={12}>
          <Typography className="dialog-gray-text">{translations.user.texts.passwordRequirements()}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className="dialog-gray-text with-bullet">
            {translations.logIn.texts.passwordCharacters({ count: 14 })}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className="dialog-gray-text with-bullet">
            {translations.logIn.texts.passwordNumber({ count: 1 })}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className="dialog-gray-text with-bullet">
            {translations.logIn.texts.passwordLowercase({ count: 1 })}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className="dialog-gray-text with-bullet">
            {translations.logIn.texts.passwordUppercase({ count: 1 })}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  public render(): React.ReactNode {
    return (
      <Dialog open={this.props.dialogOpen} fullWidth>
        <Grid container direction="column" className="edit-dialog-container">
          <Grid item>
            <Typography variant="h5">{translations.user.texts.editPassword()}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{translations.user.texts.editPasswordInstructions()}</Typography>
          </Grid>
          {this.renderPasswordFields()}
          {this.renderPasswordRequirements()}
          <Grid item>
            <Typography className="password-error-text">{this.state.generalError ?? " "}</Typography>
          </Grid>
          <Grid item container xs direction="row" justifyContent="flex-end" className="dialog-item-container">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                className="dialog-button"
                onClick={(): void => this.closeDialog()}
              >
                {translations.common.buttons.cancel().toUpperCase()}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={!this.passwordUpdatePossible()}
                className="dialog-button"
                onClick={this.updatePassword}
              >
                {translations.common.buttons.update().toUpperCase()}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <IconButton onClick={(): void => this.closeDialog()} className="exit-button">
          <Close color="primary" />
        </IconButton>
      </Dialog>
    );
  }
}
