import { AuthWrapper } from "../auth/AuthWrapper";
import { Service } from "../backend/AppSyncClientProvider";
import { AWSOrganization } from "./AWSOrganization";
import { AWSUser } from "./AWSUser";
import { OrganizationsCreateDocument, OrganizationsGetDocument, OrganizationsRolesAssignDocument, OrganizationsUsersAddDocument, ResultType, RolesListDocument, UsersCreateDocument, UsersDeleteDocument, UsersGetDocument, UsersRolesListDocument, } from "../../generated/gqlUsers";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { EntityRelationCache, } from "../private-utils/EntityRelationCache";
import { throwGQLError } from "../private-utils/throwGQLError";
import { AuthListener } from "../auth/AuthListener";
import { AsyncCache } from "../private-utils/AsyncCache";
import { Role } from "./Role";
import { isDefined } from "../../common";
// TODO:  method for cache pruning - or an actual cache
//        also, cache could be extracted with the pruning code
//        also, would graphql cache be enough here?
//        the cache should also be invalidated when the user logs out - is there a way to listen for that?
export class AWSOrganizationBackend {
    constructor() {
        // this is public so AWSEntities can reach into it
        this.entityRelationCache = new EntityRelationCache();
        this.cache = new AsyncCache();
        this.authEventHandler = (event) => {
            if (event === "SignedOut") {
                this.cache.clear();
                this.entityRelationCache.clear();
            }
        };
        this.authListener = new AuthListener(this.authEventHandler);
    }
    async getOrganization(organizationId) {
        const fetchOrganization = async () => {
            var _a, _b;
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = await client.query(OrganizationsGetDocument, { organizationId });
            if ((_a = response.data.organizationsGet) === null || _a === void 0 ? void 0 : _a.id) {
                return new AWSOrganization(this, {
                    id: response.data.organizationsGet.id,
                    name: response.data.organizationsGet.name,
                    parentOrganizationId: response.data.organizationsGet.organizationId,
                    maxSecureCode: (_b = response.data.organizationsGet.maxSecureCode) !== null && _b !== void 0 ? _b : 0,
                });
            }
        };
        return this.cache.get(organizationId, fetchOrganization);
    }
    async getUser(userId) {
        const fetchUser = async () => {
            var _a;
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = await client.query(UsersGetDocument, { userId });
            if ((_a = response.data.usersGet) === null || _a === void 0 ? void 0 : _a.id) {
                return new AWSUser(this, {
                    id: response.data.usersGet.id,
                    email: response.data.usersGet.email,
                    homeOrganization: response.data.usersGet.homeOrganizationId,
                });
            }
        };
        return this.cache.get(userId, fetchUser);
    }
    async getRole(id) {
        const roles = await this.listRoles();
        return roles.find((role) => role.identifier === id);
    }
    async listRoles() {
        const fetchRoles = async () => {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
            const response = await client.query(RolesListDocument, {});
            if (response.data.rolesList) {
                return response.data.rolesList.roles.map((role) => Role.fromGraphQL(role));
            }
            else {
                throwGQLError(response, "Could not fetch Roles");
            }
        };
        const roles = await this.cache.get(AWSOrganizationBackend.ROLE_CACHE_KEY, fetchRoles);
        return roles !== null && roles !== void 0 ? roles : [];
    }
    async getCurrentHomeOrganization() {
        const claims = await AuthWrapper.getCurrentAuthenticatedUserClaims();
        if (!claims) {
            throw new Error("No authenticated user");
        }
        const organization = await this.getOrganization(claims.homeOrganizationId);
        if (!organization) {
            throw new Error("Could not resolve home organization of current user");
        }
        return organization;
    }
    async getCurrentUser() {
        const claims = await AuthWrapper.getCurrentAuthenticatedUserClaims();
        if (!claims) {
            return;
        }
        return await this.getUser(claims.userId);
    }
    async createOrganization(owner, parameters) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(OrganizationsCreateDocument, {
            payload: {
                name: parameters.name,
                parentOrganizationId: owner.getId(),
                maxSecureCode: parameters.maxSecureCode,
            },
        });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.organizationsCreate)) {
            throwGQLError(response, "Failed to create organization");
        }
        const newOrganization = new AWSOrganization(this, {
            id: response.data.organizationsCreate.id,
            name: response.data.organizationsCreate.name,
            parentOrganizationId: response.data.organizationsCreate.organizationId,
            maxSecureCode: response.data.organizationsCreate.maxSecureCode,
        });
        console.debug(`IN ${parameters.maxSecureCode} OUT ${newOrganization.getMaxSecureCode()}`);
        this.cache.set(newOrganization.getId(), newOrganization);
        return newOrganization;
    }
    async createUser(owner, parameters) {
        var _a, _b, _c;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(UsersCreateDocument, {
            payload: {
                email: parameters.email.toLowerCase(),
                organizationId: owner.getId(),
                roles: (_a = parameters.roles) === null || _a === void 0 ? void 0 : _a.map((role) => role.identifier),
                resendInvitation: (_b = parameters.resendInvitation) !== null && _b !== void 0 ? _b : false,
            },
        });
        if (!((_c = response.data) === null || _c === void 0 ? void 0 : _c.usersCreate)) {
            throwGQLError(response, "Failed to create new user");
        }
        const newUser = new AWSUser(this, {
            id: response.data.usersCreate.id,
            email: response.data.usersCreate.email,
            homeOrganization: owner,
        });
        this.cache.set(newUser.getId(), newUser);
        return newUser;
    }
    //
    // OrganizationBackend implementation ends:
    // Next are internal method for AWS-implementation classes to use for backend communication
    // and caching
    //
    async addUserToOrganization(organization, user, roles) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(OrganizationsUsersAddDocument, {
            organizationId: organization.getId(),
            userId: user.getId(),
            roles,
        });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.organizationsUsersAdd)) {
            throwGQLError(response, "Failed to add user to organization");
        }
        else {
            const success = response.data.organizationsUsersAdd.result === ResultType.Ok;
            if (!success) {
                const reason = response.data.organizationsUsersAdd.failureReason;
                throw new Error(`Failed to add user to organization ${reason ? ": " + reason : ""}`);
            }
            this.entityRelationCache.link(organization, user);
        }
    }
    /**
     * Sends a request to replace user's current roles within the given organization with new roles.
     *
     * @param userId - user
     * @param organizationId - context organization
     * @param roles - role identifiers
     * @throws if user/organization/roles do not exist
     * @throws on internal service failure
     */
    async assignUserOrganizationRoles(userId, organizationId, roles) {
        var _a, _b, _c, _d, _e;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const response = await client.mutate(OrganizationsRolesAssignDocument, {
            userId,
            organizationId,
            roles,
        });
        if (!((_a = response.data) === null || _a === void 0 ? void 0 : _a.organizationsRolesAssign)) {
            throwGQLError(response);
        }
        else {
            const success = ((_c = (_b = response.data) === null || _b === void 0 ? void 0 : _b.organizationsRolesAssign) === null || _c === void 0 ? void 0 : _c.result) === ResultType.Ok;
            if (!success) {
                const reason = (_e = (_d = response.data) === null || _d === void 0 ? void 0 : _d.organizationsRolesAssign) === null || _e === void 0 ? void 0 : _e.failureReason;
                throw new Error(`Failed to set roles${reason ? ": " + reason : ""}`);
            }
        }
    }
    /**
     * Retrieves a map from organization IDs to roles for the given user.
     * @param userId - user
     * @param organizationId - root organization of the subtree
     * @returns an object with a map from organization IDs to Role objects, and
     *          inherited roles active in the parameter organization.
     *          If some roles are no longer valid those are dropped from the result.
     * @throws if unable to retrieve roles
     */
    async getUserRoles(userId, organizationId) {
        var _a;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        // TODO: nextToken
        const response = await client.query(UsersRolesListDocument, {
            userId,
            organizationId,
        });
        console.log("getUserRoles", userId, response);
        const { usersRolesList } = response.data;
        if (!usersRolesList) {
            throwGQLError(response, "Failed to retrieve user's roles");
        }
        // construct fresh Roles from the role definitions that are part of the response
        const activeRoleMap = usersRolesList.activeRoles.reduce((map, gqlRole) => {
            const role = Role.fromGraphQL(gqlRole);
            map.set(role.identifier, role);
            return map;
        }, new Map());
        const roleTree = new Map();
        usersRolesList.roleGrants.forEach((grant) => {
            if (!grant.roles)
                return;
            const roles = grant.roles.map((roleId) => activeRoleMap.get(roleId)).filter(isDefined);
            if (roles.length !== grant.roles.length) {
                console.error(`Could not find roles for all identifiers: ${grant.roles.join(", ")}`);
            }
            roleTree.set(grant.organizationId, roles);
        });
        const inheritedRoles = ((_a = usersRolesList.inheritedRoles) !== null && _a !== void 0 ? _a : [])
            .map((roleId) => activeRoleMap.get(roleId))
            .filter(isDefined);
        return { roleTree, inheritedRoles };
    }
    /**
     * Deletes user from the backend (and local caches).
     *
     * @param userId - user
     * @throws if unable to delete user (permissions, does not exist, etc)
     */
    async deleteUser(userId) {
        var _a, _b, _c, _d, _e;
        const client = AppSyncClientFactory.createProvider().getTypedClient(Service.USERS);
        const result = await client.mutate(UsersDeleteDocument, { userId });
        if (((_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.usersDelete) === null || _b === void 0 ? void 0 : _b.result) !== ResultType.Ok) {
            throwGQLError(result, (_e = (_d = (_c = result.data) === null || _c === void 0 ? void 0 : _c.usersDelete) === null || _d === void 0 ? void 0 : _d.failureReason) !== null && _e !== void 0 ? _e : "Failed to delete user");
        }
        else {
            await this.cleanEntityFromCaches(userId);
        }
    }
    // TODO: make private by moving backend calls from AWSOrganization here
    async cleanEntityFromCaches(id) {
        const entity = await this.cache.delete(id);
        if (entity) {
            this.entityRelationCache.remove(entity);
        }
    }
}
AWSOrganizationBackend.ROLE_CACHE_KEY = "roles-async-cache-key";
