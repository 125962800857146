import React, { JSX, useEffect, useRef, useState } from "react";
import { AuthWrapper, AwsConfiguration, Format, OrderApiClient } from "@sade/data-access";
import { Select, SelectChangeEvent, InputLabel, MenuItem } from "@mui/material";
import Loader from "../../ui/loader";
import { translations } from "../../../generated/translationHelper";
import { ValidationError } from "../../ui/validation-error";

interface Props {
  organizationId?: string;
  currentlySelectedFormatName?: string;
  validationErrorMessage?: string;
  selectFormat: (format: Format) => void;
}

export const FormatSelect: React.FC<Props> = (props) => {
  const awsConfig = useRef(AwsConfiguration.getConfiguration());
  const apiClient = useRef(new OrderApiClient(awsConfig.current.ApiGateway.RootUrlOrders, AuthWrapper.getAccessToken));

  const [formats, setFormats] = useState<Format[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingFailed, setIsLoadingFailed] = useState(false);

  useEffect(() => {
    async function getSelectableFormats(): Promise<Format[]> {
      if (props.organizationId !== undefined) {
        return await apiClient.current.getFormats(props.organizationId);
      }
      return [];
    }

    // reset in case organization ID was changed - we can't leave the values for the previously selected organization
    setFormats([]);
    setIsLoading(true);

    getSelectableFormats()
      .then((selectableFormats) => {
        setFormats(selectableFormats);
      })
      .catch((error) => {
        console.error(error);
        setIsLoadingFailed(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [props.organizationId, setFormats, setIsLoadingFailed, setIsLoading]);

  function selectFormat(event: SelectChangeEvent): void {
    const formatName = event.target.value;
    const selected = formats.find((f) => f.name === formatName);
    props.selectFormat(selected as Format);
  }

  function renderFormatList(): JSX.Element | JSX.Element[] {
    if (!isLoading && !isLoadingFailed) {
      return formats.map((f) => {
        return (
          <MenuItem value={f.name} key={`${f.name}`}>
            {f.name}
          </MenuItem>
        );
      });
    } else if (!isLoadingFailed) {
      return (
        <MenuItem value="">
          <Loader size={1} topBottomPadding={"0"} leftRightPadding={"1em"} />
          {translations.common.texts.loading()}…
        </MenuItem>
      );
    } else {
      return <MenuItem value="">{translations.orders.texts.loadingFormatsFailed()}</MenuItem>;
    }
  }

  return (
    <>
      <InputLabel id="label-format-select">{translations.orders.inputs.format()}</InputLabel>
      <Select
        labelId="label-format-select"
        value={props.currentlySelectedFormatName ?? ""}
        onChange={selectFormat}
        displayEmpty={false}
        style={{ width: "100%" }}
        size="small"
      >
        {renderFormatList()}
      </Select>
      <ValidationError>{props.validationErrorMessage}</ValidationError>
    </>
  );
};
