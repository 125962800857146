import { PromiseWaitList } from "./PromiseWaitList";
/**
 * Cache specialized in storing Promise results.
 *
 * In cases where multiple "clients" required the same cacheable, asynchronous entity,
 * AsyncCache may be used to make sure that only one async call is made and other
 * requesters receive a promise which will eventually resolve into the entity (or error).
 */
export class AsyncCache {
    constructor() {
        this.cache = new Map();
    }
    /**
     * Get a thing from cache.
     * If the thing has not been fetched yet, uses the provided objectProvider to fetch the thing.
     * If the provider throws, the error will be delivered to the other called queued for the fetch result.
     *
     * @param key
     *    Unique key used to store the entity
     * @param objectProvider
     *    Async method which fetches the object on cache miss
     * @returns promise which might resolve into a thing - or undefined
     */
    async get(key, objectProvider) {
        const cached = this.cache.get(key);
        if (cached) {
            const ret = (await cached.get());
            return ret !== null && ret !== void 0 ? ret : undefined;
        }
        const waitList = new PromiseWaitList(`cache.get(${key})`);
        this.cache.set(key, waitList);
        try {
            const thing = await objectProvider();
            if (thing) {
                waitList.set(thing);
            }
            else {
                waitList.set(null);
                this.cache.delete(key);
            }
            return thing;
        }
        catch (err) {
            waitList.fail(err);
            this.cache.delete(key);
            throw err;
        }
    }
    /**
     * Stores thing to the cache under the key.
     * @param key
     *    Unique key that identifies the thing
     * @param thing
     *    Thing to cache
     * @returns true if stored, false if already stored
     */
    set(key, thing) {
        if (!this.cache.has(key)) {
            const waitList = new PromiseWaitList(`cache.set(${key})`);
            waitList.set(thing);
            this.cache.set(key, waitList);
            return true;
        }
        return false;
    }
    /**
     * Deletes value from the cache, if one exists for the key and
     * returns a promise resolving to the deleted value.
     * @param key
     *    Unique key
     */
    async delete(key) {
        var _a;
        const value = this.cache.get(key);
        if (value) {
            this.cache.delete(key);
            return (_a = (await value.get())) !== null && _a !== void 0 ? _a : undefined;
        }
    }
    /**
     * Looks through cache to find an object that a given callback function considers correct.
     * @param callback
     *   Callback function, returns boolean truthy
     */
    async find(callback) {
        for (const value of this.cache.values()) {
            try {
                const unwrappedObject = await value.get();
                if (unwrappedObject && callback(unwrappedObject))
                    return unwrappedObject;
            }
            catch (error) {
                console.warn("Cache error", error);
            }
        }
    }
    /**
     * Does the key have something cached.
     * @param key
     *    Unique key
     */
    has(key) {
        return this.cache.has(key);
    }
    /**
     * Empties the cache.
     * However, living promises will not be invalidated.
     */
    clear() {
        this.cache.clear();
    }
}
