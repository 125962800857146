import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, Dialog, FormControlLabel, Grid } from "@mui/material";
import { DialogTitle } from "../../../ui/dialog-title";
import { translations } from "../../../../generated/translationHelper";
import { AuthWrapper, AwsConfiguration, OrderApiClient, OrderTemplate, Organization } from "@sade/data-access";
import MuiDialogActions from "@mui/material/DialogActions";
import Loader from "../../../ui/loader";
import { useNotification } from "../../../ui/notification";
import MuiDialogContent from "@mui/material/DialogContent";
import { SearchField } from "../../../ui/search-field";

interface Props {
  organization: Organization;
  availableOrderTemplates: Set<OrderTemplate>;
  selectedOrderTemplates: Set<number>;
  isLoadingOrderTemplates: boolean;
  assignOrderTemplates: (newOrderTemplateIds: Set<number>) => void;
  close: () => void;
}

export const OrderTemplateAssignment: React.FC<Props> = (props) => {
  const awsConfig = useRef(AwsConfiguration.getConfiguration());
  const apiClient = useRef(new OrderApiClient(awsConfig.current.ApiGateway.RootUrlOrders, AuthWrapper.getAccessToken));

  const { organization, isLoadingOrderTemplates } = props;

  const displayNotification = useNotification();

  const [isAssigningOrderTemplates, setIsAssigningOrderTemlates] = useState(false);
  const [availableOrderTemplates, setAvailableOrderTemplates] = useState(new Set<OrderTemplate>());
  const [selectedOrderTemplates, setSelectedOrderTemplates] = useState(new Set<number>());
  const [filteredOrderTemplates, setFilteredOrderTemplates] = useState<OrderTemplate[]>([]);
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  useEffect(() => {
    setSelectedOrderTemplates(props.selectedOrderTemplates);
  }, [props.selectedOrderTemplates]);
  useEffect(() => {
    setAvailableOrderTemplates(props.availableOrderTemplates);
  }, [props.availableOrderTemplates]);

  useEffect(() => {
    if (searchTerm !== undefined) {
      const regExPattern = new RegExp(searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "i"); // escape regex special characters
      const filtered = Array.from(availableOrderTemplates).filter((ot) => regExPattern.test(ot.name));
      setFilteredOrderTemplates(filtered);
    } else {
      setFilteredOrderTemplates(Array.from(availableOrderTemplates));
    }
  }, [availableOrderTemplates, searchTerm]);

  async function assignSelectedOrderTemplates(): Promise<void> {
    try {
      setIsAssigningOrderTemlates(true);

      await apiClient.current.assignOrderTemplates(selectedOrderTemplates, organization.getId());
      props.assignOrderTemplates(selectedOrderTemplates);

      props.close();
    } catch (e) {
      const message = e instanceof Error ? e.message : translations.common.texts.unknownError();
      displayNotification({
        title: translations.admin.texts.assigningOrderTemplatesFailed(),
        message: message,
        variant: "error",
      });
    } finally {
      setIsAssigningOrderTemlates(false);
    }
  }

  function handleSelectedOrderTemplatesChanged(orderTemplateId: number, isSelected: boolean): void {
    if (isSelected) {
      setSelectedOrderTemplates((previous) => {
        const newSelectedOrderTemplates = new Set(previous);
        return newSelectedOrderTemplates.add(orderTemplateId);
      });
    } else {
      setSelectedOrderTemplates((previous) => {
        const newSelectedOrderTemplates = new Set(previous);
        newSelectedOrderTemplates.delete(orderTemplateId);
        return new Set(newSelectedOrderTemplates);
      });
    }
  }

  function renderOrderTemplatesList(): React.JSX.Element[] {
    return filteredOrderTemplates.map((f) => {
      return (
        <Grid item key={f.id}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedOrderTemplates.has(f.id)}
                onChange={(e, v): void => handleSelectedOrderTemplatesChanged(f.id, v)}
                color="primary"
                size="small"
                disabled={isAssigningOrderTemplates}
                sx={{ marginTop: -0.5 }}
              />
            }
            label={f.name}
            sx={{
              display: "block",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              borderBottom: 1,
              borderColor: "rgb(0, 0, 0, 0.12)",
              paddingBottom: 1,
            }}
          />
        </Grid>
      );
    });
  }

  return (
    <Dialog open={true} onClose={props.close} maxWidth="md">
      <DialogTitle onClose={props.close}>
        {translations.admin.texts.assignOrderTemplatesTo({ organizationName: organization.getName() })}
      </DialogTitle>
      <MuiDialogContent sx={{ height: "50vh" }}>
        <Grid container direction="column" rowGap={1}>
          <SearchField
            filterBySearchTerm={async (term: string | undefined): Promise<void> => setSearchTerm(term)}
            minimumSearchTermLength={0}
            debounceSeconds={0.1}
          />
          {isLoadingOrderTemplates ? <Loader /> : renderOrderTemplatesList()}
        </Grid>
      </MuiDialogContent>
      <MuiDialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={assignSelectedOrderTemplates}
          disabled={isLoadingOrderTemplates || isAssigningOrderTemplates}
        >
          <Loader
            size={1}
            topBottomPadding={"0"}
            leftRightPadding={"0"}
            hidden={!isAssigningOrderTemplates}
            styles={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
          />
          <span style={{ visibility: isAssigningOrderTemplates ? "hidden" : "visible" }}>
            {translations.admin.buttons.assign()}
          </span>
        </Button>
        <Button variant="outlined" color="primary" onClick={props.close}>
          {translations.common.buttons.cancel()}
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
};
