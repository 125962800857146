import React, { HTMLAttributes, JSX, PropsWithChildren, ReactElement, useEffect, useState } from "react";
import { InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import Loader from "../ui/loader";
import { translations } from "../../generated/translationHelper";
import { ValidationError } from "./validation-error";

export const allItems: unique symbol = Symbol(crypto.randomUUID());
type ItemId = string | number;

export type SelectItem<TItem> =
  | {
      id: ItemId;
      name: string;
      item: TItem;
    }
  | {
      id: typeof allItems;
      name: string;
      item: typeof allItems;
    };

interface Props<TItem> {
  loadItems: () => Promise<SelectItem<TItem>[]>;
  selectedId: ItemId | undefined;
  label: string;
  validationErrorMessage?: string;
  disabled?: boolean;
}
export interface PropsDisableAll<TItem> extends Props<TItem> {
  enableSelectingAll: false;
  select: (selected: TItem) => Promise<void>;
}
export interface PropsEnableAll<TItem> extends Props<TItem> {
  enableSelectingAll: true;
  select: (selected: TItem | typeof allItems) => Promise<void>;
}

export const SelectWithLoading = <TItem,>(
  props: PropsWithChildren<(PropsDisableAll<TItem> | PropsEnableAll<TItem>) & HTMLAttributes<HTMLElement>>
): ReactElement => {
  const { loadItems } = props;
  const [items, setItems] = useState<SelectItem<TItem>[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingFailed, setIsLoadingFailed] = useState(false);

  useEffect(() => {
    loadItems()
      .then((loadedItems) => {
        const presetItems: SelectItem<TItem>[] = props.enableSelectingAll
          ? [{ id: allItems, item: allItems, name: translations.common.texts.all() }]
          : [];
        setItems(presetItems.concat(loadedItems));
      })
      .catch((error) => {
        console.error("Error in populating a list of selectable items", error);
        setIsLoadingFailed(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [loadItems, setItems, setIsLoading, setIsLoadingFailed, props.enableSelectingAll]);

  async function selectItem(event: SelectChangeEvent): Promise<void> {
    const selectedItem = items.find((i) => i.id.toString() === event.target.value);
    if (selectedItem !== undefined) {
      if (!props.enableSelectingAll && selectedItem.id !== allItems) {
        await props.select(selectedItem.item);
      } else if (props.enableSelectingAll) {
        await props.select(selectedItem.item);
      }
    } else {
      throw new Error("Selected item was not found from the list of items (IMPOSSIBLE)");
    }
  }

  function renderItemList(): JSX.Element | JSX.Element[] {
    if (!isLoading && !isLoadingFailed) {
      return items.map((i, index) => {
        return (
          <MenuItem value={i.id.toString()} key={index} sx={{ whiteSpace: "pre" }}>
            {i.name}
          </MenuItem>
        );
      });
    } else if (!isLoadingFailed) {
      return (
        <MenuItem value="">
          <Loader size={1} topBottomPadding={"0"} leftRightPadding={"1em"} />
          {translations.common.texts.loading()}…
        </MenuItem>
      );
    } else {
      return <MenuItem value="">{translations.common.texts.loadingFailed()}</MenuItem>;
    }
  }

  return (
    <>
      <InputLabel id={`label-${props.id}`}>{props.label}</InputLabel>
      <Select
        id={props.id}
        labelId={`label-${props.id}`}
        value={!isLoading ? props.selectedId?.toString() ?? (props.enableSelectingAll ? allItems.toString() : "") : ""}
        onChange={selectItem}
        displayEmpty={false}
        style={{ width: "100%" }}
        size="small"
        disabled={props.disabled ?? false}
      >
        {renderItemList()}
      </Select>
      <ValidationError>{props.validationErrorMessage}</ValidationError>
    </>
  );
};
