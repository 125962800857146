import React, { HTMLAttributes } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { Mode } from "./index";
import { FilePickerButton } from "./file-picker-button";

interface Props {
  mode: Mode;
  fileName?: string;
  fileType?: string;
  isLoading: boolean;
  disabled?: boolean;
  textFieldWidth?: number;
  buttonWidth?: number;
  selectFile: (file: File) => Promise<void>;
}

export const FileUploadInput: React.FC<Props & HTMLAttributes<HTMLElement>> = (props) => {
  if (props.mode === Mode.ViewExisting) {
    return <Typography id={props.id}>{props.fileName}</Typography>;
  } else {
    return (
      <Grid container direction="row" justifyContent="space-between" justifyItems="center">
        <Grid item xs={props.textFieldWidth ?? 9.75}>
          <TextField
            value={props.fileName ?? ""}
            disabled={props.disabled ?? false}
            size="small"
            fullWidth
            contentEditable={false}
            id={props.id}
          />
        </Grid>
        <Grid item xs={props.buttonWidth ?? 2}>
          <FilePickerButton
            fileType={props.fileType}
            selectFile={props.selectFile}
            isLoading={props.isLoading}
            disabled={props.disabled ?? false}
            id={`${props.id}_filePickerButton`}
          />
        </Grid>
      </Grid>
    );
  }
};
