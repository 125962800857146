import { BaseObservable } from "../observer/BaseObservable";
import { prefixlessId } from "./Utils";
export class Organization extends BaseObservable {
    constructor(params) {
        var _a;
        super();
        this.id = params.id;
        this.name = params.name;
        this.parentId = params.parentOrganizationId;
        this.maxSecureCode = (_a = params.maxSecureCode) !== null && _a !== void 0 ? _a : 0;
    }
    static isParentOrEqualOrganizationId(organizationId, maybeParentOrganizationId) {
        const a = this.toComparableFormat(organizationId);
        const b = this.toComparableFormat(maybeParentOrganizationId);
        return a.startsWith(b);
    }
    static toComparableFormat(id) {
        return prefixlessId(id).endsWith(":") ? id : `${id}:`;
    }
    getId() {
        return this.id;
    }
    getName() {
        return this.name;
    }
    getParentOrganizationId() {
        return this.parentId;
    }
    getMaxSecureCode() {
        return this.maxSecureCode;
    }
}
