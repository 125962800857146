import {
  AuthWrapper,
  AwsConfiguration,
  BackendFactory,
  Maybe,
  OrderApiClient,
  OrderSearchResult,
  OrganizationDto,
} from "@sade/data-access";
import React, { JSX, useCallback, useEffect, useRef, useState } from "react";
import { NewOrderDialog } from "./components/new-order-dialog";
import { OrdersList } from "./components/orders-list";
import { useParams } from "react-router-dom";
import { OrderDetails } from "./components/order-details";
import { OrderRow } from "./components/OrderRow";
import { translations } from "../../generated/translationHelper";
import { useNotification } from "../ui/notification";
import { OrdersListInputs } from "./components/orders-list-inputs";
import { DateRange } from "../ui/DateRange";
import { treatLocalTimeAsUtcTime } from "../../utils/TimeUtils";

class ResultIsStale extends Error {}

export const OrdersListView: React.FC = () => {
  const organizationBackend = useRef(BackendFactory.getOrganizationBackend());
  const awsConfig = useRef(AwsConfiguration.getConfiguration());
  const apiClient = useRef(new OrderApiClient(awsConfig.current.ApiGateway.RootUrlOrders, AuthWrapper.getAccessToken));
  const refreshAbortion = useRef<AbortController | undefined>(undefined);
  const searchAbortion = useRef<AbortController | undefined>(undefined);

  const [isNewOrderDialogOpen, setIsNewOrderDialogOpen] = useState(false);
  const [organizationId, setOrganizationId] = useState<string | undefined>(undefined);
  const [dateRange, setDateRange] = useState<DateRange>({
    beginning: new Date(treatLocalTimeAsUtcTime(new Date()).setUTCHours(0, 0, 0, 0)),
  });
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [searchResults, setSearchResults] = useState<OrderSearchResult[] | undefined>(undefined);
  const [rows, setRows] = useState<OrderRow[] | undefined>(undefined);
  const [currentSlowOperationsCount, setCurrentSlowOperationsCount] = useState(0);

  const displayNotification = useNotification();
  const { orderId } = useParams<{ orderId?: string }>();

  function incrementSlowOperationsCount(): void {
    setCurrentSlowOperationsCount((previousValue) => {
      if (previousValue < 0) {
        console.debug("Incrementing order list slow operation count from below zero, that should never happen");
      }
      return previousValue + 1;
    });
  }
  function decrementSlowOperationsCount(): void {
    setCurrentSlowOperationsCount((previousValue) => {
      if (previousValue <= 0) {
        console.debug("Decrementing order list slow operation count to below zero, that should never happen");
      }
      return previousValue - 1;
    });
  }

  function resultsAreStillRelevant(abortion: AbortController): boolean {
    return !abortion.signal.aborted || !(abortion.signal.reason instanceof ResultIsStale);
  }

  const refreshOrdersList = useCallback(
    async (createdAtRange: DateRange): Promise<void> => {
      incrementSlowOperationsCount();
      const abortion = new AbortController();
      try {
        refreshAbortion.current?.abort(new ResultIsStale());
        refreshAbortion.current = abortion;

        const user = await organizationBackend.current.getCurrentUser();
        if (user !== undefined) {
          const orderSummaries = await apiClient.current.getOrdersForOrganization(
            {
              organizationId: user.getHomeOrganizationId(),
              beginning: createdAtRange.beginning,
              end: createdAtRange.end,
            },
            refreshAbortion.current
          );
          const newRows: OrderRow[] = orderSummaries.map((o) => {
            return {
              id: o.id,
              createdAt: o.createdAt,
              purchaseOrder: o.purchaseOrder,
              organizationId: o.organizationId,
              organizationName: o.organizationName,
              status: o.status,
              credentialCountTotal: o.credentialCountTotal,
              programmerName: o.programmerName,
              credentialMatchesSearch: false,
              actions: o.downloads
                ? {
                    downloadUrl: o.downloads.url,
                    expiresAt: new Date(new Date().valueOf() + o.downloads.validityDurationMilliseconds),
                  }
                : undefined,
            };
          });

          if (resultsAreStillRelevant(abortion)) {
            setRows(([] as OrderRow[]).concat(...newRows));
          }
        }
      } catch (e) {
        if (resultsAreStillRelevant(abortion)) {
          setRows(undefined);
          const message = e instanceof Error ? e.message : translations.orders.texts.unknownError();
          displayNotification({
            title: translations.orders.texts.listingOrdersFailed(),
            message: message,
            variant: "error",
          });
        }
      } finally {
        if (!resultsAreStillRelevant(abortion)) {
          console.debug("Stale order list refresh result discarded");
        }

        decrementSlowOperationsCount();
      }
    },
    [displayNotification]
  );

  const searchOrders = useCallback(
    async (searchTerm: string, abortion: AbortController): Promise<OrderSearchResult[]> => {
      try {
        incrementSlowOperationsCount();

        const results = await apiClient.current.searchOrders(
          { query: searchTerm, beginning: dateRange.beginning, end: dateRange.end },
          abortion
        );

        return results.results;
      } finally {
        decrementSlowOperationsCount();
      }
    },
    [dateRange]
  );

  useEffect((): void => {
    refreshOrdersList(dateRange).catch((e) => console.error("Error when auto-refreshing list of orders", e));
  }, [refreshOrdersList, dateRange]);

  const openNewOrderDialog = useCallback(() => setIsNewOrderDialogOpen(true), []);
  const closeNewOrderDialog = useCallback(() => setIsNewOrderDialogOpen(false), []);

  const selectOrganization = useCallback(async (organization: OrganizationDto | undefined): Promise<void> => {
    setOrganizationId(organization?.id);
  }, []);

  const filterBySearchTerm = useCallback(
    async (term: string | undefined): Promise<void> => {
      const abortion = new AbortController();
      try {
        setSearchTerm(term);
        searchAbortion.current?.abort(new ResultIsStale());
        searchAbortion.current = abortion;

        if (term !== undefined) {
          const foundOrders = await searchOrders(term, abortion);

          if (resultsAreStillRelevant(abortion)) {
            setSearchResults(foundOrders);
          }
        } else {
          setSearchResults(undefined);
        }
      } catch (e) {
        if (resultsAreStillRelevant(abortion)) {
          setSearchResults([]);
          const message = e instanceof Error ? e.message : translations.orders.texts.unknownError();
          displayNotification({
            title: translations.orders.texts.searchOrdersFailed(),
            message: message,
            variant: "error",
          });
        }
      } finally {
        if (!resultsAreStillRelevant(abortion)) {
          console.debug("Stale orders search result discarded");
        }
      }
    },
    [displayNotification, searchOrders]
  );

  const filterByDateRange = useCallback(async (range: DateRange): Promise<void> => {
    setDateRange(range);
  }, []);

  function renderNewOrderDialog(): Maybe<JSX.Element> {
    if (isNewOrderDialogOpen) {
      return (
        <NewOrderDialog
          title="Create Order"
          close={closeNewOrderDialog}
          closeAndRefresh={(): void => {
            closeNewOrderDialog();
            refreshOrdersList(dateRange).catch((e) =>
              console.error("Error auto-refreshing list of orders after order creation", e)
            );
          }}
        />
      );
    }
  }

  function renderView(): JSX.Element {
    if (orderId === undefined) {
      return (
        <>
          <OrdersListInputs
            dateRange={dateRange}
            searchTerm={searchTerm}
            selectedOrganizationId={organizationId}
            openNewOrderDialog={openNewOrderDialog}
            selectOrganization={selectOrganization}
            filterBySearchTerm={filterBySearchTerm}
            filterByDateRange={filterByDateRange}
            refresh={refreshOrdersList}
          />
          <OrdersList
            rows={rows ?? []}
            organizationId={organizationId}
            searchResults={searchResults}
            searchTerm={searchTerm}
            isLoading={currentSlowOperationsCount > 0}
          />
        </>
      );
    } else {
      return <OrderDetails orderId={Number(orderId)} key={orderId} />;
    }
  }

  return (
    <>
      {renderView()}
      {renderNewOrderDialog()}
    </>
  );
};
