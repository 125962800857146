import React, { JSX, useEffect, useRef, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { OrderActions, OrderRow } from "../OrderRow";
import { FileDownload } from "@mui/icons-material";
import { translations } from "../../../../generated/translationHelper";
import Loader from "../../../ui/loader";

export interface Props {
  order: OrderRow;
  getFreshDownloadLink: (order: OrderRow) => Promise<OrderActions | undefined>;
}

export const DownloadButton: React.FC<Props> = (props) => {
  // The Order this component is given in the props might or might not have a URL to download a file with.
  // If it does not have it, this component renders as empty element.
  // If it does have it, this component renders as a download button.
  // The link might be expired by the time the user clicks on the button, so we can't render the URL
  // in a static <a> element. Instead, besides the download button we render a hidden <a> that initially
  // has undefined href. When the user clicks on the download button, we check the expiration time, and if
  // it has expired we fetch a fresh URL from the server.
  // Then we change the <a> element href to point to the now-guaranteed-not-expired URL, and "click"
  // it, and then set it back to undefined in order to avoid accidentally clicking it again.

  const { order } = props;

  // We need a ref to the hidden <a> element to be able to call .click() on it when the time comes
  const downloadAnchor = useRef<HTMLAnchorElement>(null);
  const [downloadUrl, setDownloadUrl] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (downloadUrl !== undefined) {
      downloadAnchor.current?.click();
      setDownloadUrl(undefined);
    }
  }, [downloadUrl, downloadAnchor, setDownloadUrl]);

  async function onClick(event: React.MouseEvent<HTMLElement>): Promise<void> {
    event.stopPropagation();
    try {
      setIsLoading(true);
      if (order.actions && order.actions.expiresAt > new Date()) {
        setDownloadUrl(order.actions.downloadUrl);
      } else {
        order.actions = await props.getFreshDownloadLink(order);
        if (order.actions) {
          setDownloadUrl(order.actions.downloadUrl);
        }
      }
    } finally {
      setIsLoading(false);
    }
  }

  function anchorOnClick(event: React.MouseEvent<HTMLElement>): void {
    event.stopPropagation();
  }

  function renderVisuals(): JSX.Element {
    if (!isLoading) {
      return (
        <Tooltip
          placement="left"
          title={translations.orders.buttons.downloadOrder({ purchaseOrder: order.purchaseOrder })}
        >
          <IconButton style={{ cursor: "pointer" }} onClick={onClick}>
            <FileDownload style={{ cursor: "pointer" }} />
          </IconButton>
        </Tooltip>
      );
    } else {
      return <Loader size={1} leftRightPadding={"8px"} />;
    }
  }

  if (order.actions?.downloadUrl) {
    return (
      <>
        {renderVisuals()}
        <a hidden href={downloadUrl} ref={downloadAnchor} onClick={anchorOnClick}></a>
      </>
    );
  } else {
    return <></>;
  }
};
