import React, { Component, ReactNode } from "react";
import { Breadcrumbs } from "@mui/material";
import { Maybe, Organization, OrganizationObserver } from "@sade/data-access";
import DropdownSelection from "../dropdown-selection";
import { translations } from "../../../generated/translationHelper";
import { ParentLinkRenderer } from "./parent-link-renderer";

interface Props {
  rootOrganization: Organization;
  organizationSelected: (organization: Organization) => void;
}

interface State {
  breadCrumbs: Organization[];
  nextChildren?: Organization[];
}

function tail<T>(array: T[]): Maybe<T> {
  return array.length === 0 ? undefined : array[array.length - 1];
}

export default class OrganizationSelector extends Component<Props, State> implements OrganizationObserver {
  public constructor(props: Props) {
    super(props);
    this.state = {
      breadCrumbs: [],
    };
  }

  private static async getNewChildren(organizations: Organization[]): Promise<Organization[]> {
    const last = tail(organizations);
    return last ? await last.getDirectChildOrganizations() : [];
  }

  public async componentDidMount(): Promise<void> {
    await this.updateCrumbs([this.props.rootOrganization]);
  }

  public componentWillUnmount(): void {
    this.state.breadCrumbs.forEach((organization) => organization.removeObserver(this));
    this.state.nextChildren?.forEach((organization) => organization.removeObserver(this));
  }

  public onChildrenChange(children: Organization[], organization: Organization): void {
    // check if the children of the last organization in the path changed
    // deletions _on_ the path will be handled by onDelete callback
    if (organization === tail(this.state.breadCrumbs)) {
      this.swapChildren(children);
    }
  }

  public async onDeleted(organization: Organization): Promise<void> {
    const deleteIndex = this.state.breadCrumbs.findIndex((crumb) => crumb.getId() === organization.getId());

    // TODO what should happen if deleteIndex === 0
    if (deleteIndex > 0) {
      const nextPath = this.state.breadCrumbs.slice(0, deleteIndex);
      await this.updateCrumbs(nextPath);
    }
  }

  public onPropertiesChange(organization: Organization): void {
    console.log(`OrganizationSelector: organization ${organization.getName()} updated its properties!`);

    if (this.state.breadCrumbs.includes(organization)) {
      console.log("OrganizationSelector updating path");
      this.setState({
        breadCrumbs: [...this.state.breadCrumbs],
      });
    } else if (this.state.nextChildren?.includes(organization)) {
      console.log("OrganizationSelector updating children");
      this.setState({
        nextChildren: [...this.state.nextChildren!],
      });
    }
  }

  private async handleOrganizationSelection(index: number, organization: Organization): Promise<void> {
    const newCrumbs = this.state.breadCrumbs.slice(0, index + 1);
    newCrumbs[newCrumbs.length - 1] = organization;
    return this.updateCrumbs(newCrumbs);
  }

  private handleChildSelection = async (index?: number): Promise<void> => {
    if (index == null) {
      return;
    }
    const child = this.state.nextChildren?.[index];

    if (!child) {
      console.error("Selected organization from a set of none");
      return;
    }

    const newCrumbs = this.state.breadCrumbs.concat(child);
    return this.updateCrumbs(newCrumbs);
  };

  private async updateCrumbs(organizations: Organization[]): Promise<void> {
    this.state.breadCrumbs.forEach((organization) => organization.removeObserver(this));
    organizations.forEach((organization) => organization.addObserver(this));
    this.setState({
      breadCrumbs: organizations,
    });
    const nextChildren = await OrganizationSelector.getNewChildren(organizations);
    this.swapChildren(nextChildren);
    this.notifyCurrentOrganizationSelection();
  }

  private swapChildren(nextChildren: Organization[]): void {
    this.state.nextChildren?.forEach((organization) => {
      if (!this.state.breadCrumbs.includes(organization)) {
        organization.removeObserver(this);
      }
    });
    nextChildren.forEach((organization) => organization.addObserver(this));
    nextChildren.sort((a, b) => a.getName().localeCompare(b.getName()));
    this.setState({ nextChildren });
  }

  private notifyCurrentOrganizationSelection(): void {
    this.props.organizationSelected(this.state.breadCrumbs[this.state.breadCrumbs.length - 1]);
  }

  private renderOrganizationLinks(): ReactNode {
    if (!this.state.breadCrumbs) {
      return;
    }

    const ret: ReactNode[] = [];

    for (let level = 0; level < this.state.breadCrumbs.length; level++) {
      const organization = this.state.breadCrumbs[level];
      ret.push(
        <ParentLinkRenderer
          key={organization.getParentOrganizationId()}
          currentOrganization={organization}
          onOrganizationSelected={(newOrganization): void =>
            void this.handleOrganizationSelection(level, newOrganization)
          }
          data_test_id={level.toString()}
        />
      );
    }

    return ret;
  }

  private renderChildSelector(): ReactNode {
    if (!this.state.nextChildren) {
      return;
    }
    return (
      <DropdownSelection
        variant="standard"
        onSelect={this.handleChildSelection}
        selectionList={this.state.nextChildren.map((child) => ({ key: child.getId(), label: child.getName() }))}
        emptySelectionItem={translations.admin.inputs.selectChild()}
        disabled={this.state.nextChildren.length === 0}
        aria-label="organization-dropdown"
      />
    );
  }

  public render(): ReactNode {
    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {this.renderOrganizationLinks()}
        {this.renderChildSelector()}
      </Breadcrumbs>
    );
  }
}
