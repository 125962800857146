import { addDays, addMonths, format, parse, startOfDay } from "date-fns";
import { isTimestamp, Maybe, Timestamp } from "@sade/data-access";
import { isValidLanguageCode, SupportedLanguageCode } from "../locales/localizationUtils";
import { enUS, fi } from "date-fns/locale";
import { getLanguage } from "../locales/localisator";

const HOUR_AS_MILLISECONDS: number = 60 * 60 * 1000;
const MINUTE_AS_MILLISECONDS: number = 60 * 1000;
const SUPPORTED_LOCALES: Record<SupportedLanguageCode, Locale> = {
  en: enUS,
  fi: fi,
};

type StopMethod = () => void;

export enum TimeUnit {
  Month = "month",
}

export type FormattedDateString = string;

function getLocale(): Maybe<Locale> {
  const langCode = getLanguage();
  if (langCode && isValidLanguageCode(langCode)) return SUPPORTED_LOCALES[langCode];
}

export function getStartOfXDaysAgo(days: Timestamp): Timestamp {
  const date = new Date(days);
  return startOfDay(addDays(date, -1 * days)).getTime();
}

export function getTimestampXDaysAgo(days: Timestamp): Timestamp {
  const date = new Date();
  date.setDate(date.getDate() - days);
  return date.getTime();
}

export function getTimestampXUnitsAgo(fromTimestamp: Timestamp, num: number, unit: TimeUnit): Timestamp {
  const date = new Date(fromTimestamp);
  switch (unit) {
    case TimeUnit.Month:
      return addMonths(date, -1 * num).getTime();
    default:
      throw new Error(`Unknown time unit ${unit}`);
  }
}

export function millisecondsToHoursAndMinutes(milliseconds: number): string {
  const hours: number = Math.floor(milliseconds / HOUR_AS_MILLISECONDS);
  const minutes: number = Math.floor((milliseconds % HOUR_AS_MILLISECONDS) / MINUTE_AS_MILLISECONDS);

  if (hours > 0) {
    return hours + " h " + minutes + " min";
  }
  return minutes + " min";
}

export function startInterval(callback: (stop: StopMethod) => void, interval: number): StopMethod {
  let id = -1;
  const stop = (): void => window.clearInterval(id);
  id = window.setInterval(callback, interval, stop);
  return stop;
}

export enum DateTimeFormatTarget {
  ChartTimeAxis = 0,
  ChartTooltip = 1,
  ShadowUpdate = 2,
  StatusTable = 3,
  EventsTable = 4,
  DateTimePicker = 5,
  DayMonthYear = 6,
}

export function getDateTimeFormat(target: DateTimeFormatTarget): string {
  switch (target) {
    case DateTimeFormatTarget.ChartTimeAxis:
      return "d/M HH:mm";
    case DateTimeFormatTarget.ChartTooltip:
      return "d/M/yyyy HH:mm:ss";
    case DateTimeFormatTarget.StatusTable:
    case DateTimeFormatTarget.EventsTable:
      return "dd/MM/yyyy HH:mm:ss";
    case DateTimeFormatTarget.ShadowUpdate:
      return "d/M/y HH:mm:ss";
    case DateTimeFormatTarget.DateTimePicker:
      return "dd/MM/yyyy HH:mm";
    case DateTimeFormatTarget.DayMonthYear:
      return "dd/MM/yyyy";
    default:
      throw new Error(`Unknown datetime format id ${target}`);
  }
}

export function convertDateToString(date: Date, formatTarget: DateTimeFormatTarget): FormattedDateString {
  return format(date, getDateTimeFormat(formatTarget), { locale: getLocale() });
}

export function convertTimestampToString(
  timestamp: Timestamp,
  formatTarget: DateTimeFormatTarget
): FormattedDateString {
  if (!isTimestamp(timestamp)) throw new Error(`Timestamp ${timestamp} was not in a millisecond format`);
  return convertDateToString(new Date(timestamp), formatTarget);
}

export function convertStringToTimestamp(
  formattedDate: FormattedDateString,
  inputFormat: DateTimeFormatTarget
): Timestamp {
  return parse(formattedDate, getDateTimeFormat(inputFormat), new Date(), { locale: getLocale() }).getTime();
}

/**
 * Transforms the given local Date to the same wall clock time in UTC time.
 * E.g. if current local offset is +3 and the given value is 2023/11/30 22:00:00,
 * this will return UTC time 2023/11/30 22:00:00, meaning local time 2023/12/01 01:00:00.
 */
export function treatLocalTimeAsUtcTime(value: Date): Date {
  const minutesToMillisecondsMultiplier = 60 * 1000;
  return new Date(value.getTime() + -value.getTimezoneOffset() * minutesToMillisecondsMultiplier);
}
