export class TypedAppSyncClient {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(client) {
        this.client = client;
    }
    async query(document, variables, options = {}) {
        return this.client.query(Object.assign({ query: document, variables }, options));
    }
    async mutate(document, variables, options = {}) {
        const result = await this.client.mutate(Object.assign({ mutation: document, variables }, options));
        return result;
    }
    subscribe(document, variables, options = {}) {
        return this.client.subscribe(Object.assign({ query: document, variables }, options));
    }
}
