import { AWSBackend } from "./backend/AWSBackend";
import { AWSOrganizationBackend } from "./organization/AWSOrganizationBackend";
export class BackendFactory {
    static getBackend() {
        if (!BackendFactory.genericBackend) {
            BackendFactory.genericBackend = new AWSBackend();
        }
        return BackendFactory.genericBackend;
    }
    static getOrganizationBackend() {
        if (!BackendFactory.organizationBackend) {
            BackendFactory.organizationBackend = new AWSOrganizationBackend();
        }
        return BackendFactory.organizationBackend;
    }
}
