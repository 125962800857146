import { Button, Grid, InputLabel, TextField, Typography } from "@mui/material";
import { translations } from "../../../../generated/translationHelper";
import React, { useCallback, useState } from "react";
import { Organization } from "@sade/data-access";
import accessControlled from "../../../access-control/access-controlled";
import Loader from "../../../ui/loader";
import { ValidationError } from "../../../ui/validation-error";
import { isInteger } from "../../../../utils/StringUtils";
import { useNotification } from "../../../ui/notification";

export interface Props {
  organization: Organization;
}

const ACTextField = accessControlled(TextField, ["organizationsUpdate"]);

export const OrganizationDetails: React.FC<Props> = (props: Props) => {
  const { organization } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [organizationName, setOrganizationName] = useState(props.organization.getName());
  const [organizationMaxSecureCode, setOrganizationMaxSecureCode] = useState(
    props.organization.getMaxSecureCode().toString()
  );
  const [maxSecureCodeValidationError, setMaxSecureCodeValidationError] = useState<string | undefined>(undefined);

  const displayNotification = useNotification();

  const validateMaxSecureCode = useCallback((value: string): boolean => {
    if (isInteger(value)) {
      const min = 0;
      const max = 65535;
      const maxSecureCodeNumber = parseInt(value);

      if (maxSecureCodeNumber >= min && maxSecureCodeNumber <= max) {
        return true;
      } else {
        setMaxSecureCodeValidationError(translations.admin.texts.maxSecureCodeMustBeInRange({ min, max }));
        return false;
      }
    } else {
      setMaxSecureCodeValidationError(translations.admin.texts.maxSecureCodeMustBeNumber());
      return false;
    }
  }, []);
  const validateAndSubmit = useCallback(async (): Promise<void> => {
    try {
      setIsLoading(true);
      if (validateMaxSecureCode(organizationMaxSecureCode)) {
        await organization.changeProps(organizationName, parseInt(organizationMaxSecureCode));
        setOrganizationName(organization.getName());
        setOrganizationMaxSecureCode(organization.getMaxSecureCode().toString());

        displayNotification({
          title: translations.admin.texts.organizationEditingSucceededTitle(),
          message: translations.admin.texts.organizationEditingSucceededMessage({
            organizationName: organization.getName(),
          }),
          variant: "success",
        });
      }
    } catch (e) {
      const errorDetails = e instanceof Error ? e.message : JSON.stringify(e);
      displayNotification({
        title: translations.admin.texts.organizationEditingFailedTitle(),
        message: translations.admin.texts.organizationEditingFailedMessage({
          organizationName: organization.getName(),
          message: errorDetails,
        }),
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }, [organization, organizationName, organizationMaxSecureCode, validateMaxSecureCode, displayNotification]);

  const cancel = useCallback((): void => {
    setOrganizationName(organization.getName());
    setOrganizationMaxSecureCode(organization.getMaxSecureCode().toString());
  }, [organization]);

  return (
    <Grid container direction="column" rowGap={3} sx={{ padding: "0 24px" }}>
      <Grid item container direction="row" alignItems="center" sx={{ marginTop: "24px" }}>
        <Typography variant="h6">{translations.admin.texts.organizationDetails()}</Typography>
      </Grid>
      <Grid item container direction="row">
        <Grid item container direction="column" rowGap={1} xs={12} sm={4}>
          <Grid item>
            <InputLabel id="label-organizationName">{translations.admin.inputs.organisationName()}</InputLabel>
            <ACTextField
              value={organizationName}
              contextOrganization={organization}
              accessDeniedProps={{ disabled: true }}
              onChange={(event): void => {
                setOrganizationName(event.target.value ?? "");
              }}
              variant="outlined"
              size="small"
              fullWidth
              id="organizationName"
            />
            <ValidationError>{"\u00a0"}</ValidationError>
          </Grid>
          <Grid item>
            <InputLabel id="label-organizationMaxSecureCode">{translations.admin.inputs.maxSecureCode()}</InputLabel>
            <ACTextField
              value={organizationMaxSecureCode}
              contextOrganization={organization}
              accessDeniedProps={{ disabled: true }}
              onChange={(event): void => {
                setOrganizationMaxSecureCode(event.target.value ?? "");
                setMaxSecureCodeValidationError(undefined);
              }}
              variant="outlined"
              size="small"
              fullWidth
              id="organizationMaxSecureCode"
            />
            <ValidationError>{maxSecureCodeValidationError ?? "\u00a0"}</ValidationError>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container direction="row" justifyContent="flex-start" columnGap={1} sx={{ paddingTop: "20px" }}>
        <Button variant="contained" color="primary" onClick={validateAndSubmit} disabled={isLoading}>
          <Loader
            size={1}
            topBottomPadding={"0"}
            leftRightPadding={"0"}
            hidden={!isLoading}
            styles={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
          />
          <span style={{ visibility: isLoading ? "hidden" : "visible" }}>{translations.common.buttons.save()}</span>
        </Button>
        <Button variant="outlined" color="primary" onClick={cancel}>
          {translations.common.buttons.cancel()}
        </Button>
      </Grid>
    </Grid>
  );
};
