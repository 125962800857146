export class AwsConfiguration {
    static configure(awsConfig) {
        AwsConfiguration.awsConfig = awsConfig;
    }
    static getConfiguration() {
        if (!AwsConfiguration.awsConfig) {
            throw new Error("AWS not configured. Have you called AwsConfiguration.configure from data access module?");
        }
        return AwsConfiguration.awsConfig;
    }
}
