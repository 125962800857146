import { Button, FormLabel, Grid, Skeleton, Typography } from "@mui/material";
import React, { useState } from "react";
import { translations } from "../../generated/translationHelper";
import { Flip } from "@mui/icons-material";
import Loader from "./loader";

enum CardSide {
  Front,
  Back,
}

export interface Props {
  backImage?: string;
  frontImage?: string;
  hasLoadingAnimation: boolean;
  showLoaderOverlay?: boolean;
  width: number;
  renderHeading?: (headingText: string) => React.JSX.Element;
}

export const CardImageDisplay: React.FC<Props> = (props) => {
  const [cardSide, setCardSide] = useState(CardSide.Front);
  const showLoaderOverlay = props.showLoaderOverlay && cardSide === CardSide.Front;

  const headingText = translations.orders.texts.card();
  const heading = props.renderHeading ? props.renderHeading(headingText) : <FormLabel>{headingText}</FormLabel>;

  const getCardImage = (): string | undefined => (cardSide === CardSide.Front ? props.frontImage : props.backImage);

  return (
    <Grid item md container direction="column" width={props.width} margin="0 auto">
      <Grid item container spacing={0}>
        {heading}
        <div style={{ flexGrow: 1 }} />
        <Button
          startIcon={<Flip></Flip>}
          variant="text"
          color="primary"
          onClick={(): void => setCardSide(cardSide === CardSide.Front ? CardSide.Back : CardSide.Front)}
        >
          {translations.orders.buttons.flip()}
        </Button>
      </Grid>
      <Grid item maxHeight={props.width / 1.5} position="relative">
        {getCardImage() ? (
          <>
            <img
              width={props.width}
              height={props.width / 1.5}
              src={"data:image/png;base64," + getCardImage()}
              style={{
                borderRadius: 16,
                boxShadow: "0px 3px 14px 2px #0000001F, 0px 8px 10px 1px #00000024, 0px 5px 5px -3px #00000033",
                opacity: showLoaderOverlay ? "30%" : "100%",
              }}
            />
            <Loader
              size="medium"
              hidden={!showLoaderOverlay}
              topBottomPadding={"0"}
              leftRightPadding={"0"}
              styles={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
            />
          </>
        ) : (
          <Skeleton
            variant="rectangular"
            width={props.width}
            height={props.width / 1.5}
            animation={props.hasLoadingAnimation ? "wave" : false}
          />
        )}
      </Grid>
      <Grid item>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: 12,
            fontFamily: "Inter, Roboto",
            letterSpacing: 0.4,
            marginTop: 1,
          }}
          color="text.secondary"
          align="center"
        >
          {cardSide === CardSide.Front ? translations.orders.texts.front() : translations.orders.texts.back()}
        </Typography>
      </Grid>
    </Grid>
  );
};
