import { Grid, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MuiDialogTitle from "@mui/material/DialogTitle";
import React from "react";
import { styled } from "@mui/material/styles";

export interface DialogTitleProps {
  children: React.ReactNode;
  onClose: () => void;
}

function UnstyledDialogTitle(props: DialogTitleProps): JSX.Element {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle {...other}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" columnGap={3}>
        <Typography variant="h5">{children}</Typography>
        <IconButton aria-label="close" onClick={onClose} sx={{ verticalAlign: "textTop" }}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </MuiDialogTitle>
  );
}

export const DialogTitle = styled(UnstyledDialogTitle)<DialogTitleProps>(({ theme }) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
}));
