import React, { ChangeEvent, Component } from "react";
import { Button, Dialog, Grid, IconButton, TextField, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { translations } from "../../../generated/translationHelper";
import { AuthWrapper } from "@sade/data-access";
import ConfirmCodeDialog from "./confirm-code-dialog";

interface Props {
  email?: string;
  dialogOpen: boolean;
  onDialogClose: (saveSuccessful?: boolean) => void;
}

interface State {
  savedState?: boolean;
  newEmail?: string;
  confirmRequest?: {
    title: string;
    msg: string;
    confirm: (code: string) => Promise<void>;
  };
  confirmDialogOpen: boolean;
}

export default class EditEmailDialog extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      confirmDialogOpen: false,
    };
  }

  private updateEmail = async (): Promise<void> => {
    const { newEmail } = this.state;
    if (!newEmail) return;
    try {
      this.setState({ confirmDialogOpen: true });
      const confirmCodeCallback = await AuthWrapper.setEmail(newEmail);
      this.setState({
        confirmRequest: {
          title: translations.user.texts.confirmEmailTitle(),
          msg: translations.user.texts.confirmEmailMsg({ email: newEmail }),
          confirm: confirmCodeCallback,
        },
      });
    } catch (error) {
      console.error("handleEmailSubmit", error);
    }
  };

  private handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const input = event.target.value;
    this.setState({
      newEmail: input,
    });
  };

  private handleDialogClose = (confirmSuccessful?: boolean): void => {
    this.setState({
      confirmDialogOpen: false,
    });
    this.props.onDialogClose(confirmSuccessful);
  };

  private confirmationDialog = (): React.ReactNode => {
    const { confirmRequest, newEmail } = this.state;
    if (!confirmRequest || !newEmail) return;
    return (
      <ConfirmCodeDialog
        dialogOpen={this.state.confirmDialogOpen}
        title={confirmRequest.title}
        msg={confirmRequest.msg}
        onConfirm={confirmRequest.confirm}
        onDialogClose={this.handleDialogClose}
        onResend={this.updateEmail}
      />
    );
  };

  public render(): React.ReactNode {
    const { newEmail } = this.state;
    const { email } = this.props;
    return (
      <Dialog open={this.props.dialogOpen} fullWidth>
        {this.confirmationDialog()}
        <Grid container direction="column" className="edit-dialog-container">
          <Grid item>
            <Typography variant="h5">{translations.user.texts.editEmail()}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{translations.user.texts.editEmailInstructions()}</Typography>
          </Grid>
          <Grid item>
            <TextField
              label={translations.user.inputs.email().toUpperCase()}
              fullWidth
              value={newEmail ?? ""}
              variant="outlined"
              size="small"
              id="new-email-field"
              onChange={this.handleChange}
            ></TextField>
          </Grid>
          <Grid item container xs direction="row" justifyContent="flex-end" className="dialog-item-container">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                className="dialog-button"
                onClick={(): void => this.props.onDialogClose()}
              >
                {translations.common.buttons.cancel().toUpperCase()}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={!newEmail || !newEmail.includes("@") || newEmail === email}
                className="dialog-button"
                onClick={this.updateEmail}
              >
                {translations.common.buttons.update().toUpperCase()}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <IconButton onClick={(): void => this.props.onDialogClose()} className="exit-button">
          <Close color="primary" />
        </IconButton>
      </Dialog>
    );
  }
}
