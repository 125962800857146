/**
 * Constructs a promise wait list - when multiple 'clients' require the same async information, this
 * tool can be used to organize those requests into a waiting list, letting one of the requesters resolve
 * the async request and let others then receive the same value.
 *
 * This works well in combination with a Map<T, V> type custom cache.
 *
 * This could also be replaced with a de-bouncer, but this offers more immediate feedback.
 */
export class PromiseWaitList {
    constructor(name) {
        this.name = name;
        this.waitList = [];
    }
    async get() {
        if (this.value !== undefined) {
            return this.value;
        }
        else {
            return new Promise((resolve, reject) => {
                this.waitList.push((error) => {
                    if (error) {
                        reject(error);
                    }
                    else {
                        resolve(this.value);
                    }
                });
            });
        }
    }
    set(value) {
        this.value = value;
        const notifyList = [...this.waitList];
        console.log(`WaitList${this.name ? "(" + this.name + ")" : ""} ${notifyList.length + 1} waiting, notifying success`);
        this.waitList = [];
        notifyList.forEach((method) => method());
    }
    fail(error) {
        const notifyList = [...this.waitList];
        console.log(`WaitList${this.name ? "(" + this.name + ")" : ""} ${notifyList.length + 1} waiting, notifying failure`);
        this.waitList = [];
        notifyList.forEach((method) => method(error));
    }
}
