import { Done, ErrorOutline, Loop } from "@mui/icons-material";
import { Chip, SxProps, Theme } from "@mui/material";
import { OrderStatus } from "@sade/data-access";
import React from "react";
import Loader from "../../ui/loader";

interface Props {
  orderStatus: OrderStatus | undefined;
  sx?: SxProps<Theme>;
}

export const OrderStatusChip: React.FC<Props> = (props: Props) => {
  function getColor(): "primary" | "info" | "success" | "default" {
    switch (props.orderStatus) {
      case OrderStatus.Completed:
        return "success";
      case OrderStatus.InProgress:
        return "info";
      case OrderStatus.Failed:
        return "primary";
    }
    return "default";
  }

  function getIcon(): JSX.Element {
    switch (props.orderStatus) {
      case OrderStatus.Completed:
        return <Done />;
      case OrderStatus.InProgress:
        return <Loop />;
      case OrderStatus.Failed:
        return <ErrorOutline />;
    }
    return <Loader size="small" styles={{ marginLeft: 6 }} />;
  }

  return (
    <Chip
      variant="filled"
      label={props.orderStatus?.replace("_", " ").toLowerCase() ?? "loading"}
      color={getColor()}
      icon={getIcon()}
      sx={{
        color: props.orderStatus ? "white" : "default",
        textTransform: "capitalize",
        ...props.sx,
      }}
    />
  );
};
