import React, { ChangeEvent, Component, Fragment } from "react";
import Loader from "../../ui/loader";
import { Button, TextField, styled } from "@mui/material";
import { AuthenticatedUser, AuthWrapper, ErrorWithCode, isErrorWithCode, Maybe } from "@sade/data-access";
import PasswordField from "../../ui/password-field";
import { isValidEmail } from "../../../utils/validation";
import { translations } from "../../../generated/translationHelper";

interface Props {
  onPasswordResetRequest: (user?: string) => void;
  onSignUpConfirmRequest: (user: AuthenticatedUser) => void;
}

interface State {
  isLoaderVisible: boolean;
  isPasswordVisible: boolean;
  username: string;
  password: string;
  generalError?: string;
  emailError?: string;
  passwordError?: string;
}

const ValidationTextField = styled(TextField)({
  root: {
    "& input:invalid:not(:focus) + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
  },
});

export default class LoginForm extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      isLoaderVisible: false,
      isPasswordVisible: false,
      username: "",
      password: "",
    };
  }

  private renderLoader = (): Maybe<JSX.Element> => {
    if (this.state.isLoaderVisible) {
      return <Loader size={1} leftRightPadding="1rem" />;
    }
  };

  private renderLoaderCounter = (): Maybe<JSX.Element> => {
    if (this.state.isLoaderVisible) {
      return <div style={{ width: "3rem" }} />;
    }
  };

  private handleErrors(
    reason: string,
    message?: string,
    continueOnIgnore?: () => void,
    continueOnInvalidUser?: () => void
  ): void {
    switch (reason) {
      case "UsernameEmptyException":
        if (continueOnInvalidUser) {
          continueOnInvalidUser();
        } else {
          this.setState({ emailError: translations.logIn.texts.usernameCannotBeEmpty() });
        }
        break;
      case "PasswordEmptyException":
        this.setState({ passwordError: translations.logIn.texts.passwordCannotBeEmpty() });
        break;
      case "EmailFormatNotValidException":
        if (continueOnInvalidUser) {
          continueOnInvalidUser();
        } else {
          this.setState({ emailError: translations.logIn.texts.emailFormatNotValid() });
        }
        break;
      case "LimitExceededException":
        this.setState({ emailError: translations.common.texts.tooManyAttempts() });
        break;
      case "NetworkError":
        this.setState({ generalError: translations.common.texts.networkError() });
        break;
      case "NotAuthorizedException":
        if (message?.includes("User password cannot be reset in the current state")) {
          this.setState({ passwordError: translations.logIn.texts.passwordCannotBeResetCurrently() });
        } else {
          this.setState({ passwordError: translations.logIn.texts.incorrectCredentials() });
        }
        break;
      case "UserNotFoundException":
        if (continueOnIgnore) {
          continueOnIgnore();
        } else {
          this.setState({ passwordError: translations.logIn.texts.incorrectCredentials() });
        }
        break;
      case "ResetPasswordNotAuthorizedException":
        this.setState({ passwordError: translations.logIn.texts.passwordCannotBeResetCurrently() });
        break;
      case "InvalidParameterException":
        this.setState({ emailError: translations.logIn.texts.emailNotVerified() });
        break;
      case "CodeMismatchException":
      case "ExpiredCodeException":
      default:
        this.setState({ generalError: translations.common.texts.unableToPerformAction() });
        break;
    }
  }

  private logIn = async (): Promise<void> => {
    try {
      this.resetErrors();
      this.isEmailValid();
      this.isPasswordValid();
      this.setState({ isLoaderVisible: true });
      const user = await AuthWrapper.logIn(this.state.username, this.state.password);

      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        this.props.onSignUpConfirmRequest(user);
      }
    } catch (error) {
      this.setState({ isLoaderVisible: false });
      console.error("logIn", error);
      if (isErrorWithCode(error)) this.handleErrors(error.code);
    }
  };

  private handleForgotPassword = async (): Promise<void> => {
    try {
      this.resetErrors();
      this.isEmailValid();
      this.setState({ isLoaderVisible: true });
      await AuthWrapper.forgotPassword(this.state.username);
      this.props.onPasswordResetRequest(this.state.username);
    } catch (error) {
      if (isErrorWithCode(error)) {
        console.error("handleForgotPassword", error);
        this.handleErrors(
          error.code,
          error.message,
          () => this.props.onPasswordResetRequest(this.state.username),
          () => this.props.onPasswordResetRequest()
        );
      }
    } finally {
      this.setState({ isLoaderVisible: false });
    }
  };

  private isEmailValid(email?: string): void | never {
    const testedEmail = email ?? this.state.username;

    if (testedEmail === "") {
      throw new ErrorWithCode("UsernameEmptyException");
    }

    if (!isValidEmail(testedEmail)) {
      throw new ErrorWithCode("EmailFormatNotValidException");
    }
  }

  private isPasswordValid(password?: string): void | never {
    const testedPassword = password ?? this.state.password;

    if (testedPassword === "") {
      throw new ErrorWithCode("PasswordEmptyException");
    }
  }

  private resetErrors(): void {
    this.setState({ generalError: undefined, emailError: undefined, passwordError: undefined });
  }

  private renderInputs(): JSX.Element {
    return (
      <Fragment>
        <div className="login-fields">
          <div className="field-container">
            <label htmlFor="email">{translations.common.inputs.email()}</label>
            <ValidationTextField
              type="email"
              name="email"
              id="email"
              autoComplete="email"
              variant="outlined"
              autoFocus={true}
              required={true}
              value={this.state.username}
              helperText={this.state.emailError}
              error={this.state.emailError != null}
              onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {
                this.resetErrors();
                this.setState({ username: event.currentTarget.value });
              }}
              fullWidth={true}
              onKeyPress={this.handleKeyPress}
            />
          </div>
          <div className="field-container">
            <label htmlFor="password">{translations.logIn.inputs.password()}</label>
            <PasswordField
              id="password"
              autoComplete="current-password"
              fullWidth={true}
              helperText={this.state.passwordError}
              error={this.state.passwordError != null}
              onChange={(password: string): void => {
                this.resetErrors();
                this.setState({ password });
              }}
              onKeyPress={this.handleKeyPress}
            />
          </div>
        </div>
        <div className="login-links underline right">
          <a onClick={this.handleForgotPassword}>{translations.logIn.buttons.forgotPassword()}</a>
        </div>
        <div className="login-buttons">
          <Button
            disabled={!this.state.username.length || !this.state.password.length || this.state.isLoaderVisible}
            variant="contained"
            color="primary"
            onClick={this.logIn}
          >
            {this.renderLoader()}
            {translations.logIn.buttons.logIn()}
            {this.renderLoaderCounter()}
          </Button>
        </div>
      </Fragment>
    );
  }

  private renderErrorMessage(): Maybe<JSX.Element> {
    if (this.state.generalError) {
      return (
        <div className="login-errortext">
          {translations.common.texts.errorOccurred({ error: this.state.generalError })}
        </div>
      );
    }
  }

  private handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      this.logIn();
    }
  };

  public render(): JSX.Element {
    return (
      <Fragment>
        <div className="login-header">
          {translations.logIn.texts.logInTo()} <span className="login-no-break">CloudCONEKT</span>
        </div>
        {this.renderInputs()}
        {this.renderErrorMessage()}
      </Fragment>
    );
  }
}
