import { Button, Grid, Typography } from "@mui/material";
import { AuthWrapper, AwsConfiguration, Order, OrderApiClient } from "@sade/data-access";
import React, { useEffect, useRef, useState } from "react";
import { translations } from "../../../../../generated/translationHelper";
import { NavLink } from "react-router-dom";
import { ArrowBack, Download } from "@mui/icons-material";
import { OrderStatusChip } from "../../order-status-chip";
import Loader from "../../../../ui/loader";

interface Props {
  orderId: number;
  order?: Order;
}

export const OrderDetailsBanner: React.FC<Props> = (props: Props) => {
  const awsConfig = useRef(AwsConfiguration.getConfiguration());
  const apiClient = useRef(new OrderApiClient(awsConfig.current.ApiGateway.RootUrlOrders, AuthWrapper.getAccessToken));

  const downloadAnchor = useRef<HTMLAnchorElement>(null);
  const [downloadUrl, setDownloadUrl] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (downloadUrl !== undefined) {
      downloadAnchor.current?.click();
      setDownloadUrl(undefined);
    }
  }, [downloadUrl, downloadAnchor, setDownloadUrl]);

  async function download(): Promise<void> {
    if (props.order?.downloads) {
      try {
        setIsLoading(true);
        if (props.order?.downloads?.expiresAt > new Date()) {
          setDownloadUrl(props.order.downloads.archiveUrl);
        } else {
          console.log("Expired download");
          props.order.downloads = (await apiClient.current.getOrder(props.order.orderId)).downloads;
          if (props.order.downloads) {
            setDownloadUrl(props.order.downloads.archiveUrl);
          }
        }
      } finally {
        setIsLoading(false);
      }
    }
  }

  return (
    <Grid item container direction="row" columnGap={1.5}>
      <Grid item>
        <Grid item container direction="column" rowSpacing={1}>
          <Grid item>
            <NavLink to={"/orders"}>
              <Button variant="text" startIcon={<ArrowBack />}>
                {translations.orders.buttons.backToList()}
              </Button>
            </NavLink>
          </Grid>
          <Grid item>
            <Typography variant="h4">
              {translations.orders.texts.order()} {props.order?.purchaseOrder ?? props.orderId}
              <OrderStatusChip orderStatus={props.order?.status} sx={{ marginLeft: 2 }} />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={{ flexGrow: 1 }} />
      <Grid item></Grid>
      <Grid item>
        <Button
          variant="contained"
          startIcon={isLoading ? <Loader size="small" topBottomPadding="0" /> : <Download />}
          disabled={props.order?.downloads === undefined}
          onClick={download}
        >
          {translations.orders.buttons.downloadCredentials()}
        </Button>
        <a hidden href={downloadUrl} ref={downloadAnchor}></a>
      </Grid>
    </Grid>
  );
};
