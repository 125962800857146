import React, { HTMLAttributes } from "react";
import { Button, InputLabel, TextField } from "@mui/material";
import { translations } from "../../../generated/translationHelper";
import Loader from "../../ui/loader";

interface Props {
  fileType?: string;
  isLoading: boolean;
  disabled?: boolean;
  selectFile: (file: File) => Promise<void>;
}

export const FilePickerButton: React.FC<Props & HTMLAttributes<HTMLButtonElement>> = (props) => {
  /**
   * Invisible text field (display: none) of type 'file' to leverage file picker already implemented in browsers,
   * and a button that is connected to the said text field via a label element. This way we display only the button,
   * and clicking it triggers the built-in file picker.
   */

  return (
    <>
      <TextField
        type="file"
        onChange={async (e): Promise<void> => {
          if ("files" in e.target && e.target.files !== null) {
            await props.selectFile(e.target.files[0]);
          }
        }}
        InputProps={{ inputProps: { accept: props.fileType } }}
        sx={{ display: "none" }}
        id={props.id}
      />
      <InputLabel htmlFor={props.id} sx={{ margin: 0 }}>
        <Button
          disabled={(props.disabled ?? false) || props.isLoading}
          variant="contained"
          color="primary"
          component="span"
          fullWidth
        >
          <Loader
            size={1}
            topBottomPadding={"0"}
            leftRightPadding={"0"}
            hidden={!props.isLoading}
            styles={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
          />
          <span style={{ visibility: props.isLoading ? "hidden" : "visible" }}>
            {translations.configurations.texts.browse()}
          </span>
        </Button>
      </InputLabel>
    </>
  );
};
