/**
 * Verifies that value is an object.
 * @param {unknown} value Potential object
 * @returns {boolean} result
 */
export function isObject(value) {
    return typeof value === "object" && value != null && !Array.isArray(value);
}
export function isError(error) {
    return (isObject(error) &&
        typeof error.name === "string" &&
        typeof error.message === "string" &&
        (!error.stack || typeof error.stack === "string"));
}
