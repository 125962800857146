import { AuthEvent, AuthListener, BackendFactory, RoleIdentifiers } from "@sade/data-access";

export default class ViewAccessMethods {
  private static adminAccess: boolean | undefined;

  public static async hasAdminAccess(): Promise<boolean> {
    if (ViewAccessMethods.adminAccess != null) {
      return ViewAccessMethods.adminAccess;
    }
    const organizationBackend = BackendFactory.getOrganizationBackend();

    try {
      const user = await organizationBackend.getCurrentUser();

      if (!user) {
        return false;
      }

      const hasAdminGroup = await Promise.all(
        [RoleIdentifiers.Owner, RoleIdentifiers.SuperAdmin, RoleIdentifiers.Admin].map((id) => user.hasRole(id))
      );

      ViewAccessMethods.adminAccess = hasAdminGroup.includes(true);
    } catch (err) {
      console.error("Failed to check for admin rights", err);
      ViewAccessMethods.adminAccess = false;
    }

    ViewAccessMethods.logPermission("Admin*", ViewAccessMethods.adminAccess);
    return ViewAccessMethods.adminAccess;
  }

  private static authCallback = (event: AuthEvent): void => {
    if (event === "SignedOut") {
      ViewAccessMethods.adminAccess = undefined;
    }
  };

  private static authListener = new AuthListener(ViewAccessMethods.authCallback);

  private static logPermission(name: string, access: boolean): void {
    console.log(`User ${access ? "has" : "does not have"} ${name} access`);
  }
}
