import { Role, RoleIdentifiers } from "@sade/data-access";
import { translations } from "../../../../generated/translationHelper";

export function translateRoleName(role: Role): string {
  switch (role.identifier) {
    case RoleIdentifiers.Owner:
      return translations.admin.texts.roleOwner();
    case RoleIdentifiers.SuperAdmin:
      return translations.admin.texts.roleSuperAdmin();
    case RoleIdentifiers.Admin:
      return translations.admin.texts.roleAdmin();
    case RoleIdentifiers.Programmer:
      return translations.admin.texts.roleProgrammer();
    case RoleIdentifiers.User:
      return translations.admin.texts.roleUser();
    default: {
      console.error(`Unknown role ${role.name}(${role.identifier})`);
      return translations.common.texts.notAvailable();
    }
  }
}
